export const PRODUCTION_HOSTNAME = 'itirreport01.irsap.it'
export const STAGING_HOSTNAME = 'itirstage01.irsap.it'

/** Application configuration that depends on the stage where it's published */
export type Config = {
  /** The URL of the authentication server */
  authenticationServerEndpoint: string
  /** The path of the export excel file in the `static` directory of the backend */
  staticReportsExcelPath: string
}

/**
 * A deployment stage
 */
export enum Stage {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DELIVERY = 'delivery',
}

/**
 * Since we build the same deliverable for production and delivery, the
 * configuration should be embedded in the application, like we do for
 * React Native application.
 */
export const STAGE_CONFIG: Record<Stage, Config> = {
  [Stage.PRODUCTION]: {
    authenticationServerEndpoint: 'https://itirauth01.irsap.it',
    staticReportsExcelPath: 'reports/production/rapportini.xlsx',
  },
  [Stage.STAGING]: {
    authenticationServerEndpoint: 'https://itirstage01.irsap.it:8443',
    staticReportsExcelPath: 'reports/staging/rapportini.xlsx',
  },
  [Stage.DELIVERY]: {
    authenticationServerEndpoint: 'https://loom-authentication-service.galaxy',
    staticReportsExcelPath: 'reports/delivery/rapportini.xlsx',
  },
}

/**
 * Gets the current stage based on the endpoint used
 */
export function getCurrentStage(): Stage {
  switch (window.location.hostname) {
    case PRODUCTION_HOSTNAME:
      return Stage.PRODUCTION
    case STAGING_HOSTNAME:
      return Stage.STAGING
    default:
      return Stage.DELIVERY
  }
}

/**
 * Gets the current config based on the stage that the application is in execution
 */
export function getConfigForStage(): Config {
  return STAGE_CONFIG[getCurrentStage()]
}

export const ROOT_CONFIG = getConfigForStage()
export const COUCHDB_ROOT_PATH = '/db/'
export const AUTHENTICATION_SERVICE_URI = ROOT_CONFIG.authenticationServerEndpoint
export const EXPORT_REPORT_URI = `${window.location.origin}/static/${ROOT_CONFIG.staticReportsExcelPath}`
