import { ErrorScreen } from '@/screens/ErrorScreen'
import { Page404 } from '@/screens/Page404'
import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Root } from './screens/Root'
import { DiscardReasonListScreen } from './screens/discardReasons/DiscardReasonListScreen'
import { DivisionListScreen } from './screens/divisions/DivisionListScreen'
import { MachineReportRoot } from './screens/machineUI/MachineReportRoot'
import { ProcessingDetailsListScreen } from './screens/processingDetails/ProcessingDetailsListScreen'
import { ProductListScreen } from './screens/products/ProductListScreen'
import { EditReportHeaderScreen } from './screens/reports/EditReportHeaderScreen'
import { EditReportLayout } from './screens/reports/EditReportLayout'
import { EditReportQuantityScreen } from './screens/reports/EditReportQuantityScreen'
import { EditReportStopScreen } from './screens/reports/EditReportStopScreen'
import { EditReportSummaryScreen } from './screens/reports/EditReportSummaryScreen'
import { EditReportTestScreen } from './screens/reports/EditReportTestScreen'
import { ReportDetailsScreen } from './screens/reports/ReportDetailsScreen'
import { ReportsListScreen } from './screens/reports/ReportsListScreen'
import { ReworkReasonListScreen } from './screens/reworkReasons/ReworkReasonListScreen'
import { StopReasonListScreen } from './screens/stopReasons/StopReasonListScreen'
import { WorkCenterStopsListScreen } from './screens/workCenterStops/WorkCenterStopsListScreen'
import { WorkCenterListScreen } from './screens/workCenters/WorkCenterListScreen'

export const ROUTE = {
  root: '/',
  reports: '/reports',
  reportDetails: '/reports/:id',
  reportCreate: '/reports/create',
  reportCreateQuantity: '/reports/create/quantity/:index?',
  reportCreateStops: '/reports/create/stops/:index?',
  reportCreateTests: '/reports/create/tests/:index?',
  reportCreateSummary: '/reports/create/summary',
  reportEdit: '/reports/edit/:id',
  reportEditQuantity: '/reports/edit/:id/quantity/:index?',
  reportEditStops: '/reports/edit/:id/stops/:index?',
  reportEditTests: '/reports/edit/:id/tests/:index?',
  reportEditSummary: '/reports/edit/:id/summary',
  products: '/registry/products',
  processingDetails: '/registry/processing-details',
  workCenterStops: '/registry/work-center-stops',
  reworksReasons: '/registry/reworks-reasons',
  discardsReasons: '/registry/discard-reasons',
  stopReasons: '/registry/stop-reasons',
  workCenters: '/registry/work-centers',
  divisions: '/registry/divisions',
  machineReports: '/machine/reports/:workCenterId/*',
} as const

export type RouteURL = (typeof ROUTE)[keyof typeof ROUTE]

export const routes: RouteObject[] = [
  {
    path: ROUTE.root,
    element: <Root />,
    errorElement: <ErrorScreen />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTE.reports} />,
      },
      {
        path: ROUTE.reports,
        element: <ReportsListScreen />,
      },
      {
        path: ROUTE.reportDetails,
        element: <ReportDetailsScreen />,
      },
      {
        path: ROUTE.reportCreate,
        element: <EditReportLayout />,
        children: [
          {
            index: true,
            element: <EditReportHeaderScreen />,
          },
          {
            path: ROUTE.reportCreateQuantity,
            element: <EditReportQuantityScreen />,
          },
          {
            path: ROUTE.reportCreateStops,
            element: <EditReportStopScreen />,
          },
          {
            path: ROUTE.reportCreateTests,
            element: <EditReportTestScreen />,
          },
          {
            path: ROUTE.reportCreateSummary,
            element: <EditReportSummaryScreen />,
          },
        ],
      },
      {
        path: ROUTE.reportEdit,
        element: <EditReportLayout />,
        children: [
          {
            index: true,
            element: <EditReportHeaderScreen />,
          },
          {
            path: ROUTE.reportEditQuantity,
            element: <EditReportQuantityScreen />,
          },
          {
            path: ROUTE.reportEditStops,
            element: <EditReportStopScreen />,
          },
          {
            path: ROUTE.reportEditTests,
            element: <EditReportTestScreen />,
          },
          {
            path: ROUTE.reportEditSummary,
            element: <EditReportSummaryScreen />,
          },
        ],
      },
      {
        path: ROUTE.products,
        element: <ProductListScreen />,
      },
      {
        path: ROUTE.processingDetails,
        element: <ProcessingDetailsListScreen />,
      },
      {
        path: ROUTE.workCenterStops,
        element: <WorkCenterStopsListScreen />,
      },
      {
        path: ROUTE.reworksReasons,
        element: <ReworkReasonListScreen />,
      },
      {
        path: ROUTE.discardsReasons,
        element: <DiscardReasonListScreen />,
      },
      {
        path: ROUTE.stopReasons,
        element: <StopReasonListScreen />,
      },
      {
        path: ROUTE.workCenters,
        element: <WorkCenterListScreen />,
      },
      {
        path: ROUTE.divisions,
        element: <DivisionListScreen />,
      },
    ],
  },
  {
    path: ROUTE.machineReports,
    element: <MachineReportRoot />,
  },
  { path: '*', element: <Page404 /> },
]

export const AppRouter = () => {
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}
