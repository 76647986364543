import { BaseDocument, DocumentRef, DocumentType } from './BaseDocument'
import { EncodedShift, Shift } from './Shift'
import { WorkCenter } from './WorkCenter'

/**
 * Represents an entity which created a document object present in the system
 */
export enum ReportDataSource {
  /** Created by the SIPRO bridge (imported) */
  SIPRO = 'SIPRO',

  /** Manually created by a user from the editor */
  EDITOR = 'EDITOR',

  /** Created by a data import script */
  IMPORT = 'IMPORT',
}

/**
 * Represents the state of a document
 */
export enum ReportState {
  /** A document which can be imported into the editor and has yet to be finalized */
  DRAFT = 'DRAFT',

  /** A finalized document which is part of a completed report */
  COMPLETE = 'COMPLETE',

  /** A document which has been deleted from the system and can be ignored */
  DELETED = 'DELETED',
}

/**
 * A report ("rapportino") of production as represented in the database
 */
export type Report = BaseDocument<DocumentType.REPORT> & {
  /** The ID of the report */
  code: string

  /** Shift bound to this report, decoded from the string */
  decodedShift: Shift

  /** Encoded shift for storage in the DB */
  shift: EncodedShift

  /** ID of the worker that produces the report */
  workerCode: string

  /** The work center described by this report */
  workCenter: WorkCenter

  /** If work was stopped  */
  breakDuration: number

  /** Duration of the shift change in seconds */
  shiftChangeDuration: number

  /** Optional notes added by the worker */
  notes: string

  /** The current state of the report */
  state: ReportState

  /** The total production duration in seconds */
  totalProductionDuration: number

  /** The total stop duration in seconds */
  totalStopDuration: number

  /** The real calculated shift duration in seconds */
  totalShiftDuration: number
}

/** Objects extending this always lead back to the originating report */
export type ReportItem = {
  /** The ID of this report part */
  code: string

  /** Arbitrary name of a system which generated this item */
  source: ReportDataSource

  /** Document ID for the report which created this object */
  reportCode: DocumentRef<Report>

  /** Shift bound to this part */
  shift: EncodedShift

  /** The current state of the report */
  state: ReportState
}
