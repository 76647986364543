import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { ComboSelect } from '@/components/ComboSelect'
import { DeleteButton } from '@/components/DeleteButton'
import { DeleteDialog } from '@/components/DeleteDialog'
import { EditButton } from '@/components/EditButton'
import { PaginationFooter } from '@/components/PaginationFooter'
import { ContentLayout } from '@/components/layouts/ContentLayout'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { EXPORT_REPORT_URI } from '@/config'
import { ASSIEMATRICI_DIVISION_CODE, EXTERNAL_DIVISION_CODE } from '@/constants'
import { useAllDocs } from '@/hooks/useAllDocs'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { usePaginatedQuery } from '@/hooks/usePaginatedQuery'
import { humanizeSeconds } from '@/lib/datetime'
import { joinIgnoreEmpty } from '@/lib/localize'
import { storage } from '@/lib/storage'
import { CASE_INSENSITIVE_MATCH, cn, escapeForRegex } from '@/lib/utils'
import { ROUTE } from '@/routes'
import { FullReportCrudService } from '@/shared/crud/FullReportCrudService'
import { ReportListQueryService, ReportWithQuantitiesCount } from '@/shared/crud/ReportListQueryService'
import { CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import { PRODUCTION_DB_DESIGN_DOC_NAME, PRODUCTION_DB_NAME, SCHEDULE_ITEMS_BY_SHIFT_CODE } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Division } from '@/shared/dto/Division'
import { Report, ReportState } from '@/shared/dto/Report'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { UserRole, hasRole } from '@/shared/utils/auth'
import { IconFilePlus, IconTableExport } from '@tabler/icons-react'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { ReportNewModal } from './ReportNewModal'

const BASE_QUERY = new CouchdbQueryBuilderImpl()
  .where('type', '=', DocumentType.REPORT)
  .where('state', '=', ReportState.COMPLETE)
  .orderBy('decodedShift.start', 'desc')

export function ReportsListScreen() {
  const navigate = useNavigate()
  const { currentUser } = useAuthentication()
  const { t } = useTranslation()

  const [params, setParams] = useSearchParams()
  const updateSearchParam = (key: string, value: string) => {
    setParams(old => {
      old.set(key, value)
      return old
    })
  }

  const isUserAdmin = hasRole(currentUser, [UserRole.REPORTS_REGISTRY, UserRole.ADMIN])
  const canAddReport = hasRole(currentUser, [UserRole.REPORTS_WRITE, UserRole.REPORTS_REGISTRY, UserRole.ADMIN])

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState<Report | undefined>()

  const id = params.get('id') ?? undefined
  const date = params.get('date') ?? undefined

  const shift = params.get('shift') ?? undefined
  const setShift = (shift: string) => updateSearchParam('shift', shift)

  const division = params.get('division') ?? undefined
  const setDivision = (division: string) => updateSearchParam('division', division)

  const workerId = params.get('worker_id') ?? undefined
  const setWorkerId = (workerId: string) => updateSearchParam('worker_id', workerId)

  const workCenterId = params.get('work_centre_id') ?? undefined
  const setWorkCentreId = (workCenterId: string) => updateSearchParam('work_centre_id', workCenterId)

  const pageSize = params.has('page_size') ? parseInt(params.get('page_size')!) : 20
  const setPageSize = (size: number) => updateSearchParam('page_size', size?.toString())

  const [query, setQuery] = useState(BASE_QUERY.limit(pageSize))

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const queryService = new ReportListQueryService(db)
  const {
    data: reports,
    goToNext,
    goToPrev,
    pageIndex,
    hasNext,
    hasPrev,
    refresh,
  } = usePaginatedQuery<ReportWithQuantitiesCount>(query, queryService, pageSize)

  const { data: divisions } = useAllDocs<DocumentType.DIVISION, Division>(DocumentType.DIVISION)
  const { data: workCenters } = useAllDocs<DocumentType.WORK_CENTER, WorkCenter>(DocumentType.WORK_CENTER)

  const divisionMap: Map<string, Division> = useMemo(
    () => (divisions ?? []).reduce((map, division) => map.set(division.code, division), new Map()),
    [divisions]
  )

  const [shiftCodes, setShiftCodes] = useState<string[]>([])
  useEffect(() => {
    db.design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view(SCHEDULE_ITEMS_BY_SHIFT_CODE, {
        group_level: 1,
      })
      .then(resp => setShiftCodes(resp.rows.map(row => row.key as string)))
  }, [db])

  const updateView = useDebouncedCallback(() => {
    let query = BASE_QUERY
    if (id) {
      query = query.where('_id', 'regex', id)
    }
    if (date) {
      query = query.where('decodedShift.start', 'regex', escapeForRegex(date))
    }
    if (shift) {
      query = query.where('decodedShift.code', '=', shift)
    }
    if (workerId) {
      query = query.where('workerCode', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(workerId))
    }
    if (workCenterId) {
      query = query.where('workCenter.code', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(workCenterId))
    }
    if (division) {
      query = query.where('workCenter.divisionCode', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(division))
    }

    setQuery(query)
  }, 500)

  useEffect(() => {
    updateView()
  }, [updateView, date, shift, workerId, workCenterId, division])

  const onReportInfo = (id: string) => {
    navigate(generatePath(ROUTE.reportDetails, { id: id }))
  }

  const onChangeDate = (value: string | undefined) => {
    const newParams = Object.fromEntries(params.entries())

    if (value === undefined || value.length === 0) {
      delete newParams.shift
    }

    if (value) {
      newParams.date = value
    } else {
      delete newParams.date
    }

    setParams(newParams)
  }

  const onExport = async () => {
    const link = document.createElement('a')
    link.href = EXPORT_REPORT_URI
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  const onDeleteItem = async (item: Report) => {
    if (isUserAdmin) {
      const reportService = new FullReportCrudService(db)
      await reportService.delete(item.code)
      refresh()
    }
  }

  // highlight just edited report
  const lastEditedReportCode = storage.getLastEditedReportCode()

  return (
    <>
      <ContentLayout
        leftActions={
          <ButtonWithHotkey
            hotkey="ctrl+n"
            disabled={!canAddReport}
            onClick={() => {
              setCreateModalVisible(true)
            }}
            className="print:hidden"
            tooltip={t('NewReport')}
            variant="outline"
          >
            <IconFilePlus />
          </ButtonWithHotkey>
        }
        rightActions={
          <ButtonWithHotkey
            hotkey="ctrl+e"
            onClick={onExport}
            className="print:hidden mr-2"
            tooltip={t('ExportToExcel')}
            variant="outline"
          >
            <IconTableExport />
          </ButtonWithHotkey>
        }
        footer={
          <PaginationFooter
            setPageSize={setPageSize}
            pageSize={pageSize}
            nextEnabled={hasNext}
            prevEnabled={hasPrev}
            onNext={goToNext}
            onPrev={goToPrev}
            pageIndex={pageIndex}
            currentPageSize={reports.length}
          />
        }
      >
        <Table className="table-fixed">
          <TableHeader className="sticky inset-0 bg-secondary">
            <TableRow className="shadow-sticky-header-b [&_th]:pt-4 [&_th]:align-top [&_th]:text-primary [&_input]:text-muted-foreground">
              <TableHead className="w-40">
                <div className="flex flex-col">
                  <span>{t('Date')}</span>
                  <Input
                    type="date"
                    value={date}
                    onChange={e => onChangeDate(e.target.value)}
                    className="my-2 print:hidden"
                  />
                </div>
              </TableHead>
              <TableHead className="w-32">
                <div className="flex flex-col">
                  <span>{t('Shift')}</span>
                  <ComboSelect
                    className="my-2 print:hidden bg-secondary"
                    items={shiftCodes.map(item => ({
                      value: item,
                      label: item,
                    }))}
                    showReset
                    resetLabel={t('Any')}
                    label={t('Shift')}
                    onSelect={value => setShift(value.value)}
                  />
                </div>
              </TableHead>
              <TableHead>
                <div className="flex flex-col">
                  <span>{t('Division')}</span>
                  <ComboSelect
                    className="my-2 print:hidden bg-secondary"
                    items={(divisions ?? [])
                      .filter(d => d.code !== EXTERNAL_DIVISION_CODE)
                      .map(item => ({
                        value: item.code,
                        label: joinIgnoreEmpty(' - ', item.code, item.description),
                      }))}
                    showReset
                    resetLabel={t('Any')}
                    label={t('Division')}
                    onSelect={value => {
                      setDivision(value.value)
                      setWorkCentreId('')
                    }}
                  />
                </div>
              </TableHead>
              <TableHead>
                <div className="flex flex-col">
                  <span>{t('WorkCenter')}</span>
                  <ComboSelect
                    className="my-2 print:hidden bg-secondary"
                    items={(workCenters ?? [])
                      .filter(wc => {
                        if (wc.divisionCode === EXTERNAL_DIVISION_CODE) {
                          return false
                        }

                        if (division) {
                          return wc.divisionCode === division
                        }
                        return true
                      })
                      .map(item => ({
                        value: item.code,
                        label: joinIgnoreEmpty(' - ', item.code, item.description),
                      }))}
                    showReset
                    resetLabel={t('Any')}
                    label={t('FilterByWorkCentre')}
                    value={workCenterId}
                    onSelect={value => setWorkCentreId(value.value)}
                  />
                </div>
              </TableHead>
              <TableHead className="w-36">
                <div className="flex flex-col">
                  <span>{t('WorkerId')}</span>
                  <Input
                    type="text"
                    value={workerId}
                    onChange={e => setWorkerId(e.target.value)}
                    className="my-2 print:hidden"
                    placeholder={t('FilterByWorkerId')}
                  />
                </div>
              </TableHead>
              <TableHead className="w-28">{t('ElementsCount')}</TableHead>
              <TableHead className="w-28">{t('Batteries')}</TableHead>
              <TableHead className="w-28">{t('ReworkedPieces')}</TableHead>
              <TableHead className="w-28">{t('DiscardedPieces')}</TableHead>
              <TableHead className="w-28">{t('Duration')}</TableHead>
              <TableHead className="w-20">{t('Actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {reports.map(report => (
              <TableRow
                key={report._id}
                onClick={() => onReportInfo(report.code)}
                className={cn('cursor-pointer', report.code === lastEditedReportCode ? 'bg-neutral-200' : '')}
              >
                <TableCell>{DateTime.fromISO(report.decodedShift.start).toFormat('dd/MM/yyyy')}</TableCell>
                <TableCell>{report.decodedShift.code}</TableCell>
                <TableCell>{divisionMap.get(report.workCenter.divisionCode)?.description}</TableCell>
                <TableCell>{report.workCenter.code}</TableCell>
                <TableCell>{report.workerCode}</TableCell>

                {report.workCenter.code.startsWith(ASSIEMATRICI_DIVISION_CODE) ? (
                  <>
                    <TableCell>{report.productionCount[1] || 0}</TableCell>
                    <TableCell>{report.productionCount[0]}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{report.productionCount[0]}</TableCell>
                    <TableCell></TableCell>
                  </>
                )}

                <TableCell>{report.reworksCount}</TableCell>
                <TableCell>{report.discardsCount}</TableCell>
                <TableCell>{humanizeSeconds(report.totalShiftDuration)}</TableCell>
                <TableCell className="flex">
                  {canAddReport && (
                    <EditButton
                      onClick={e => {
                        e.stopPropagation()
                        navigate(generatePath(ROUTE.reportEdit, { id: report.code }))
                      }}
                    />
                  )}
                  {isUserAdmin ? (
                    <DeleteButton
                      onClick={e => {
                        e.stopPropagation()
                        setSelectedEntry(report)
                        setDeleteModalVisible(true)
                      }}
                    />
                  ) : (
                    <div className="h-9"></div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow />
          </TableBody>
        </Table>
      </ContentLayout>
      {canAddReport && <ReportNewModal open={createModalVisible} setOpen={setCreateModalVisible} />}
      {isUserAdmin && (
        <DeleteDialog open={deleteModalVisible} setOpen={setDeleteModalVisible}>
          <DeleteDialog.Content onConfirm={() => selectedEntry && onDeleteItem(selectedEntry)} />
        </DeleteDialog>
      )}
    </>
  )
}
