import { Loading } from '@/components/Loading'
import { ReportView } from '@/components/ReportView'
import { ContentLayout } from '@/components/layouts/ContentLayout'
import { useCouchdb } from '@/hooks/useCouchdb'
import { FullReportCrudService, ReportFull } from '@/shared/crud/FullReportCrudService'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export function ReportDetailsScreen() {
  const { id: reportCode } = useParams()

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const reportCrudService = useMemo(() => new FullReportCrudService(db), [db])

  const [report, setReport] = useState<ReportFull>()

  useEffect(() => {
    if (reportCode) {
      reportCrudService.read(reportCode).then(result => {
        setReport(result)
      })
    }
  }, [reportCode, reportCrudService])

  return (
    <ContentLayout hideHeader>
      {report === undefined && (
        <div className="h-screen flex justify-center">
          <Loading />
        </div>
      )}
      {report && (
        <div className="m-8 w-2/3 print:w-full mx-auto">
          <ReportView isEdit={false} report={report} />
        </div>
      )}
    </ContentLayout>
  )
}
