import { buttonVariants } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { Icon } from '@tabler/icons-react'
import { NavLink, To } from 'react-router-dom'

export type NavigationDrawerItem = {
  title: string
  label?: string
  onClick?: () => void
  to: To
  icon: Icon
}

export type NavigationDrawerProps = {
  isCollapsed: boolean
  reloadOnNavigate?: boolean
  items?: NavigationDrawerItem[]
}

export function NavigationDrawer({ isCollapsed, items = [], reloadOnNavigate = false }: NavigationDrawerProps) {
  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 print:hidden"
    >
      <nav className="grid group-[[data-collapsed=true]]:justify-stretch">
        {items.map((link, index) =>
          isCollapsed ? (
            <CollapsedItem key={index} link={link} reloadOnNavigate={reloadOnNavigate} />
          ) : (
            <ExpandedItem key={index} link={link} reloadOnNavigate={reloadOnNavigate} />
          )
        )}
      </nav>
    </div>
  )
}

type CommonItemProps = {
  link: NavigationDrawerItem
  reloadOnNavigate: boolean
}

function CollapsedItem({ link, reloadOnNavigate }: CommonItemProps) {
  const isDisabled = link.to === '#'

  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <NavLink to={link.to} reloadDocument={reloadOnNavigate} onClick={link.onClick}>
          {({ isActive }) => (
            <span
              className={cn(
                buttonVariants({ variant: isActive && !isDisabled ? 'default' : 'ghost', size: 'icon' }),
                'h-9 w-full rounded-none',
                isActive &&
                  !isDisabled &&
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
              )}
            >
              <link.icon className="h-5 w-5" />
              <span className="sr-only">{link.title}</span>
            </span>
          )}
        </NavLink>
      </TooltipTrigger>
      <TooltipContent side="right" className="flex items-center gap-4">
        {link.title}
        {link.label && <span className="ml-auto text-muted-foreground">{link.label}</span>}
      </TooltipContent>
    </Tooltip>
  )
}

function ExpandedItem({ link, reloadOnNavigate }: CommonItemProps) {
  const isDisabled = link.to === '#'

  return (
    <NavLink
      to={link.to}
      reloadDocument={reloadOnNavigate}
      onClick={link.onClick}
      className={({ isActive }) =>
        cn(
          buttonVariants({ variant: isActive && !isDisabled ? 'default' : 'ghost', size: 'sm' }),
          isActive && !isDisabled && 'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
          'text-sm py-5 justify-start rounded-none'
        )
      }
    >
      {({ isActive }) => (
        <>
          <link.icon className="mr-3 h-5 w-5" />
          {link.title}
          {link.label && (
            <span className={cn('ml-auto', isActive && !isDisabled && 'text-background dark:text-white')}>
              {link.label}
            </span>
          )}
        </>
      )}
    </NavLink>
  )
}
