import {
  CouchdbDesignDocDefinition,
  CouchdbDoc,
  CouchdbMangoIndexCreateParams,
} from '@iotinga/ts-backpack-couchdb-client'
import { BaseDocument, DocumentType } from '../dto/BaseDocument'

declare function emit(key: unknown, value: unknown): void

export type ProductionCouchdbDoc = CouchdbDoc & BaseDocument<DocumentType>

export const PRODUCTION_DB_NAME = 'loom_production'
export const PRODUCTION_DB_DESIGN_DOC_NAME = 'loom_production'
export const PRODUCTION_DB_INDEX_DESIGN_DOC_NAME = 'loom_production_indexes'
export const REPORT_PARTS_BY_SOURCE_VIEW_NAME = 'reportPartsBySource'
export const REPORT_PARTS_BY_SHIFT_VIEW_NAME = 'reportPartsByShift'
export const REPORT_QUANTITIES_VIEW_NAME = 'reportQuantities'
export const REPORT_EXPORT_VIEW_NAME = 'reportExport'

export const WORK_CENTER_STOP_REASONS_VIEW_NAME = 'workCenterStopsReasons'
export const WORK_CENTER_REWORK_REASONS_VIEW_NAME = 'workCenterReworkReasons'
export const WORK_CENTER_DISCARD_REASONS_VIEW_NAME = 'workCenterDiscardReason'
export const WORK_CENTER_PRODUCTS_VIEW_NAME = 'workCenterProducts'

export const WORK_CENTERS_BY_DISCARD_REASON_VIEW_NAME = 'workCentersByDiscardReason'
export const WORK_CENTERS_BY_REWORK_REASON_VIEW_NAME = 'workCentersByReworkReason'
export const WORK_CENTERS_BY_STOP_REASON_VIEW_NAME = 'workCentersByStopReason'

export const SCHEDULE_ITEMS_BY_SHIFT_CODE = 'scheduleItemsByShiftCode'

export const WORK_CENTER_REASONS_BY_WORK_CENTER_CODE_VIEW_NAME = 'workCenterReasonsByWorkCenterCode'
export const WORK_CENTER_STOP_REASONS_BY_REASON_CODE_VIEW_NAME = 'workCenterStopReasonsByReasonCode'
export const WORK_CENTER_DISCARD_REASONS_BY_REASON_CODE_VIEW_NAME = 'workCenterDiscardReasonsByReasonCode'
export const WORK_CENTER_REWORK_REASONS_BY_REASON_CODE_VIEW_NAME = 'workCenterReworkReasonsByReasonCode'

export const PRODUCTION_DB_DESIGN_DOC: CouchdbDesignDocDefinition<ProductionCouchdbDoc> = {
  name: PRODUCTION_DB_DESIGN_DOC_NAME,
  validate_doc_update: function (newDoc, oldDoc, userCtx) {
    const isAdmin =
      userCtx.roles.includes('_admin') || userCtx.roles.includes('*') || userCtx.roles.includes('reports:*')
    const canWrite = isAdmin || userCtx.roles.includes('reports:write')

    // only write users can update documents
    if (!canWrite) {
      throw { forbidden: 'User has read-only access' }
    }

    // only admin can edit the registry
    const registryTypes = [
      'DISCARD_REASON',
      'DIVISION',
      'PROCESSING_DETAILS',
      'PRODUCT',
      'REWORK_REASON',
      'SCHEDULE_ITEM',
      'STOP_REASON',
      'WORK_CENTER_DISCARD',
      'WORK_CENTER_REWORK',
      'WORK_CENTER_STOP',
      'WORK_CENTER',
    ]
    if (registryTypes.includes(newDoc.type) && !isAdmin) {
      throw { forbidden: 'Only admin users can edit the registry' }
    }
  },
  views: {
    [REPORT_PARTS_BY_SOURCE_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        const reportDocuments = ['REPORT', 'PRODUCTION', 'REWORK', 'DISCARD', 'STOP', 'TEST']

        if (reportDocuments.includes(doc.type as string)) {
          if (doc.state && doc.state !== 'COMPLETE') {
            return
          }

          if (doc.type === 'REPORT') {
            emit([doc.code, doc.type], 1)
          }

          if (doc.type == 'PRODUCTION') {
            emit([doc.reportCode, 'BATCH'], { _id: 'BATCH@' + doc.batchCode })
          }

          if (doc.reportCode !== undefined) {
            emit([doc.reportCode, doc.type], 1)
          }
        }
      },
      reduce: '_count',
    },
    [REPORT_PARTS_BY_SHIFT_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.shift && doc.state) {
          emit([doc.shift, doc.state, doc.type], 1)

          if (doc.type == 'PRODUCTION') {
            emit([doc.shift, doc.state, 'BATCH'], { _id: 'BATCH@' + doc.batchCode })
          }
        }
      },
      reduce: '_count',
    },
    [REPORT_QUANTITIES_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        const productionTypes = ['PRODUCTION', 'REWORK', 'DISCARD']
        if (doc.type && productionTypes.includes(doc.type as string)) {
          emit([doc.reportCode, doc.type], [doc.quantity, doc.elements])
        }
      },
      reduce: '_sum',
    },
    [WORK_CENTER_STOP_REASONS_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_STOP') {
          emit([doc.workCenterCode, doc.reasonCode], {
            _id: 'STOP_REASON@' + doc.reasonCode,
            stopDuration: doc.stopDuration,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_REWORK_REASONS_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_REWORK') {
          emit([doc.workCenterCode, doc.reasonCode], {
            _id: 'REWORK_REASON@' + doc.reasonCode,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_DISCARD_REASONS_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_DISCARD') {
          emit([doc.workCenterCode, doc.reasonCode], {
            _id: 'DISCARD_REASON@' + doc.reasonCode,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_PRODUCTS_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'PROCESSING_DETAILS') {
          emit([doc.workCenterCode, doc.productCode], {
            _id: 'PRODUCT@' + doc.productCode,
            machineCycleDuration: doc.machineCycleDuration,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTERS_BY_DISCARD_REASON_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_DISCARD') {
          emit(doc.reasonCode, { _id: 'WORK_CENTER@' + doc.workCenterCode })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTERS_BY_REWORK_REASON_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_REWORK') {
          emit(doc.reasonCode, { _id: 'WORK_CENTER@' + doc.workCenterCode })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTERS_BY_STOP_REASON_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_STOP') {
          emit(doc.reasonCode, { _id: 'WORK_CENTER@' + doc.workCenterCode })
        }
      },
      reduce: '_count',
    },
    [SCHEDULE_ITEMS_BY_SHIFT_CODE]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'SCHEDULE_ITEM') {
          emit(doc.shiftCode, { _id: doc._id })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_REASONS_BY_WORK_CENTER_CODE_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        const types = ['WORK_CENTER_STOP', 'WORK_CENTER_DISCARD', 'WORK_CENTER_REWORK']
        if (types.includes(doc.type as string)) {
          emit([doc.reasonCode, doc.workCenterCode], {
            _id: doc._id,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_STOP_REASONS_BY_REASON_CODE_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_STOP') {
          emit([doc.reasonCode, doc.workCenterCode], {
            _id: doc._id,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_DISCARD_REASONS_BY_REASON_CODE_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_DISCARD') {
          emit([doc.reasonCode, doc.workCenterCode], {
            _id: doc._id,
          })
        }
      },
      reduce: '_count',
    },
    [WORK_CENTER_REWORK_REASONS_BY_REASON_CODE_VIEW_NAME]: {
      map: function (doc: Record<string, unknown>) {
        if (doc.type === 'WORK_CENTER_REWORK') {
          emit([doc.reasonCode, doc.workCenterCode], {
            _id: doc._id,
          })
        }
      },
      reduce: '_count',
    },
    [REPORT_EXPORT_VIEW_NAME]: {
      map: function (doc: Record<string, unknown> & ProductionCouchdbDoc) {
        const reportDocuments = ['REPORT', 'PRODUCTION', 'REWORK', 'DISCARD', 'STOP', 'TEST']

        if (reportDocuments.includes(doc.type) && doc.shift) {
          const [, workCenterCode, , start] = (doc.shift as string).split('_')
          const [year, month, day] = start
            .substring(0, 10)
            .split('-')
            .map((x: string) => parseInt(x))

          if (doc.state === 'COMPLETE') {
            emit([year, month, day, workCenterCode], 1)
          }
        }
      },
      reduce: '_count',
    },
  },
}

export const PRODUCTION_INDEXES: Record<string, CouchdbMangoIndexCreateParams> = {
  'type-json-index': {
    ddoc: PRODUCTION_DB_INDEX_DESIGN_DOC_NAME,
    name: 'type-json-index',
    index: {
      fields: ['type'],
    },
  },
  'report-start-json-index': {
    ddoc: PRODUCTION_DB_INDEX_DESIGN_DOC_NAME,
    name: 'report-start-json-index',
    index: {
      fields: ['decodedShift.start'],
    },
  },
  'product-code-json-index': {
    ddoc: PRODUCTION_DB_INDEX_DESIGN_DOC_NAME,
    name: 'product-code-json-index',
    index: {
      fields: ['type', 'productCode'],
    },
  },
  'work-center-code-json-index': {
    ddoc: PRODUCTION_DB_INDEX_DESIGN_DOC_NAME,
    name: 'work-center-code-json-index',
    index: {
      fields: ['type', 'workCenterCode'],
    },
  },
  'worker-code-json-index': {
    ddoc: PRODUCTION_DB_INDEX_DESIGN_DOC_NAME,
    name: 'worker-code-json-index',
    index: {
      fields: ['type', 'workerCode'],
    },
  },
}
