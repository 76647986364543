import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { ComboSelect } from '@/components/ComboSelect'
import { NewReportScreenCard } from '@/components/NewReportScreenCard'
import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'
import { useAuthentication } from '@/hooks/useAuthentication'
import { calculateTotalProductionDuration } from '@/lib/calculation'
import { NON_EMPTY_STRING } from '@/lib/schema'
import { calculateProductionDuration, cn } from '@/lib/utils'
import { NewReportOutletContext, getBaseReportItemDocument } from '@/screens/reports/EditReportLayout'
import { QuantityFull } from '@/shared/crud/FullReportCrudService'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Batch } from '@/shared/dto/Batch'
import { Product } from '@/shared/dto/Product'
import { DiscardReason, ReworkReason } from '@/shared/dto/Reason'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { cloneDeep } from 'lodash'
import { useEffect } from 'react'
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { z } from 'zod'

type QuantityFormFields = {
  productCode: string
  parentWorkCenterCode: string
  quantity: number
  elements: number
  reworks: Array<{
    quantity: number
    elements: number
    reasonCode: string
  }>
  discards: Array<{
    quantity: number
    elements: number
    reasonCode: string
  }>
}

const QUANTITY_FORM_SCHEMA = z.object({
  productCode: NON_EMPTY_STRING,
  parentWorkCenterCode: z.string(),
  quantity: z.coerce.number().gt(0),
  elements: z.number().int().gte(0),
  reworks: z.array(
    z.object({
      quantity: z.coerce.number().gt(0),
      elements: z.number(),
      reasonCode: NON_EMPTY_STRING,
    })
  ),
  discards: z.array(
    z.object({
      quantity: z.coerce.number().gt(0),
      elements: z.number(),
      reasonCode: NON_EMPTY_STRING,
    })
  ),
})

export function EditReportQuantityScreen() {
  const { index: indexParam } = useParams()
  const { currentUser } = useAuthentication()

  const {
    editorState,
    setEditorState,
    availableProducts,
    availableDiscardReasons,
    availableReworkReasons,
    parentWorkCenters,
  } = useOutletContext<NewReportOutletContext>()

  const quantities = editorState.quantities || []
  const index = quantities.findIndex(q => q.production.batchCode === (indexParam || ''))
  const batch = indexParam ? editorState.batches[indexParam] : undefined
  const workCenterDivisionCode = editorState.header.workCenter.divisionCode

  const onUpdateQuantity = (data: QuantityFormFields) => {
    const selectedProduct = availableProducts.find(x => x.code === data.productCode)

    if (!batch || !selectedProduct) return

    const machineDuration = selectedProduct.machineCycleDuration ?? 0

    // update batches
    const newBatches = { ...editorState.batches }
    newBatches[batch.code].parentWorkCenterCode = data.parentWorkCenterCode
    newBatches[batch.code].product = selectedProduct

    // update quantities
    if (Number.isNaN(data.quantity)) {
      return
    }
    const newQuantities = cloneDeep(editorState.quantities)
    newQuantities[index].production.quantity = data.quantity
    newQuantities[index].production.elements = data.elements
    newQuantities[index].production.machineCycleDuration = machineDuration
    newQuantities[index].production.productionDuration = calculateProductionDuration(
      workCenterDivisionCode,
      data.quantity,
      data.elements,
      machineDuration
    )

    newQuantities[index].reworks = data.reworks.map(rew => ({
      ...getBaseReportItemDocument(DocumentType.REWORK, editorState.header, currentUser.username),
      batchCode: batch.code,
      quantity: rew.quantity,
      reason: availableReworkReasons.find(r => r.code === rew.reasonCode)!,
      elements: rew.elements,
      machineCycleDuration: selectedProduct.machineCycleDuration ?? 0,
      productionDuration: calculateProductionDuration(
        workCenterDivisionCode,
        rew.quantity,
        rew.elements,
        machineDuration
      ),
    }))

    newQuantities[index].discards = data.discards.map(dis => ({
      ...getBaseReportItemDocument(DocumentType.DISCARD, editorState.header, currentUser.username),
      batchCode: batch.code,
      quantity: dis.quantity,
      reason: availableDiscardReasons.find(d => d.code === dis.reasonCode)!,
      elements: dis.elements,
      machineCycleDuration: selectedProduct.machineCycleDuration ?? 0,
      productionDuration: calculateProductionDuration(
        workCenterDivisionCode,
        dis.quantity,
        dis.elements,
        machineDuration
      ),
    }))

    // update test quantity
    const newTests = editorState.tests.map(test => {
      if (test.batchCode === batch.code) {
        return {
          ...test,
          quantity: Number.isNaN(data.quantity) ? 0 : data.quantity,
        }
      }

      return test
    })

    const totalProductionDuration = calculateTotalProductionDuration(newQuantities)

    setEditorState(old => ({
      ...old,
      header: {
        ...old.header,
        totalProductionDuration,
      },
      quantities: newQuantities,
      tests: newTests,
      batches: newBatches,
    }))
  }

  return (
    <NewReportScreenCard>
      <CardContent className="pt-6">
        {quantities[index] && batch && (
          <QuantityForm
            quantity={quantities[index]}
            batch={batch}
            products={availableProducts}
            reworkReasons={availableReworkReasons}
            discardReasons={availableDiscardReasons}
            parentWorkCenters={parentWorkCenters}
            setData={onUpdateQuantity}
            divisionCode={workCenterDivisionCode}
          />
        )}
      </CardContent>
    </NewReportScreenCard>
  )
}

type QuantityFormProps = {
  quantity: QuantityFull
  batch: Batch
  products: Product[]
  reworkReasons: ReworkReason[]
  discardReasons: DiscardReason[]
  parentWorkCenters: WorkCenter[]
  divisionCode: string
  setData: (data: QuantityFormFields) => void
}

const QuantityForm = ({
  quantity,
  batch,
  products,
  reworkReasons,
  discardReasons,
  parentWorkCenters,
  setData,
  divisionCode,
}: QuantityFormProps) => {
  const { t } = useTranslation()

  const form = useForm<z.infer<typeof QUANTITY_FORM_SCHEMA>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: zodResolver(QUANTITY_FORM_SCHEMA),
    defaultValues: {
      productCode: batch.product.code,
      parentWorkCenterCode: batch.parentWorkCenterCode,
      quantity: quantity.production.quantity || NaN,
      elements:
        divisionCode === ASSIEMATRICI_DIVISION_CODE
          ? quantity.production.elements || NaN
          : quantity.production.elements,
      reworks: quantity.reworks.map(r => ({
        quantity: r.quantity,
        elements: r.elements ?? 0,
        reasonCode: r.reason?.code,
      })),
      discards: quantity.discards.map(d => ({
        quantity: d.quantity,
        elements: d.elements ?? 0,
        reasonCode: d.reason?.code,
      })),
    },
  })

  useEffect(() => {
    const subscription = form.watch(data => setData(data as QuantityFormFields))
    return () => subscription.unsubscribe()
  }, [form, setData])

  return (
    <Form {...form}>
      <div className="grid gap-6">
        <div className="grid col-span-full grid-cols-5 gap-4">
          <FormField
            control={form.control}
            name="productCode"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>{t('ProductId')}</FormLabel>
                <FormControl>
                  <ComboSelect
                    autoFocus={!field.value}
                    items={products.map(product => ({
                      label: `${product.code} - ${product.description}`,
                      value: product.code,
                    }))}
                    label={t('ProductId')}
                    value={field.value}
                    onSelect={item => field.onChange(item.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`quantity`}
            render={({ field }) => (
              <FormItem
                className={cn('ml-2', divisionCode === ASSIEMATRICI_DIVISION_CODE ? 'col-span-1' : 'col-span-2')}
              >
                <FormLabel>{divisionCode === ASSIEMATRICI_DIVISION_CODE ? t('Batteries') : t('Quantity')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    autoFocus
                    min="1"
                    placeholder={divisionCode === ASSIEMATRICI_DIVISION_CODE ? t('Batteries') : t('Quantity')}
                    value={Number.isNaN(field.value) ? '' : field.value}
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className={cn(
                      '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                    )}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {divisionCode === ASSIEMATRICI_DIVISION_CODE && (
            <FormField
              control={form.control}
              name="elements"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('ElementsCount')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      autoFocus
                      placeholder={t('ElementsCount')}
                      value={Number.isNaN(field.value) ? '' : field.value}
                      onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                      className={cn(
                        '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                      )}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}
        </div>

        <div className="col-span-full">
          <FormField
            control={form.control}
            name="parentWorkCenterCode"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>{t('ParentWorkCentre')}</FormLabel>
                <FormControl>
                  <ComboSelect
                    showReset
                    resetLabel={t('Any')}
                    items={(parentWorkCenters || []).map(workCenter => ({
                      label: [workCenter.code, ...(workCenter.description && [workCenter.description])].join(' - '),
                      value: workCenter.code,
                    }))}
                    label={t('ParentWorkCentre')}
                    value={field.value}
                    onSelect={item => field.onChange(item.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="col-span-full">
          <h4 className="text-sm font-medium mb-4">{t('ReworkedPieces')}</h4>
          <ReworkDiscardTable
            addButtonHotkey="shift+alt+1"
            form={form}
            type={DocumentType.REWORK}
            reasons={reworkReasons}
            mode={divisionCode === ASSIEMATRICI_DIVISION_CODE ? 'assiematrici' : 'normal'}
          />
        </div>

        <div className="col-span-full">
          <h4 className="text-sm font-medium mb-4">{t('DiscardedPieces')}</h4>
          <ReworkDiscardTable
            addButtonHotkey="shift+alt+2"
            form={form}
            type={DocumentType.DISCARD}
            reasons={discardReasons}
            mode={divisionCode === ASSIEMATRICI_DIVISION_CODE ? 'assiematrici' : 'normal'}
          />
        </div>
      </div>
    </Form>
  )
}

type ReworkDiscardTableProps = {
  type: DocumentType.REWORK | DocumentType.DISCARD
  form: UseFormReturn<QuantityFormFields, unknown, undefined>
  addButtonHotkey: string
  reasons: (ReworkReason | DiscardReason)[]
  mode?: 'normal' | 'assiematrici'
}

const ReworkDiscardTable = ({ type, form, addButtonHotkey, reasons, mode = 'normal' }: ReworkDiscardTableProps) => {
  const { t } = useTranslation()

  const name = type === DocumentType.REWORK ? 'reworks' : 'discards'
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name,
  })

  const addRow = () => {
    append({
      quantity: NaN,
      elements: 0,
      reasonCode: '',
    })
  }

  if (reasons.length === 0) {
    const reasonType = type === DocumentType.REWORK ? t('ReworkedPieces') : t('DiscardedPieces')
    return (
      <div className="h-16 flex items-center justify-center">
        <span className="text-sm text-muted-foreground">
          {t('ReasonsNotProvided', { type: reasonType.toLocaleLowerCase() })}
        </span>
      </div>
    )
  }

  return (
    <Table className="table-fixed">
      <TableHeader>
        <TableRow>
          <TableHead className="w-2/3">{t('CauseId')}</TableHead>
          <TableHead>{mode === 'assiematrici' ? t('ElementsCount') : t('Quantity')}</TableHead>
          <TableHead className="w-20"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fields.map((field, idx) => (
          <TableRow key={field.id}>
            <TableCell>
              <FormField
                control={form.control}
                name={`${name}.${idx}.reasonCode`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ComboSelect
                        autoFocus={!field.value}
                        items={(reasons || []).map(item => ({
                          label: `${item.code} - ${item.description}`,
                          value: item.code,
                        }))}
                        label={t('CauseId')}
                        value={field.value}
                        onSelect={item => form.setValue(`${name}.${idx}.reasonCode`, item.value)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </TableCell>
            <TableCell>
              <FormField
                control={form.control}
                name={`${name}.${idx}.quantity`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        value={Number.isNaN(field.value) ? '' : field.value}
                        onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                        placeholder={mode === 'assiematrici' ? t('ElementsCount') : t('Quantity')}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </TableCell>
            <TableCell>
              <div className="mr-4 flex">
                <Button tabIndex={-1} type="button" onClick={() => remove(idx)} variant="link">
                  <IconTrash />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>
            <div className="mr-4 flex">
              <ButtonWithHotkey
                hotkey={addButtonHotkey}
                tooltip={t('AddObject', {
                  object: type === DocumentType.REWORK ? t('ReworkedPiece') : t('DiscardedPiece'),
                })}
                enableOnFormTags
                type="button"
                onClick={addRow}
                className="mb-2"
                variant="link"
              >
                <IconPlus />
              </ButtonWithHotkey>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
