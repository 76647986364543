import { ComboSelect } from '@/components/ComboSelect'
import { NewReportScreenCard } from '@/components/NewReportScreenCard'
import { CardContent } from '@/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'
import { MEASURES } from '@/lib/conversion'
import { validateMinValue } from '@/lib/form'
import { REQUIRED_NUMBER } from '@/lib/schema'
import { ROUTE } from '@/routes'
import { NewReportOutletContext } from '@/screens/reports/EditReportLayout'
import { Batch } from '@/shared/dto/Batch'
import { Measure, Test } from '@/shared/dto/Test'
import { zodResolver } from '@hookform/resolvers/zod'
import { cloneDeep } from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { z } from 'zod'

type TestFormFields = {
  batchCode: string
  quantity: number
  measures: Record<(typeof MEASURES)[number], number>
}

const TEST_FORM_SCHEMA = z.object({
  batchCode: z.string(),
  quantity: REQUIRED_NUMBER.gt(0),
  measures: z.record(z.nativeEnum(Measure), REQUIRED_NUMBER.gte(0)),
})

export function EditReportTestScreen() {
  const navigate = useNavigate()
  const { index: indexParam } = useParams()

  const { editorState, setEditorState, isEdit } = useOutletContext<NewReportOutletContext>()

  const tests = editorState.tests || []
  const index = tests.findIndex(test => test.batchCode === (indexParam || ''))

  const onUpdateTest = (data: TestFormFields) => {
    const newMeasures = MEASURES.map(measureId => ({
      id: measureId,
      value: data.measures[measureId] ?? 0,
    }))

    // retrieve quantity from report quantities
    const currentQuantity = editorState.quantities.find(q => q.production.batchCode === data.batchCode)
    const batchQuantity = currentQuantity?.production.quantity ?? 0

    const newTests = cloneDeep(tests)
    newTests[index].batchCode = data.batchCode
    newTests[index].quantity = Number.isNaN(batchQuantity) ? 0 : batchQuantity
    newTests[index].measures = newMeasures

    setEditorState(old => ({
      ...old,
      tests: newTests,
    }))

    // change batchCode on URL if batchCode change
    if (data.batchCode !== indexParam) {
      navigate(
        isEdit
          ? generatePath(ROUTE.reportEditTests, { index: data.batchCode, id: editorState.header.code })
          : generatePath(ROUTE.reportCreateTests, { index: data.batchCode }),
        {
          replace: true,
        }
      )
    }
  }

  // remove duplicates product from batches
  const batchesMap = new Map<string, Batch>()
  for (const batchCode in editorState.batches) {
    const batch = editorState.batches[batchCode]
    if (!batchesMap.has(batch.product.code)) {
      batchesMap.set(batch.product.code, batch)
    }
  }

  // remove already used batch codes
  const batchCodeAlreadyUsed = tests
    .map(test => test.batchCode)
    .filter(batchCode => batchCode !== tests[index]?.batchCode)
  const availableBatches = Array.from(batchesMap.values()).filter(batch => !batchCodeAlreadyUsed.includes(batch.code))

  return (
    <NewReportScreenCard>
      <CardContent className="pt-6">
        {tests[index] && (
          <TestForm
            test={tests[index]}
            batches={availableBatches}
            divisionCode={editorState.header.workCenter.divisionCode}
            setData={onUpdateTest}
          />
        )}
      </CardContent>
    </NewReportScreenCard>
  )
}

type TestFormProps = {
  test: Test
  batches: Batch[]
  divisionCode: string
  setData: (data: TestFormFields) => void
}

function TestForm({ test, batches, divisionCode, setData }: TestFormProps) {
  const { t } = useTranslation()

  const { editorState } = useOutletContext<NewReportOutletContext>()

  const form = useForm<z.infer<typeof TEST_FORM_SCHEMA>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(TEST_FORM_SCHEMA),
    defaultValues: {
      batchCode: test.batchCode,
      quantity: test.quantity,
      measures: Object.fromEntries(test.measures.map(m => [m.id, m.value])),
    },
  })

  const batchCode = form.watch('batchCode')
  const currentQuantity = editorState.quantities.find(q => q.production.batchCode === batchCode)

  useEffect(() => {
    const subscription = form.watch(data => setData(data as TestFormFields))
    return () => subscription.unsubscribe()
  }, [form, setData])

  return (
    <Form {...form}>
      <div className="grid gap-6">
        <div className="grid col-span-full grid-cols-4 gap-4">
          <FormField
            control={form.control}
            name={`batchCode`}
            render={({ field }) => (
              <FormItem className={divisionCode === ASSIEMATRICI_DIVISION_CODE ? 'col-span-2' : 'col-span-3'}>
                <FormLabel>{t('ProductId')}</FormLabel>
                <FormControl>
                  <ComboSelect
                    autoFocus={!field.value}
                    items={batches
                      .filter(batch => batch.product.code)
                      .map(batch => ({
                        label: `${batch.product.code} - ${batch.product.description}`,
                        value: batch.code,
                      }))}
                    label={t('ProductId')}
                    value={field.value}
                    onSelect={item => field.onChange(item.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`quantity`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{divisionCode === ASSIEMATRICI_DIVISION_CODE ? t('Batteries') : t('Quantity')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder={divisionCode === ASSIEMATRICI_DIVISION_CODE ? t('Batteries') : t('Quantity')}
                    readOnly={true}
                    disabled={true}
                    tabIndex={-1}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {divisionCode === ASSIEMATRICI_DIVISION_CODE && (
            <FormItem>
              <FormLabel>{t('ElementsCount')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('ElementsCount')}
                  readOnly={true}
                  disabled={true}
                  tabIndex={-1}
                  value={currentQuantity?.production.elements ?? 0}
                  className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              </FormControl>
            </FormItem>
          )}
        </div>

        <div className="grid col-span-full grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name={`measures.HEAD_LEAKS_A`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`${t('HeadLeaks')} A`}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    autoFocus
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`measures.HEAD_LEAKS_B`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`${t('HeadLeaks')} B`}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="grid col-span-full grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name={`measures.HEAD_LEAKS_C`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`${t('HeadLeaks')} C`}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`measures.HEAD_LEAKS_D`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{`${t('HeadLeaks')} D`}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="grid col-span-full grid-cols-3 gap-4">
          <FormField
            control={form.control}
            name={`measures.JOINT_LEAKS`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('JointLeaks')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`measures.PIPE_LEAKS`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('PipeLeaks')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`measures.POROUS_PIPE_LEAKS`}
            rules={validateMinValue(0)}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('PorousPipesLeaks')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min="0"
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '')}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </Form>
  )
}
