import { AuthenticationToken } from '../dto/AuthenticationToken'
import { User } from '../dto/User'

export const TOKEN_TYPE = 'Bearer'

/**
 * Decodes the authentication token from an HTTP header, WITHOUT checking if the signature is valid.
 * Do not use this function for authentication purposes!
 *
 * @param header Content of the authentication header (`Bearer <token>`)
 * @returns the token if valid, otherwise null
 */
export function decodeAuthenticationHeader(header: string | undefined | null): string | null {
  if (header == null) {
    return null
  }

  const [type, token] = header.split(' ')
  if (type !== TOKEN_TYPE) {
    return null
  }

  return token
}

/**
 * Composes the authentication header to set in the HTTP requests with the token
 *
 * @param token the token to encode
 * @returns the encoded HTTP Authorization header
 */
export function composeAuthenticationHeader(token: string): string {
  return `${TOKEN_TYPE} ${token}`
}

/**
 * Decodes a JWT token, without performing signature verification
 *
 * @param token the encoded token as a JWT string
 * @returns the payload contained in the token itself
 */
export function decodeJwtToken(token: string): AuthenticationToken {
  const [, bodyBase64] = token.split('.')

  const body = atob(bodyBase64)

  return JSON.parse(body)
}

/**
 * Gets an user object form an authentication token
 */
export function getUserFromAuthenticationToken(authToken: AuthenticationToken): User {
  return {
    ...authToken,
    id: authToken.sub,
  }
}
