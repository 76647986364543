import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { DeleteButton } from '@/components/DeleteButton'
import { DeleteDialog } from '@/components/DeleteDialog'
import { PaginationFooter } from '@/components/PaginationFooter'
import { ContentLayout } from '@/components/layouts/ContentLayout'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { usePaginatedQuery } from '@/hooks/usePaginatedQuery'
import { CASE_INSENSITIVE_MATCH, cn, escapeForRegex } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import {
  PRODUCTION_DB_DESIGN_DOC_NAME,
  PRODUCTION_DB_NAME,
  WORK_CENTER_STOP_REASONS_BY_REASON_CODE_VIEW_NAME,
} from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { StopReason } from '@/shared/dto/Reason'
import { WorkCenterStopReason } from '@/shared/dto/WorkCenterReason'
import { UserRole, hasRole } from '@/shared/utils/auth'
import { IconDatabasePlus } from '@tabler/icons-react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { StopReasonForm } from './StopReasonForm'

type StopReasonFilters = {
  code?: string
  description?: string
}

const BASE_QUERY = new CouchdbQueryBuilderImpl().where('type', '=', DocumentType.STOP_REASON)

export function StopReasonListScreen() {
  const { currentUser } = useAuthentication()
  const isUserAdmin = hasRole(currentUser, [UserRole.REPORTS_REGISTRY, UserRole.ADMIN])

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<StopReason>(db)

  const [selectedEntry, setSelectedEntry] = useState<StopReason | undefined>()
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [filters, setFilters] = useState<StopReasonFilters>({})
  const [query, setQuery] = useState(BASE_QUERY)

  const updateFilter = (name: keyof StopReasonFilters, value: StopReasonFilters[keyof StopReasonFilters]) => {
    setFilters(old => ({
      ...old,
      [name]: value,
    }))
  }

  const updateQuery = useDebouncedCallback(() => {
    let query = BASE_QUERY

    if (filters.code) {
      query = query.where('code', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(filters.code))
    }
    if (filters.description) {
      query = query.where('description', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(filters.description))
    }

    setQuery(query)
  }, 300)

  useEffect(() => {
    /* trigger query update when filters change */
    updateQuery()
  }, [filters, updateQuery])

  const onDeleteItem = async (item: StopReason) => {
    const workCenterReasons = await db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view<unknown[], WorkCenterStopReason>(WORK_CENTER_STOP_REASONS_BY_REASON_CODE_VIEW_NAME, {
        reduce: false,
        include_docs: true,
        start_key: [item.code],
        end_key: [item.code, {}],
      })
      .then(resp => resp.rows.map(row => row.doc!))
    for (const reason of workCenterReasons) {
      await crudService.delete(reason._id)
    }

    await crudService.delete(item._id)
    refresh()
  }

  const { data, goToNext, goToPrev, pageIndex, hasNext, hasPrev, refresh } = usePaginatedQuery(
    query,
    crudService,
    pageSize
  )

  return (
    <>
      <ContentLayout
        leftActions={
          <ButtonWithHotkey
            hotkey="ctrl+n"
            disabled={!isUserAdmin}
            tooltip={t('AddNewRegistryItem')}
            onClick={() => {
              setSelectedEntry(undefined)
              setEditModalVisible(true)
            }}
            variant="outline"
          >
            <IconDatabasePlus />
          </ButtonWithHotkey>
        }
        footer={
          <PaginationFooter
            setPageSize={setPageSize}
            pageSize={pageSize}
            nextEnabled={hasNext}
            prevEnabled={hasPrev}
            onNext={goToNext}
            onPrev={goToPrev}
            pageIndex={pageIndex}
            currentPageSize={data.length}
          />
        }
      >
        <Table className="table-fixed">
          <TableHeader className="sticky inset-0 bg-secondary">
            <TableRow className="shadow-sticky-header-b [&_th]:pt-4 [&_th]:align-top [&_th]:text-primary [&_input]:text-muted-foreground">
              <TableHead className="w-1/6">
                <div className="flex flex-col">
                  <span>{t('Code')}</span>
                  <Input
                    type="text"
                    value={filters.code || ''}
                    onChange={e => updateFilter('code', e.target.value)}
                    className="my-2 print:hidden"
                    placeholder={t('Code')}
                  />
                </div>
              </TableHead>
              <TableHead>
                <div className="flex flex-col">
                  <span>{t('Description')}</span>
                  <Input
                    type="text"
                    value={filters.description || ''}
                    onChange={e => updateFilter('description', e.target.value)}
                    className="my-2 print:hidden"
                    placeholder={t('Description')}
                  />
                </div>
              </TableHead>
              <TableHead className="w-20">{t('Actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map(item => (
              <TableRow
                key={item.code}
                className={cn(isUserAdmin && 'cursor-pointer', '[&_td]:break-words')}
                onClick={() => {
                  if (isUserAdmin) {
                    setSelectedEntry(item)
                    setEditModalVisible(true)
                  }
                }}
              >
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                  {isUserAdmin ? (
                    <DeleteButton
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSelectedEntry(item)
                        setDeleteModalVisible(true)
                      }}
                    />
                  ) : (
                    <div className="h-9"></div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow />
          </TableBody>
        </Table>
      </ContentLayout>
      <DeleteDialog open={deleteModalVisible} setOpen={setDeleteModalVisible}>
        <DeleteDialog.Content onConfirm={() => selectedEntry && onDeleteItem(selectedEntry)} />
      </DeleteDialog>
      <StopReasonForm
        key={selectedEntry?._id}
        value={selectedEntry}
        open={editModalVisible}
        setOpen={setEditModalVisible}
        onFinish={() => refresh()}
      />
    </>
  )
}
