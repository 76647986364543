import { ComboSelect } from '@/components/ComboSelect'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { EXTERNAL_DIVISION_CODE } from '@/constants'
import { useAllDocs } from '@/hooks/useAllDocs'
import { useCouchdb } from '@/hooks/useCouchdb'
import { dateAndTimeToISO } from '@/lib/datetime'
import { storage } from '@/lib/storage'
import { ROUTE } from '@/routes'
import { FullReportCrudService } from '@/shared/crud/FullReportCrudService'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { ReportState } from '@/shared/dto/Report'
import { ScheduleItem } from '@/shared/dto/ScheduleItem'
import { Shift } from '@/shared/dto/Shift'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { encodeShift } from '@/shared/utils/shift'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type ReportNewFormFields = {
  date: string
  workCenterCode: string
  shiftCode: string
}

type ReportNewModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onConfirm?: () => void
}

export function ReportNewModal({ open, setOpen, onConfirm }: ReportNewModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const lastReportInput = storage.getLastReportInput()

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const schedItemService = useMemo(() => new SimpleCouchCrudService<ScheduleItem>(db), [db])
  const reportService = useMemo(() => new FullReportCrudService(db), [db])

  const form = useForm<ReportNewFormFields>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      date: lastReportInput.date ?? '',
      workCenterCode: '',
      shiftCode: lastReportInput.shiftCode ?? '',
    },
  })

  const dateField = form.watch('date')
  const workCenterCodeField = form.watch('workCenterCode')

  // fetch work centers
  const { data: workCenters } = useAllDocs<DocumentType.WORK_CENTER, WorkCenter>(DocumentType.WORK_CENTER)
  // remove external work centers
  const filteredWorkCenters = (workCenters ?? []).filter(center => center.divisionCode !== EXTERNAL_DIVISION_CODE)

  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([])
  // fetch schedule items for work center
  useEffect(() => {
    const fetchSchedItems = async (workCenterCode: string) => {
      const query = new CouchdbQueryBuilderImpl()
        .where('type', '=', DocumentType.SCHEDULE_ITEM)
        .where('workCenterCode', '=', workCenterCode)

      const response = await schedItemService.query(query.build())
      return response.items
    }

    if (workCenterCodeField !== undefined && workCenterCodeField.length > 0) {
      fetchSchedItems(workCenterCodeField).then(items => {
        const newScheduleItems = items
          .filter(item => {
            const reportDate = DateTime.fromISO(dateField)
            return DateTime.fromISO(item.validFrom) < reportDate && reportDate < DateTime.fromISO(item.validTo)
          })
          .sort((a, b) => a.shiftCode.localeCompare(b.shiftCode))

        setScheduleItems(newScheduleItems)

        // preselect with previous shift
        if (lastReportInput.shiftCode && newScheduleItems.some(s => s.shiftCode === lastReportInput.shiftCode)) {
          form.setValue('shiftCode', lastReportInput.shiftCode)
        } else {
          form.resetField('shiftCode')
        }
      })
    }
  }, [dateField, form, lastReportInput.shiftCode, schedItemService, workCenterCodeField])

  const comboShiftsItems =
    scheduleItems.length > 0
      ? scheduleItems.map(schedItem => ({
          value: schedItem.shiftCode,
          label: schedItem.shiftCode,
        }))
      : [{ value: lastReportInput.shiftCode ?? '', label: lastReportInput.shiftCode ?? '' }]

  const onSubmit = async (data: ReportNewFormFields) => {
    const { date, workCenterCode, shiftCode } = data

    const workCenter = filteredWorkCenters.find(center => center.code === workCenterCode)
    const scheduleItem = scheduleItems.find(item => item.shiftCode === shiftCode)

    if (date.length <= 0 || !workCenter || !scheduleItem) {
      console.error('Invalid form data', data)
      return
    }

    const endDayOffset = scheduleItem.endTime < scheduleItem.startTime ? 1 : 0
    const startDateStr = dateAndTimeToISO(date, scheduleItem.startTime)
    const endDateStr = dateAndTimeToISO(date, scheduleItem.endTime, { day: endDayOffset })

    if (!startDateStr || !endDateStr) {
      console.error('Invalid shift start or end date', { startDateStr, endDateStr })
      return
    }

    const shift: Shift = {
      workCenter: workCenter.code,
      code: scheduleItem.shiftCode,
      start: startDateStr,
      end: endDateStr,
    }

    const encodedShift = encodeShift(shift)

    // check if there is already a report for selected shift
    const report = await reportService.readByShift(encodedShift, ReportState.COMPLETE, DocumentType.REPORT)
    if (report.header?.code !== undefined) {
      form.setError('root', { message: t('ReportAlreadyExists') })
      return
    }

    // save input data to local storage
    storage.setLastReportInput({
      ...lastReportInput,
      date: date,
      shiftCode: shiftCode,
      scheduleItemCode: scheduleItem.code,
      encodedShift: encodedShift,
    })

    navigate(ROUTE.reportCreate)
    setOpen(false)
    if (onConfirm) {
      onConfirm()
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle>{t('NewReport')}</DialogTitle>
          </DialogHeader>
          {form.formState.errors.root?.message && (
            <DialogDescription>
              <span className="font-bold text-red-600">{form.formState.errors.root?.message}</span>
            </DialogDescription>
          )}
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="date"
              rules={{ required: t('MissingRequiredField') }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('Date')}</FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      {...field}
                      onChange={e => {
                        form.resetField('workCenterCode')
                        form.resetField('shiftCode')
                        field.onChange(e)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="workCenterCode"
              rules={{ required: t('MissingRequiredField') }}
              render={({ field }) => (
                <FormItem className="mt-2">
                  <FormLabel>{t('WorkCentre')}</FormLabel>
                  <FormControl>
                    <ComboSelect
                      items={filteredWorkCenters.map(item => ({
                        value: item.code,
                        label: `${item.code} - ${item.description}`,
                      }))}
                      disabled={dateField.length === 0}
                      label={t('WorkCentre')}
                      value={field.value}
                      onSelect={item => {
                        field.onChange(item.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="shiftCode"
              rules={{ required: t('MissingRequiredField') }}
              render={({ field }) => (
                <FormItem className="mt-2">
                  <FormLabel>{t('Shift')}</FormLabel>
                  <FormControl>
                    <ComboSelect
                      items={comboShiftsItems}
                      disabled={workCenterCodeField.length === 0}
                      label={t('Shift')}
                      value={field.value}
                      onSelect={item => {
                        field.onChange(item.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="flex flex-row pt-6 justify-end">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t('Cancel')}
                </Button>
              </DialogClose>
              <Button type="submit" className="ml-2" disabled={form.formState.isLoading}>
                {t('Confirm')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
