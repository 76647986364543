import { User } from '@/shared/dto/User'
import { createContext } from 'react'

export type AuthenticationContextType = {
  /** User details, decoded from the user token */
  currentUser: User

  /** the RAW JWT token, to use to make requests */
  jwtToken: string

  /** clears the authentication information from the app context */
  signOut: () => void
}

export const AuthenticationContext = createContext<AuthenticationContextType | null>(null)
