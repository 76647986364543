import { Shift } from '@/shared/dto/Shift'
import { DateTime, Duration, DurationLike, Interval } from 'luxon'

export const TIME_FORMAT = 'HH:mm'

export function dateAndTimeToISO(date: string, time: string, offset: DurationLike = {}) {
  const baseDate = DateTime.fromISO(date).startOf('day')
  const { hour, minute } = DateTime.fromFormat(time, 'HH:mm:ss')
  return baseDate.set({ hour, minute }).plus(offset).toUTC().toISO({ suppressMilliseconds: true })
}

/**
 * Humanizes an arbitrary number of seconds into minutes with optional rounding
 * @param seconds The number of seconds to transform
 * @param rounded If `true`, the resulting time will be rounded to the nearest minute digit, otherwise will have 3 decimal digits. Defaults to `false`
 * @returns A string containing the humanized time in minutes (for example `45,442 min`)
 */
export function humanizeSeconds(seconds?: number, rounded: boolean = true) {
  if (seconds === undefined || isNaN(seconds)) {
    seconds = 0
  }

  let dur = Duration.fromObject({ seconds }).shiftTo('minutes')
  if (dur.hours === 0) {
    dur = dur.shiftTo('minutes')
  }

  return dur
    .toHuman({
      listStyle: 'short',
      unitDisplay: 'short',
      minimumFractionDigits: rounded ? 0 : 3,
      maximumFractionDigits: rounded ? 0 : 3,
    })
    .replace(',', '.')
}

export function parseTime(base: DateTime, timeStr: string): DateTime | undefined {
  try {
    const parsedTime = DateTime.fromFormat(timeStr, TIME_FORMAT)

    return base.set({ hour: parsedTime.hour, minute: parsedTime.minute, second: 0 })
  } catch {
    return undefined
  }
}

/**
 * Creates an interval inside the given shift. Does not guarantee the resulting interval's validity.
 * @param shift The shift in which to run calculations
 * @param start The start time (HH:mm) of the child interval
 * @param end The end time (HH:mm) of the child interval
 */
export function getIntervalInShift(shift: Shift, start: string, end: string): Interval {
  const startTime = DateTime.fromFormat(start, 'HH:mm')
  if (!startTime.isValid) {
    console.warn(startTime.invalidExplanation)
  }

  const endTime = DateTime.fromFormat(end, 'HH:mm')
  if (!startTime.isValid) {
    console.warn(endTime.invalidExplanation)
  }

  const setHoursMins = (dt: DateTime, time: DateTime) => dt.startOf('day').set({ hour: time.hour, minute: time.minute })

  const shiftStartDt = DateTime.fromISO(shift.start)
  const shiftEndDt = DateTime.fromISO(shift.end)

  const crossesMidnight = !shiftStartDt.hasSame(shiftEndDt, 'day')
  let stopStart = setHoursMins(shiftStartDt, startTime)
  let stopEnd = setHoursMins(shiftStartDt, endTime)

  if (crossesMidnight) {
    // If the stop end time (for example 02:00) is before the stop start time (for example 23:00), we assume the
    // shift end time is the best anchor (probably the following day if the shift ends after midnight).
    // Otherwise, assume the shift start is the best base date (we assume shift start and end are in the same day)
    if (stopEnd < shiftStartDt) {
      stopEnd = setHoursMins(shiftEndDt, endTime)
    }

    // If the stop start time (for example 01:00) is before the shift start time (for example 23:00), we assume the
    // shift end time is the best anchor (probably the following day if the shift ends after midnight).
    // Otherwise, assume the shift start is the best base date.
    // Also check is using the next day as date will put the start in an invalid position
    if (stopStart < shiftStartDt && stopStart.plus({ days: 1 }) < stopEnd) {
      stopStart = setHoursMins(shiftEndDt, startTime)
    }
  }

  return Interval.fromDateTimes(stopStart, stopEnd)
}
