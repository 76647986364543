import IRSAPLogo from '@/assets/img/logo_irsap_black.svg'
import radiator from '@/assets/img/radiator-button.svg'
import { NavigationDrawer, NavigationDrawerItem, NavigationDrawerProps } from '@/components/NavigationDrawer'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { useAuthentication } from '@/hooks/useAuthentication'
import { cn } from '@/lib/utils'
import { ROUTE } from '@/routes'
import {
  IconAssembly,
  IconBarcode,
  IconBuildingFactory2,
  IconClockHour3,
  IconClockPause,
  IconHandStop,
  IconLogout,
  IconRecycle,
  IconReport,
  IconTrashX,
  IconUser,
} from '@tabler/icons-react'
import { t } from 'i18next'
import { PropsWithChildren, useState } from 'react'
import { Link } from 'react-router-dom'

type DrawerLayoutProps = {
  defaultLayout?: number[]
  defaultCollapsed?: boolean
  reloadOnNavigate?: boolean
  navCollapsedSize?: number
} & Omit<NavigationDrawerProps, 'isCollapsed'>

export function DrawerLayout({
  children,
  defaultLayout = [15, 85],
  defaultCollapsed = false,
  reloadOnNavigate = false,
  navCollapsedSize = 4,
}: PropsWithChildren<DrawerLayoutProps>) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const { currentUser, signOut } = useAuthentication()

  const mainMenuItems: NavigationDrawerItem[] = [
    { title: t('Reports'), to: ROUTE.reports, icon: IconReport },
    { title: t('Products'), to: ROUTE.products, icon: IconAssembly },
    { title: t('ProcessingDetails'), to: ROUTE.processingDetails, icon: IconClockHour3 },
    { title: t('StopTimes'), to: ROUTE.workCenterStops, icon: IconClockPause },
    { title: t('ReworkIds'), to: ROUTE.reworksReasons, icon: IconRecycle },
    { title: t('DiscardIds'), to: ROUTE.discardsReasons, icon: IconTrashX },
    { title: t('StopIds'), to: ROUTE.stopReasons, icon: IconHandStop },
    { title: t('WorkCentres'), to: ROUTE.workCenters, icon: IconBuildingFactory2 },
    { title: t('Divisions'), to: ROUTE.divisions, icon: IconBarcode },
  ]

  const userMenuItems: NavigationDrawerItem[] = [
    { title: currentUser.name, to: '#', icon: IconUser },
    { title: 'Logout', to: '#', icon: IconLogout, onClick: signOut },
  ]

  return (
    <div className="h-screen w-screen print:block">
      <ResizablePanelGroup
        direction="horizontal"
        autoSaveId="loom-production-reports-drawer"
        className="h-full items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          minSize={defaultLayout[0]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          className={cn(
            isCollapsed && 'min-w-[50px] transition-all duration-300 ease-in-out',
            'flex flex-col print:hidden'
          )}
        >
          <ScrollArea className="h-screen overflow-auto border-0">
            <div
              className={cn(
                'flex h-drawer-header items-center justify-start',
                isCollapsed ? 'h-drawer-header justify-center' : 'px-2'
              )}
            >
              <Link to="/" reloadDocument={reloadOnNavigate}>
                {isCollapsed ? (
                  <img src={radiator} className="h-7" alt="IRSAP" />
                ) : (
                  <img src={IRSAPLogo} className="h-8 ml-1" alt="IRSAP" />
                )}
              </Link>
            </div>
            <Separator />
            <NavigationDrawer items={mainMenuItems} isCollapsed={isCollapsed} reloadOnNavigate={reloadOnNavigate} />
            <Separator />
            <NavigationDrawer items={userMenuItems} isCollapsed={isCollapsed} reloadOnNavigate={reloadOnNavigate} />
          </ScrollArea>
          <div className="flex flex-col justify-end">
            <p className="text-center text-muted-foreground pb-1">{import.meta.env.PACKAGE_VERSION}</p>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle className="[&_div]:print:hidden" />
        <ResizablePanel defaultSize={defaultLayout[1]} minSize={defaultLayout[1]} className="flex flex-col print:block">
          {children}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  )
}
