import { dateAndTimeToISO, getIntervalInShift } from '@/lib/datetime'
import { calculateProductionDuration } from '@/lib/utils'
import { ListScreenOption } from '@/screens/machineUI/FormListScreen'
import {
  getBaseBatchDocument,
  getBaseReportHeader,
  getBaseReportItemDocument,
} from '@/screens/reports/EditReportLayout'
import { FullReportCrudService, QuantityFull, ReportFull } from '@/shared/crud/FullReportCrudService'
import {
  ProductWithProcessingDetails,
  StopReasonWithDuration,
  WorkCenterViewServices,
} from '@/shared/crud/WorkCenterViewServices'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Batch } from '@/shared/dto/Batch'
import { DiscardReason, ReworkReason } from '@/shared/dto/Reason'
import { Report, ReportState } from '@/shared/dto/Report'
import { ScheduleItem } from '@/shared/dto/ScheduleItem'
import { Shift } from '@/shared/dto/Shift'
import { Stop } from '@/shared/dto/Stop'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { encodeShift } from '@/shared/utils/shift'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useCouchdb } from './useCouchdb'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'

type MachineReportStopRange = {
  start: string
  end: string
}

type MachineReportStop = {
  reasonCode: string
  quantity: number
  ranges: MachineReportStopRange[]
  stopDuration: number
}

type MachineReportRework = {
  quantity: number
  elements: number
  reasonCode: string
}

type MachineReportDiscard = {
  quantity: number
  elements: number
  reasonCode: string
}

type MachineReportProduction = {
  productCode: string
  parentWorkCenterCode: string | undefined
  quantity: number
  elements: number
  reworks: MachineReportRework[]
  discards: MachineReportDiscard[]
  productionDuration: number
}

type OeeInfo = {
  availability: number
  performance: number
  quality: number
}

export type MachineReportState = {
  date: string
  shiftCode: string
  workCenterCode: string
  workerCode: string
  notes: string
  hasBreak: boolean
  productions: MachineReportProduction[]
  stops: MachineReportStop[]
  oee: OeeInfo
}

export function useMachineReport() {
  const db = useCouchdb(PRODUCTION_DB_NAME)

  const [report, setReport] = useState<MachineReportState>({
    date: '',
    shiftCode: '',
    workCenterCode: '',
    workerCode: '',
    notes: '',
    hasBreak: false,
    productions: [],
    stops: [],
    oee: {
      availability: 0,
      performance: 0,
      quality: 0,
    },
  })

  const [scheduleItem, setScheduleItem] = useState<ScheduleItem>()
  const [workCenter, setWorkCenter] = useState<WorkCenter>()

  const [availableProducts, setAvailableProducts] = useState<ProductWithProcessingDetails[]>([])
  const [availableReworkReasons, setAvailableReworkReasons] = useState<ReworkReason[]>([])
  const [availableDiscardReasons, setAvailableDiscardReasons] = useState<DiscardReason[]>([])
  const [availableStopReasons, setAvailableStopReasons] = useState<StopReasonWithDuration[]>([])
  const [parentWorkCenters, setParentWorkCenters] = useState<WorkCenter[]>([])

  const productOptionsMap = useMemo(() => getOptionMap(availableProducts), [availableProducts])
  const reworkOptionsMap = useMemo(() => getOptionMap(availableReworkReasons), [availableReworkReasons])
  const discardOptionsMap = useMemo(() => getOptionMap(availableDiscardReasons), [availableDiscardReasons])
  const stopOptionsMap = useMemo(() => getOptionMap(availableStopReasons), [availableStopReasons])
  const parentWorkCenterOptionsMap = useMemo(() => getOptionMap(parentWorkCenters), [parentWorkCenters])

  const initData = async (workCenterId: string) => {
    // on load fetch date, shifts and cdl
    const workCentreViewService = new WorkCenterViewServices(db, workCenterId)
    workCentreViewService.getAvailableProducts().then(setAvailableProducts)
    workCentreViewService.getAvailableReworkReason().then(setAvailableReworkReasons)
    workCentreViewService.getAvailableDiscardReason().then(setAvailableDiscardReasons)
    workCentreViewService.getAvailableStopReason().then(setAvailableStopReasons)
    workCentreViewService.getParentWorkCenters().then(setParentWorkCenters)

    // report date is the current date
    const currentTimestamp = DateTime.now()
    const currentTime = currentTimestamp.toFormat('HH:mm:ss')

    // fetch schedule items and fine current shift
    const scheduleItems = (await workCentreViewService.getScheduleItems()).sort((s1, s2) =>
      s1.shiftCode.localeCompare(s2.shiftCode)
    )
    let scheduleItem: ScheduleItem | undefined = undefined
    const baseDate = currentTimestamp.startOf('day')
    for (const item of scheduleItems) {
      const start = DateTime.fromFormat(item.startTime, 'HH:mm:ss')
      const startMinusOffset = item.endTime < item.startTime && currentTime < item.startTime ? 1 : 0
      const startTimestamp = baseDate.set({ hour: start.hour, minute: start.minute }).minus({ day: startMinusOffset })

      const end = DateTime.fromFormat(item.endTime, 'HH:mm:ss')
      const endPlusOffset = item.endTime < item.startTime && currentTime > item.startTime ? 1 : 0
      const endTimestamp = baseDate.set({ hour: end.hour, minute: end.minute }).plus({ day: endPlusOffset })

      if (startTimestamp <= currentTimestamp && currentTimestamp <= endTimestamp) {
        scheduleItem = item
        break
      }
    }

    const workCenter = await workCentreViewService.getWorkCenter()

    if (!scheduleItem || !workCenter) {
      throw new Error('Schedule item or work center not found')
    }

    setWorkCenter(workCenter)
    setScheduleItem(scheduleItem)

    const shift = encodeShift(
      getDecodedShift(
        currentTimestamp.toUTC().toISO(),
        scheduleItem.startTime,
        scheduleItem.endTime,
        scheduleItem.shiftCode,
        workCenter.code
      )
    )

    // check for report documents in draft for selected shift
    const reportCrudService = new FullReportCrudService(db)
    const partialReport = await reportCrudService.readByShift(shift, ReportState.DRAFT)

    const partialState = getReportMachineFromReportFull(partialReport)

    const defaultReport = {
      date: currentTimestamp.toUTC().toISO(),
      shiftCode: scheduleItem?.shiftCode,
      workCenterCode: workCenterId,
      workerCode: '',
      notes: '',
      hasBreak: scheduleItem.breakDuration > 0,
      productions: [],
      stops: [],
      oee: {
        availability: 0,
        performance: 0,
        quality: 0,
      },
      ...partialState,
    }

    setReport({
      ...defaultReport,
      oee: getReportOee(defaultReport),
    })
  }

  const updateReport = (newState: Partial<MachineReportState>) => {
    setReport(oldReport => {
      const newReport: MachineReportState = {
        ...oldReport,
        ...newState,
      }

      return {
        ...newReport,
        oee: getReportOee(newReport),
      }
    })
  }

  const getReportOee = (report: MachineReportState): OeeInfo => {
    const currentTime = DateTime.now()
    const start = DateTime.fromFormat(scheduleItem?.startTime ?? '00:00:00', 'HH:mm:ss')
    const shiftStartTime = DateTime.now().set({ hour: start.hour, minute: start.minute })

    console.log({
      startTime: scheduleItem?.startTime,
      currentTime: currentTime.toISO(),
      shiftStartTime: shiftStartTime.toISO(),
    })

    // good pieces + reworks pieces + discard pieces
    let totalUnits = 0
    // only good pieces
    let goodUnits = 0
    // actual machine speed: total units * ideal cycle time
    let totalUnitsTime = 0
    // lost time
    let totalStopDuration = 0

    for (const production of report.productions) {
      const product = availableProducts.find(x => x.code === production.productCode)
      if (product) {
        const productionGoodUnits = getProductionQuantity(divisionCode, production)
        const productionTotalUnits =
          productionGoodUnits +
          production.discards.reduce((acc, d) => acc + d.quantity, 0) +
          production.reworks.reduce((acc, r) => acc + r.quantity, 0)

        totalUnits += productionTotalUnits
        goodUnits += productionGoodUnits
        totalUnitsTime += productionTotalUnits * product.machineCycleDuration
      }
    }

    for (const stop of report.stops) {
      const stopItem = availableStopReasons.find(s => s.code === stop.reasonCode)
      if (stopItem) {
        totalStopDuration += stopItem.stopDuration ? stopItem.stopDuration * stop.quantity : 0
      }
    }

    // total hours planned or elapsed time from start of shift to now
    const plannedProductionTime = currentTime.diff(shiftStartTime, 'seconds').seconds

    // planned production time - lost time
    const runTime = plannedProductionTime - totalStopDuration

    const availability = runTime / plannedProductionTime
    const performance = totalUnitsTime / runTime
    const quality = goodUnits / totalUnits

    console.log({
      runTime,
      plannedProductionTime,
      totalUnitsTime,
      goodUnits,
      totalUnits,
    })

    console.log({
      availability,
      performance,
      quality,
    })

    return {
      availability: isFinite(availability) ? availability : 0,
      performance: isFinite(performance) ? performance : 0,
      quality: isFinite(quality) ? quality : 0,
    }
  }

  const getProduction = (prodIndex: number) => {
    if (!isNaN(prodIndex)) {
      return report.productions[prodIndex]
    }
    return undefined
  }

  const couldAddProduction = (): boolean => {
    return !report.productions.some(p => p.productCode === undefined || p.productCode.length <= 0)
  }

  const addProduction = (): number => {
    const newProductions = report.productions.slice()
    newProductions.push({
      productCode: '',
      parentWorkCenterCode: '',
      quantity: 0,
      elements: 0,
      reworks: [],
      discards: [],
      productionDuration: 0,
    })
    updateReport({ productions: newProductions })
    return newProductions.length - 1
  }

  const removeProduction = (prodIndex: number) => {
    const newProductions = report.productions.slice()
    newProductions.splice(prodIndex, 1)
    updateReport({ productions: newProductions })
  }

  const updateProduction = (prodIndex: number, newProduction: Partial<MachineReportProduction>) => {
    if (!isNaN(prodIndex) && report.productions[prodIndex] !== undefined) {
      const newProductions = report.productions.slice()
      const production = {
        ...newProductions[prodIndex],
        ...newProduction,
      }

      const selectedProd = availableProducts.find(x => x.code === production.productCode)
      if (selectedProd) {
        production.productionDuration = calculateProductionDuration(
          workCenter?.divisionCode ?? '',
          production.quantity,
          production.elements,
          selectedProd.machineCycleDuration
        )
      }

      newProductions[prodIndex] = production
      updateReport({ productions: newProductions })
    }
  }

  const getRework = (prodIndex: number, reworkIndex: number) => {
    if (!isNaN(prodIndex) && !isNaN(reworkIndex) && report.productions[prodIndex] !== undefined) {
      return report.productions[prodIndex].reworks[reworkIndex]
    }
    return undefined
  }

  const couldAddRework = (prodIndex: number): boolean => {
    return !report.productions[prodIndex].reworks.some(r => r.reasonCode === undefined || r.reasonCode.length <= 0)
  }

  const addRework = (prodIndex: number): number => {
    if (!isNaN(prodIndex) && report.productions[prodIndex] !== undefined) {
      const newProductions = report.productions.slice()
      newProductions[prodIndex].reworks.push({
        reasonCode: '',
        quantity: 0,
        elements: 0,
      })
      updateReport({ productions: newProductions })
      return newProductions[prodIndex].reworks.length - 1
    }

    return 0
  }

  const removeRework = (prodIndex: number, reworkIndex: number) => {
    const newProductions = report.productions.slice()
    newProductions[prodIndex].reworks.splice(reworkIndex, 1)
    updateReport({ productions: newProductions })
  }

  const updateRework = (prodIndex: number, reworkIndex: number, newRework: Partial<MachineReportRework>) => {
    if (!isNaN(prodIndex) && !isNaN(reworkIndex) && report.productions[prodIndex] !== undefined) {
      const newProductions = report.productions.slice()
      newProductions[prodIndex].reworks[reworkIndex] = {
        ...newProductions[prodIndex].reworks[reworkIndex],
        ...newRework,
      }
      updateReport({ productions: newProductions })
    }
  }

  const getDiscard = (prodIndex: number, discIndex: number) => {
    if (!isNaN(prodIndex) && !isNaN(discIndex) && report.productions[prodIndex] !== undefined) {
      return report.productions[prodIndex].discards[discIndex]
    }
    return undefined
  }

  const couldAddDiscard = (prodIndex: number): boolean => {
    return !report.productions[prodIndex].discards.some(r => r.reasonCode === undefined || r.reasonCode.length <= 0)
  }

  const addDiscard = (prodIndex: number): number => {
    if (!isNaN(prodIndex) && report.productions[prodIndex] !== undefined) {
      const newProductions = report.productions.slice()
      newProductions[prodIndex].discards.push({
        reasonCode: '',
        quantity: 0,
        elements: 0,
      })
      updateReport({ productions: newProductions })
      return newProductions[prodIndex].discards.length - 1
    }

    return 0
  }

  const removeDiscard = (prodIndex: number, discIndex: number) => {
    const newProductions = report.productions.slice()
    newProductions[prodIndex].discards.splice(discIndex, 1)
    updateReport({ productions: newProductions })
  }

  const updateDiscard = (prodIndex: number, discIndex: number, newDiscard: Partial<MachineReportRework>) => {
    if (!isNaN(prodIndex) && !isNaN(discIndex) && report.productions[prodIndex] !== undefined) {
      const newProductions = report.productions.slice()
      newProductions[prodIndex].discards[discIndex] = {
        ...newProductions[prodIndex].discards[discIndex],
        ...newDiscard,
      }
      updateReport({ productions: newProductions })
    }
  }

  const getStop = (stopIndex: number) => {
    if (!isNaN(stopIndex)) {
      return report.stops[stopIndex]
    }
    return undefined
  }

  const couldAddStop = (): boolean => {
    return !report.stops.some(s => s.reasonCode === undefined || s.reasonCode.length <= 0)
  }

  const addStop = (): number => {
    const newStops = report.stops.slice()
    newStops.push({
      reasonCode: '',
      quantity: 0,
      ranges: [],
      stopDuration: 0,
    })
    updateReport({ stops: newStops })
    return newStops.length - 1
  }

  const removeStop = (stopIndex: number) => {
    const newStops = report.stops.slice()
    newStops.splice(stopIndex, 1)
    updateReport({ stops: newStops })
  }

  const updateStop = (stopIndex: number, newStop: Partial<MachineReportStop>) => {
    if (!isNaN(stopIndex) && report.stops[stopIndex] !== undefined) {
      const newStops = report.stops.slice()
      const stop = {
        ...newStops[stopIndex],
        ...newStop,
      }

      const stopReason = availableStopReasons.find(x => x.code === stop.reasonCode)
      if (stopReason) {
        if (stopReason.stopDuration === undefined || stopReason.stopDuration === null) {
          stop.stopDuration = stop.ranges.reduce(
            (acc, r) =>
              acc +
              getIntervalInShift(
                getDecodedShift(
                  report.date,
                  scheduleItem!.startTime,
                  scheduleItem!.endTime,
                  scheduleItem!.shiftCode,
                  workCenter!.code
                ),
                r.start,
                r.end
              )
                .toDuration()
                .shiftTo('seconds').seconds,
            0
          )
        } else {
          stop.stopDuration = stop.quantity * stopReason.stopDuration
        }
      }

      newStops[stopIndex] = stop
      updateReport({ stops: newStops })
    }
  }

  const getStopRange = (stopIndex: number, rangeIndex: number) => {
    if (!isNaN(stopIndex) && !isNaN(rangeIndex) && report.stops[stopIndex] !== undefined) {
      return report.stops[stopIndex].ranges[rangeIndex]
    }
    return undefined
  }

  const addStopRange = (stopIndex: number): number => {
    const newStops = report.stops.slice()
    newStops[stopIndex].ranges.push({
      start: '',
      end: '',
    })
    updateReport({ stops: newStops })
    return newStops[stopIndex].ranges.length - 1
  }

  const removeStopRange = (stopIndex: number, rangeIndex: number) => {
    const newStops = report.stops.slice()
    newStops[stopIndex].ranges.splice(rangeIndex, 1)
    updateReport({ stops: newStops })
  }

  const updateStopRange = (stopIndex: number, rangeIndex: number, newRange: MachineReportStopRange) => {
    if (!isNaN(stopIndex) && !isNaN(rangeIndex) && report.stops[stopIndex] !== undefined) {
      const newStops = report.stops.slice()
      newStops[stopIndex].ranges[rangeIndex] = newRange
      updateReport({ stops: newStops })
    }
  }

  const saveReport = async () => {
    if (!workCenter || !scheduleItem) {
      throw new Error('Work center or schedule item are undefined')
    }

    const reportCrudService = new FullReportCrudService(db)
    const reportFull: ReportFull = await getReportFullFromReportMachine(
      report,
      workCenter,
      scheduleItem,
      availableProducts,
      availableReworkReasons,
      availableDiscardReasons,
      availableStopReasons
    )
    const response = await reportCrudService.write(reportFull)
    if (!response) {
      throw new Error(`Error inserting document`)
    }
  }

  const divisionCode = workCenter?.divisionCode ?? ''

  return {
    report,
    divisionCode,
    availableProducts,
    availableReworkReasons,
    availableDiscardReasons,
    availableStopReasons,
    parentWorkCenters,
    productOptionsMap,
    reworkOptionsMap,
    discardOptionsMap,
    stopOptionsMap,
    parentWorkCenterOptionsMap,
    initData,
    updateReport,
    saveReport,
    getProduction,
    addProduction,
    updateProduction,
    removeProduction,
    couldAddProduction,
    getRework,
    addRework,
    updateRework,
    removeRework,
    couldAddRework,
    getDiscard,
    addDiscard,
    updateDiscard,
    removeDiscard,
    couldAddDiscard,
    getStop,
    addStop,
    updateStop,
    removeStop,
    couldAddStop,
    getStopRange,
    addStopRange,
    updateStopRange,
    removeStopRange,
  }
}

function getOptionMap(items: { code: string; description: string }[]): Record<string, ListScreenOption> {
  const map: Record<string, ListScreenOption> = {}
  for (const i of items) {
    map[i.code] = {
      value: i.code,
      label: `${i.code} - ${i.description}`,
    }
  }
  return map
}

function getReportMachineFromReportFull(partialReport: Partial<ReportFull>): Partial<MachineReportState> {
  const partialState: Partial<MachineReportState> = {
    productions: [],
    stops: [],
  }

  for (const quantity of partialReport.quantities ?? []) {
    partialState.productions?.push({
      productCode: quantity.production.code,
      parentWorkCenterCode: partialReport.batches?.[quantity.production.batchCode].parentWorkCenterCode,
      quantity: quantity.production.quantity,
      elements: quantity.production.elements,
      reworks: quantity.reworks.map(rework => ({
        quantity: rework.quantity,
        elements: rework.elements,
        reasonCode: rework.reason.code,
      })),
      discards: quantity.discards.map(discard => ({
        quantity: discard.quantity,
        elements: discard.elements,
        reasonCode: discard.reason.code,
      })),
      productionDuration: quantity.production.productionDuration,
    })
  }

  for (const stopReasonCode in partialReport.stops) {
    partialState.stops?.push({
      reasonCode: stopReasonCode,
      quantity: partialReport.stops[stopReasonCode].length,
      ranges: partialReport.stops[stopReasonCode]
        .filter(s => s.start !== null && s.end !== null)
        .map(s => ({ start: s.start!, end: s.end! })),
      stopDuration: partialReport.stops[stopReasonCode].reduce((acc, s) => acc + s.stopDuration, 0),
    })
  }

  return partialState
}

async function getReportFullFromReportMachine(
  state: MachineReportState,
  workCenter: WorkCenter,
  scheduleItem: ScheduleItem,
  products: ProductWithProcessingDetails[],
  reworkReasons: ReworkReason[],
  discardReasons: DiscardReason[],
  stopReasons: StopReasonWithDuration[]
): Promise<ReportFull> {
  const endDayOffset = scheduleItem.endTime < scheduleItem.startTime ? 1 : 0
  const startDateStr = dateAndTimeToISO(state.date, scheduleItem.startTime)
  const endDateStr = dateAndTimeToISO(state.date, scheduleItem.endTime, { day: endDayOffset })

  const decodedShift: Shift = {
    workCenter: state.workCenterCode,
    code: scheduleItem.shiftCode,
    start: startDateStr ?? '',
    end: endDateStr ?? '',
  }

  const header: Report = {
    ...getBaseReportHeader('MACHINE_UI'),
    shift: encodeShift(decodedShift),
    decodedShift: decodedShift,
    workCenter: workCenter,
    breakDuration: state.hasBreak === false ? 0 : scheduleItem.breakDuration,
    shiftChangeDuration: scheduleItem.shiftChangeDuration,
    totalProductionDuration: 0,
    totalStopDuration: 0,
  }

  const batches: Record<string, Batch> = {}
  const quantities: QuantityFull[] = []
  const stops: Record<string, Stop[]> = {}

  for (const production of state.productions) {
    const product = products.find(p => p.code === production.productCode)
    if (!product) continue

    const batch = getBaseBatchDocument('MACHINE_UI')
    batch.product = product
    batch.parentWorkCenterCode = production.parentWorkCenterCode
    batches[batch.code] = batch

    const quantity: QuantityFull = {
      production: {
        ...getBaseReportItemDocument(DocumentType.PRODUCTION, header, 'MACHINE_UI'),
        batchCode: batch.code,
        quantity: production.quantity,
        elements: production.elements,
        machineCycleDuration: product.machineCycleDuration,
        productionDuration: 0,
      },
      discards: [],
      reworks: [],
    }

    for (const rework of production.reworks) {
      const reason = reworkReasons.find(r => r.code === rework.reasonCode)
      if (!reason) continue

      quantity.reworks.push({
        ...getBaseReportItemDocument(DocumentType.REWORK, header, 'MACHINE_UI'),
        reason,
        batchCode: batch.code,
        quantity: rework.quantity,
        elements: rework.elements,
        machineCycleDuration: 0,
        productionDuration: 0,
      })
    }

    for (const discard of production.discards) {
      const reason = discardReasons.find(d => d.code === discard.reasonCode)
      if (!reason) continue

      quantity.discards.push({
        ...getBaseReportItemDocument(DocumentType.DISCARD, header, 'MACHINE_UI'),
        reason,
        batchCode: batch.code,
        quantity: discard.quantity,
        elements: discard.elements,
        machineCycleDuration: 0,
        productionDuration: 0,
      })
    }

    quantities.push(quantity)
  }

  for (const stopItem of state.stops) {
    const reason = stopReasons.find(s => s.code === stopItem.reasonCode)
    if (!reason) continue

    stops[reason.code] = []

    if (stopItem.ranges.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const _range of stopItem.ranges) {
        stops[reason.code].push({
          ...getBaseReportItemDocument(DocumentType.STOP, header, 'MACHINE_UI'),
          reason,
          stopDuration: 0,
          start: null,
          end: null,
        })
      }
    } else {
      for (let i = 0; i < stopItem.quantity; i++) {
        stops[reason.code].push({
          ...getBaseReportItemDocument(DocumentType.STOP, header, 'MACHINE_UI'),
          reason,
          stopDuration: 0,
          start: null,
          end: null,
        })
      }
    }
  }

  return {
    header,
    quantities,
    stops,
    batches: batches,
    tests: [],
  }
}

const getDecodedShift = (
  date: string,
  startTime: string,
  endTime: string,
  shiftCode: string,
  workCenterCode: string
): Shift => {
  const endDayOffset = endTime < startTime ? 1 : 0
  const startDateStr = dateAndTimeToISO(date, startTime)
  const endDateStr = dateAndTimeToISO(date, endTime, { day: endDayOffset })

  return {
    workCenter: workCenterCode,
    code: shiftCode,
    start: startDateStr ?? '',
    end: endDateStr ?? '',
  }
}

export function getProductionQuantity(divisionCode: string, production: MachineReportProduction) {
  if (divisionCode === ASSIEMATRICI_DIVISION_CODE) {
    if (production.quantity > production.elements) return 0
    return production.elements - production.quantity
  }

  return production.quantity
}
