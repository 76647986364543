import { Couchdb, CouchdbFindQuery } from '@iotinga/ts-backpack-couchdb-client'
import { PRODUCTION_DB_DESIGN_DOC_NAME, REPORT_QUANTITIES_VIEW_NAME } from '../db/production'
import { DocumentType } from '../dto/BaseDocument'
import { Report } from '../dto/Report'
import { QueryResult, QueryService } from './types'

type QuantityElementsPair = [number, number | null]

export type ReportWithQuantitiesCount = Report & {
  productionCount: QuantityElementsPair
  reworksCount: number
  discardsCount: number
}

export class ReportListQueryService implements QueryService<ReportWithQuantitiesCount> {
  constructor(protected readonly db: Couchdb) {}

  quantityMapKey(chunks: string[]): string {
    return chunks.join('_')
  }

  async query(query: CouchdbFindQuery): Promise<QueryResult<ReportWithQuantitiesCount>> {
    const records = await this.db.find(query)
    const reports = records.docs as Report[]

    const quantitiesViewResponse = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .viewQueries(REPORT_QUANTITIES_VIEW_NAME, {
        queries: reports.map(report => ({
          group: true,
          group_level: 2,
          start_key: [report.code],
          end_key: [report.code, {}],
        })),
      })

    const flat = quantitiesViewResponse.results.flatMap(quantities => quantities.rows)
    const quantitiesCount = new Map<string, QuantityElementsPair>()
    flat.forEach(obj =>
      quantitiesCount.set(this.quantityMapKey(obj.key as string[]), obj.value as QuantityElementsPair)
    )

    const items: ReportWithQuantitiesCount[] = []
    for (const report of reports) {
      const reworksPair = quantitiesCount.get(this.quantityMapKey([report.code, DocumentType.REWORK]))
      const discardsPair = quantitiesCount.get(this.quantityMapKey([report.code, DocumentType.DISCARD]))

      items.push({
        ...report,
        productionCount: quantitiesCount.get(this.quantityMapKey([report.code, DocumentType.PRODUCTION])) ?? [0, null],
        reworksCount: reworksPair ? reworksPair[0] : 0,
        discardsCount: discardsPair ? discardsPair[0] : 0,
      })
    }

    return {
      items: items,
      paginationToken: records.bookmark,
    }
  }
}
