import { CouchdbManagerContext } from '@/contexts/CouchdbManagerContext'
import { useContext } from 'react'

export function useCouchdb(dbName: string) {
  const couchDbManagerCtx = useContext(CouchdbManagerContext)

  if (couchDbManagerCtx === null) {
    throw new Error(`CouchdbManagerProvider not in the root of this component`)
  }

  return couchDbManagerCtx.getDb(dbName)
}
