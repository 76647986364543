import { ButtonGroup } from '@/components/ButtonGroup'
import { Pagination, PaginationContent, PaginationNext, PaginationPrevious } from '@/components/ui/pagination'
import { ReactNode } from 'react'

export type PaginationButtonsProps = {
  children: ReactNode
  prevDisabled?: boolean
  nextDisabled?: boolean
  onPrevClick?: () => void
  onNextClick?: () => void
}

export function PaginationButtons({
  children,
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
}: PaginationButtonsProps) {
  return (
    <Pagination className="my-2">
      <PaginationContent>
        <ButtonGroup>
          <PaginationPrevious
            onClick={() => onPrevClick && onPrevClick()}
            disabled={prevDisabled}
            className="rounded-none mt-0.5 [&_span]:mb-0.5"
          />
          <div className="flex px-4 min-w-40 justify-center text-sm">{children}</div>
          <PaginationNext
            onClick={() => onNextClick && onNextClick()}
            disabled={nextDisabled}
            className="rounded-none mt-0.5 [&_span]:mb-0.5"
          />
        </ButtonGroup>
      </PaginationContent>
    </Pagination>
  )
}
