import { useEffect } from 'react'

/**
 * Utility hook to hijack the viewport meta tag in `<head>` to make the page unzoomable on mobile
 */
export const useUnzoomablePage = () =>
  useEffect(() => {
    const tag = document.querySelector<HTMLMetaElement>('meta[name="viewport"]')
    if (!tag) {
      return
    }

    const oldContent = tag.content
    tag.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no'

    return () => {
      tag.content = oldContent
    }
  }, [])
