import { QueryResult, QueryService } from '@/shared/crud/types'
import { CouchdbQueryBuilder } from '@/shared/db/CouchdbQueryBuilder'
import { useCallback, useEffect, useMemo, useState } from 'react'

type QueryCache<D> = Record<number, QueryResult<D> | null>

type TokenCache = Record<number, string>

const EMPTY_QUERY_CACHE = {
  0: null,
}

const EMPTY_TOKEN_CACHE = {
  0: '',
}

type UsePaginatedQueryType<D> = {
  /** THe currently visible data */
  data: D[]

  /** The current page index */
  pageIndex: number

  /** Paginates to the previous page */
  goToPrev: () => void

  /** Paginates to the next page */
  goToNext: () => void

  /** Only `true` if there is a page preceding the current one */
  hasPrev: boolean

  /** Only `true` if there is a page following the current one */
  hasNext: boolean

  /* Refresh data */
  refresh: () => void
}

export function usePaginatedQuery<D extends Record<string, unknown>>(
  query: CouchdbQueryBuilder<D>,
  crudService: QueryService<D>,
  pageSize: number
): UsePaginatedQueryType<D> {
  const [queryCache, setQueryCache] = useState<QueryCache<D>>(EMPTY_QUERY_CACHE)
  const [tokenCache, setTokenCache] = useState<TokenCache>(EMPTY_TOKEN_CACHE)
  const [pageIndex, setPageIndex] = useState(0)

  const [revalidate, setRevalidate] = useState(false)

  const refresh = () => {
    setRevalidate(true)
  }

  // Reset page number on page size change or query change
  useEffect(() => {
    setPageIndex(0)
    setTokenCache(EMPTY_TOKEN_CACHE)
    setRevalidate(true)
  }, [pageSize, query])

  // Cache current page if not already cached
  useEffect(() => {
    if (!queryCache[pageIndex] === null || revalidate) {
      setRevalidate(false)

      crudService.query(query.limit(pageSize).paginationToken(tokenCache[pageIndex]).build()).then(resp => {
        setQueryCache({
          [pageIndex]: resp,
        })
        setTokenCache(old => ({
          ...old,
          [pageIndex + 1]: resp.paginationToken || '',
        }))
      })
    }
  }, [queryCache, pageSize, query, crudService, pageIndex, tokenCache, revalidate])

  // Cache next page if not already cached
  useEffect(() => {
    const nextPage = pageIndex + 1
    const nextToken = tokenCache[nextPage]

    if (nextToken && nextToken !== 'nil' && !queryCache[nextPage]) {
      const prefetchQuery = query.limit(pageSize).paginationToken(nextToken).build()

      crudService.query(prefetchQuery).then(resp => {
        setQueryCache(old => ({
          ...old,
          [nextPage]: resp,
        }))
        setTokenCache(old => ({
          ...old,
          [nextPage + 1]: resp.paginationToken || '',
        }))
      })
    }
  }, [crudService, pageIndex, pageSize, query, queryCache, revalidate, tokenCache])

  const goToPrev = useCallback(() => {
    setPageIndex(pageIndex - 1)
  }, [pageIndex, setPageIndex])

  const goToNext = useCallback(() => {
    setPageIndex(pageIndex + 1)
  }, [pageIndex, setPageIndex])

  return useMemo(
    () => ({
      data: queryCache[pageIndex]?.items || [],
      goToPrev,
      goToNext,
      pageIndex,
      hasPrev: pageIndex !== 0,
      hasNext:
        pageIndex + 1 in queryCache && !!queryCache[pageIndex + 1] && queryCache[pageIndex + 1]!.items.length > 0,
      refresh,
    }),
    [queryCache, pageIndex, goToPrev, goToNext]
  )
}
