import { ButtonWithTooltip } from '@/components/ButtonWithTooltip'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { buttonVariants } from '@/components/ui/button'
import { IconTrash } from '@tabler/icons-react'
import { Dispatch, PropsWithChildren, ReactNode, SetStateAction, memo } from 'react'
import { useTranslation } from 'react-i18next'

export function DeleteDialog({
  children,
  open,
  setOpen,
}: PropsWithChildren<{
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}>) {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {children}
    </AlertDialog>
  )
}

DeleteDialog.Trigger = memo(({ children, onClick = () => {} }: PropsWithChildren<{ onClick?: () => void }>) => {
  const { t } = useTranslation()

  return (
    <AlertDialogTrigger asChild>
      {children || (
        <ButtonWithTooltip
          className="h-9 w-9"
          tooltip={t('DeleteObject', { object: undefined })}
          tooltipContentProps={{
            side: 'right',
          }}
          onClick={onClick}
          variant="ghost"
          size="icon"
        >
          <IconTrash className="h-5" />
        </ButtonWithTooltip>
      )}
    </AlertDialogTrigger>
  )
})

type DeleteDialogContentProps = {
  onConfirm?: () => void
  onCancel?: () => void
  title?: string
  description?: string
  children?: ReactNode
  confirmButton?: ReactNode
  cancelButton?: ReactNode
}

DeleteDialog.Content = function Content(props: DeleteDialogContentProps) {
  const { t } = useTranslation()

  const {
    onConfirm,
    onCancel,
    title = t('ConfirmDeleteTitle'),
    description = t('ConfirmDeleteBody'),
    confirmButton = (
      <AlertDialogAction className={buttonVariants({ variant: 'destructive' })} onClick={onConfirm}>
        {t('Confirm')}
      </AlertDialogAction>
    ),
    cancelButton = <AlertDialogCancel onClick={onCancel}>{t('Cancel')}</AlertDialogCancel>,
    children,
  } = props

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription>{description}</AlertDialogDescription>
      </AlertDialogHeader>
      {children}
      <AlertDialogFooter className="mt-4">
        {cancelButton}
        {confirmButton}
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
