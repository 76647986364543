import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { t } from 'i18next'
import { PaginationButtons } from './PaginationButtons'

export function PaginationFooter({
  nextEnabled,
  prevEnabled,
  onNext,
  onPrev,
  pageIndex,
  pageSize,
  setPageSize,
  currentPageSize = pageSize,
}: {
  nextEnabled: boolean
  prevEnabled: boolean
  pageIndex: number
  pageSize: number
  currentPageSize?: number
  setPageSize: (size: number) => void
  onNext: () => void
  onPrev: () => void
}) {
  return (
    <div className="grid grid-rows-1 grid-cols-3 items-center border-t print:hidden">
      <div></div>
      <PaginationButtons
        prevDisabled={!prevEnabled}
        nextDisabled={!nextEnabled}
        onPrevClick={onPrev}
        onNextClick={onNext}
      >
        <div className="text-right">
          {pageIndex * pageSize + 1}
          <span className="text-muted-foreground mx-1">-</span>
          {Math.min((pageIndex + 1) * pageSize, currentPageSize + pageIndex * pageSize)}
        </div>
      </PaginationButtons>
      <div className="self-center mr-4 flex flew-row items-center">
        <span className="mr-4 flex-1 text-right text-muted-foreground text-sm">{t('ItemsPerPage')}</span>
        <Select onValueChange={value => setPageSize(parseInt(value))} value={pageSize.toString()}>
          <SelectTrigger className="w-20">
            <SelectValue>{pageSize}</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="20">20</SelectItem>
            <SelectItem value="50">50</SelectItem>
            <SelectItem value="100">100</SelectItem>
            <SelectItem value="200">200</SelectItem>
            <SelectItem value="500">500</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
