import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'
import { humanizeSeconds } from '@/lib/datetime'
import { MEASURE_LABELS } from '@/lib/localize'
import { cn } from '@/lib/utils'
import { ROUTE } from '@/routes'
import { QuantityFull, ReportFull } from '@/shared/crud/FullReportCrudService'
import { Batch } from '@/shared/dto/Batch'
import { Report } from '@/shared/dto/Report'
import { Stop } from '@/shared/dto/Stop'
import { Test } from '@/shared/dto/Test'
import { formatLocalTimeHumanReadable } from '@/shared/utils/datetime'
import { DateTime } from 'luxon'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ZodFormattedError } from 'zod'
import { Separator } from './ui/separator'

const COMMON_CARD_CLASS = 'print:block print:p-0 print:m-0 print:border-0 print:shadow-none'

type ReportViewProps = {
  isEdit: boolean
  report: ReportFull
  errors?: ZodFormattedError<ReportFull> | undefined
  warnings?: ZodFormattedError<ReportFull> | undefined
  linkingEnabled?: boolean
}

export function ReportView({ isEdit, report, errors, warnings, linkingEnabled = false }: ReportViewProps) {
  const { t } = useTranslation()
  const { id: reportCode } = useParams()

  return (
    <div className="flex flex-col gap-4">
      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('ReportDetails', { id: undefined })}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportHeaderCard
            header={report.header}
            errors={errors?.header}
            warnings={warnings?.header}
            generateLink={
              linkingEnabled
                ? () => (isEdit ? generatePath(ROUTE.reportEdit, { id: reportCode || null }) : ROUTE.reportCreate)
                : undefined
            }
          />
        </CardContent>
      </Card>

      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('ProductionQuantityDetails')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportQuantityCard
            header={report.header}
            quantities={report.quantities}
            batches={report.batches}
            errors={errors?.quantities}
            generateLink={
              linkingEnabled
                ? q =>
                    isEdit
                      ? generatePath(ROUTE.reportEditQuantity, {
                          index: q.production.batchCode,
                          id: reportCode || null,
                        })
                      : generatePath(ROUTE.reportCreateQuantity, { index: q.production.batchCode })
                : undefined
            }
          />
        </CardContent>
      </Card>

      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('ReworkedPieces')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportReworksCard
            header={report.header}
            quantities={report.quantities}
            batches={report.batches}
            errors={errors?.quantities}
            generateLink={
              linkingEnabled
                ? q =>
                    isEdit
                      ? generatePath(ROUTE.reportEditQuantity, {
                          index: q.production.batchCode,
                          id: reportCode || null,
                        })
                      : generatePath(ROUTE.reportCreateQuantity, { index: q.production.batchCode })
                : undefined
            }
          />
        </CardContent>
      </Card>

      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('DiscardedPieces')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportDiscardsCard
            header={report.header}
            quantities={report.quantities}
            batches={report.batches}
            errors={errors?.quantities}
            generateLink={
              linkingEnabled
                ? q =>
                    isEdit
                      ? generatePath(ROUTE.reportEditQuantity, {
                          index: q.production.batchCode,
                          id: reportCode || null,
                        })
                      : generatePath(ROUTE.reportCreateQuantity, { index: q.production.batchCode })
                : undefined
            }
          />
        </CardContent>
      </Card>

      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('StopsDetails')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportStopCard
            stops={report.stops}
            errors={errors?.stops}
            generateLink={
              linkingEnabled
                ? s =>
                    isEdit
                      ? generatePath(ROUTE.reportEditStops, { index: s.reason.code, id: reportCode || null })
                      : generatePath(ROUTE.reportCreateStops, { index: s.reason.code })
                : undefined
            }
          />
        </CardContent>
      </Card>

      <Card className={COMMON_CARD_CLASS}>
        <CardHeader>
          <CardTitle>{t('TestsDetails')}</CardTitle>
        </CardHeader>
        <CardContent>
          <ReportTestCard
            tests={report.tests}
            batches={report.batches}
            errors={errors?.tests}
            generateLink={
              linkingEnabled
                ? t =>
                    isEdit
                      ? generatePath(ROUTE.reportEditTests, { index: t.batchCode, id: reportCode || null })
                      : generatePath(ROUTE.reportCreateTests, { index: t.batchCode })
                : undefined
            }
          />
        </CardContent>
      </Card>
    </div>
  )
}

function Cell({
  value,
  className,
  errorReplacesValue,
  error,
  warning,
}: {
  value: ReactNode
  className?: string
  errorReplacesValue?: boolean
  error: { _errors: string[] } | undefined
  warning: { _errors: string[] } | undefined
}) {
  return (
    <div className={cn('flex flex-col', className)}>
      {error && errorReplacesValue ? null : (
        <span className={cn(warning && 'text-amber-600', error && 'text-red-600')}>{value}</span>
      )}
      {error && <span className="font-bold text-red-600">{error._errors[0]}</span>}
      {warning && <span className="font-bold text-amber-600">{warning._errors[0]}</span>}
    </div>
  )
}

type ReportHeaderCardProps = {
  header: Report
  errors?: ZodFormattedError<Report>
  warnings?: ZodFormattedError<Report>
  generateLink?: () => string
}

export const ReportHeaderCard = ({ header, errors, warnings, generateLink }: ReportHeaderCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col w-full space-y-6">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t('Date')}</TableHead>
            <TableHead>{t('Shift')}</TableHead>
            <TableHead>{t('WorkCentre')}</TableHead>
            <TableHead>{t('WorkerId')}</TableHead>
            <TableHead>{t('BreakTime')}</TableHead>
            <TableHead>{t('Duration')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow
            className={generateLink && 'cursor-pointer'}
            onClick={() => generateLink && navigate(generateLink())}
          >
            <TableCell>
              <Cell
                value={DateTime.fromISO(header.decodedShift.start).toFormat('dd/MM/yyyy')}
                error={errors?.decodedShift?.start}
                warning={warnings?.decodedShift?.start}
              />
            </TableCell>
            <TableCell>
              <Cell
                value={header.decodedShift.code}
                error={errors?.decodedShift?.code}
                warning={warnings?.decodedShift?.code}
              />
            </TableCell>
            <TableCell>
              <Cell
                value={header.decodedShift.workCenter}
                error={errors?.decodedShift?.workCenter}
                warning={warnings?.decodedShift?.workCenter}
              />
            </TableCell>
            <TableCell>
              <Cell value={header.workerCode} error={errors?.workerCode} warning={warnings?.workerCode} />
            </TableCell>
            <TableCell>
              <Cell
                value={header.breakDuration > 0 ? t('Yes') : t('No')}
                error={errors?.breakDuration}
                warning={warnings?.breakDuration}
              />
            </TableCell>
            <TableCell>
              <Cell
                value={humanizeSeconds(header.totalShiftDuration)}
                error={errors?.totalShiftDuration}
                warning={warnings?.totalShiftDuration}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {header.notes.length > 0 && (
        <div className="space-y-2">
          <Label>{t('Notes')}</Label>
          <p className="text-sm">{header.notes}</p>
        </div>
      )}
    </div>
  )
}

type ReportQuantityCardProps = {
  header: Report
  quantities: QuantityFull[]
  batches: Record<string, Batch>
  errors?: ZodFormattedError<QuantityFull[]>
  warnings?: ZodFormattedError<QuantityFull[]>
  generateLink?: (value: QuantityFull, index: number) => string
}

const ReportQuantityCard = ({
  quantities,
  batches,
  errors,
  warnings,
  generateLink,
  header,
}: ReportQuantityCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('ProductId')}</TableHead>
          <TableHead>{t('ParentWorkCentre')}</TableHead>
          {header.workCenter.divisionCode === ASSIEMATRICI_DIVISION_CODE ? (
            <>
              <TableHead>{t('ElementsCount')}</TableHead>
              <TableHead>{t('Batteries')}</TableHead>
            </>
          ) : (
            <TableHead>{t('Quantity')}</TableHead>
          )}

          <TableHead>{t('Duration')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quantities
          .sort((a, b) =>
            batches[a.production.batchCode].product.code.localeCompare(batches[b.production.batchCode].product.code)
          )
          .map((row, idx) => (
            <TableRow
              key={idx}
              className={generateLink && 'cursor-pointer'}
              onClick={() => generateLink && navigate(generateLink(row, idx))}
            >
              <TableCell className="align-top">
                <Cell
                  value={row.production && batches[row.production.batchCode].product.code}
                  error={errors?.[idx].production?.batchCode}
                  warning={warnings?.[idx].production?.batchCode}
                />
              </TableCell>
              <TableCell className="align-top">
                <Cell
                  value={row.production && batches[row.production.batchCode].parentWorkCenterCode}
                  error={errors?.[idx].production?.batchCode}
                  warning={warnings?.[idx].production?.batchCode}
                />
              </TableCell>
              {header.workCenter.divisionCode === ASSIEMATRICI_DIVISION_CODE && (
                <TableCell className="align-top">
                  <Cell
                    value={row.production && row.production.elements}
                    error={errors?.[idx].production?.elements}
                    warning={warnings?.[idx].production?.elements}
                  />
                </TableCell>
              )}
              <TableCell className="align-top">
                <Cell
                  value={row.production && row.production.quantity}
                  error={errors?.[idx].production?.quantity}
                  warning={warnings?.[idx].production?.quantity}
                />
              </TableCell>
              <TableCell>
                {humanizeSeconds(
                  row.production.productionDuration +
                    row.discards.reduce((total, dis) => total + dis.productionDuration, 0) +
                    row.reworks.reduce((total, rew) => total + rew.productionDuration, 0)
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

const ReportReworksCard = ({ quantities, batches, errors, warnings, generateLink }: ReportQuantityCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('ProductId')}</TableHead>
          <TableHead>{t('Quantity')}</TableHead>
          <TableHead>{t('CauseId')}</TableHead>
          <TableHead>{t('Description')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quantities
          .sort((a, b) =>
            batches[a.production.batchCode].product.code.localeCompare(batches[b.production.batchCode].product.code)
          )
          .map((row, idx) =>
            row.reworks.map((rework, jdx) => (
              <TableRow
                key={rework._id}
                className={generateLink && 'cursor-pointer'}
                onClick={() => generateLink && navigate(generateLink(row, idx))}
              >
                <TableCell className="align-top">
                  <Cell
                    value={row.production && batches[row.production.batchCode].product.code}
                    error={errors?.[idx].production?.batchCode}
                    warning={warnings?.[idx].production?.batchCode}
                  />
                </TableCell>
                <TableCell className="align-top">
                  <Cell
                    value={rework.quantity}
                    error={errors?.[idx]?.reworks?.[jdx]?.quantity}
                    warning={warnings?.[idx]?.reworks?.[jdx]?.quantity}
                  />
                </TableCell>
                <TableCell className="align-top">
                  <Cell
                    value={rework.reason?.code}
                    error={errors?.[idx]?.reworks?.[jdx]?.reason?.code}
                    warning={warnings?.[idx]?.reworks?.[jdx]?.reason?.code}
                  />
                </TableCell>
                <TableCell>
                  <Cell value={rework.reason?.description ?? '--'} error={undefined} warning={undefined} />
                </TableCell>
              </TableRow>
            ))
          )}
      </TableBody>
    </Table>
  )
}

const ReportDiscardsCard = ({ quantities, batches, errors, warnings, generateLink }: ReportQuantityCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('ProductId')}</TableHead>
          <TableHead>{t('Quantity')}</TableHead>
          <TableHead>{t('CauseId')}</TableHead>
          <TableHead>{t('Description')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {quantities
          .sort((a, b) =>
            batches[a.production.batchCode].product.code.localeCompare(batches[b.production.batchCode].product.code)
          )
          .map((row, idx) =>
            row.discards.map((discard, jdx) => (
              <TableRow
                key={discard._id}
                className={generateLink && 'cursor-pointer'}
                onClick={() => generateLink && navigate(generateLink(row, idx))}
              >
                <TableCell className="align-top">
                  <Cell
                    value={row.production && batches[row.production.batchCode].product.code}
                    error={errors?.[idx].production?.batchCode}
                    warning={warnings?.[idx].production?.batchCode}
                  />
                </TableCell>
                <TableCell className="align-top">
                  <Cell
                    value={discard.quantity}
                    error={errors?.[idx]?.reworks?.[jdx]?.quantity}
                    warning={warnings?.[idx]?.reworks?.[jdx]?.quantity}
                  />
                </TableCell>
                <TableCell className="align-top">
                  <Cell
                    value={discard.reason?.code}
                    error={errors?.[idx]?.reworks?.[jdx]?.reason?.code}
                    warning={warnings?.[idx]?.reworks?.[jdx]?.reason?.code}
                  />
                </TableCell>
                <TableCell>
                  <Cell value={discard.reason?.description ?? '--'} error={undefined} warning={undefined} />
                </TableCell>
              </TableRow>
            ))
          )}
      </TableBody>
    </Table>
  )
}

type ReportStopCardProps = {
  stops: Record<string, Stop[]>
  errors?: ZodFormattedError<Record<string, Stop[]>>
  warnings?: ZodFormattedError<Record<string, Stop[]>>
  generateLink?: (value: Stop, index: number) => string
}

export const ReportStopCard = ({ stops, errors, warnings, generateLink }: ReportStopCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('CauseId')}</TableHead>
          <TableHead>{t('Description')}</TableHead>
          <TableHead>{t('NumberOfEvents')}</TableHead>
          <TableHead>{t('Time')}</TableHead>
          <TableHead>{t('Duration')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(stops)
          .sort(([key], [otherKey]) => key.localeCompare(otherKey))
          .map(([stopReason, stopGroup], idx) => (
            <TableRow
              key={idx}
              className={cn(generateLink && 'cursor-pointer')}
              onClick={() => generateLink && navigate(generateLink(stopGroup[0], idx))}
            >
              <TableCell className="align-top">
                <Cell
                  value={stopGroup[0].reason?.code}
                  error={errors?.[stopReason]?.[idx]?.reason?.code}
                  warning={warnings?.[stopReason]?.[idx]?.reason?.code}
                />
              </TableCell>
              <TableCell className="align-top">{stopGroup[0].reason?.description ?? '--'}</TableCell>
              <TableCell className="align-top">{stopGroup.length}</TableCell>
              <TableCell>
                {stopGroup.map(
                  (stop, i) =>
                    stop.start !== null &&
                    stop.end != null && (
                      <Fragment key={i}>
                        <div className="flex">
                          <Cell
                            value={formatLocalTimeHumanReadable(stop.start)}
                            error={errors?.[stopReason]?.[i]?.start}
                            warning={warnings?.[stopReason]?.[i]?.start}
                            errorReplacesValue
                            className="inline-flex"
                          />
                          <span className="text-muted-foreground mx-1">-</span>
                          <Cell
                            value={formatLocalTimeHumanReadable(stop.end)}
                            error={errors?.[stopReason]?.[i]?.end}
                            warning={warnings?.[stopReason]?.[i]?.end}
                            errorReplacesValue
                            className="inline-flex"
                          />
                        </div>
                        <Cell value={null} error={errors?.[stopReason]?.[i]} warning={warnings?.[stopReason]?.[i]} />
                      </Fragment>
                    )
                )}
              </TableCell>
              <TableCell>{humanizeSeconds(stopGroup.reduce((total, stop) => total + stop.stopDuration, 0))}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

type ReportTestCardProps = {
  tests: Test[]
  batches: Record<string, Batch>
  errors?: ZodFormattedError<Test[]>
  warnings?: ZodFormattedError<Test[]>
  generateLink?: (value: Test, index: number) => string
}

const ReportTestCard = ({ tests, batches, errors, warnings, generateLink }: ReportTestCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('ProductId')}</TableHead>
          <TableHead>{t('Time')}</TableHead>
          <TableHead>{t('Quantity')}</TableHead>
          <TableHead>{t('Measures')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tests.map((test, idx) => (
          <TableRow
            key={idx}
            className={generateLink && 'cursor-pointer'}
            onClick={() => generateLink && navigate(generateLink(test, idx))}
          >
            <TableCell className="align-top">
              <Cell
                value={test.batchCode.length > 0 ? batches[test.batchCode].product.code : ''}
                error={errors?.[idx]?.batchCode}
                warning={warnings?.[idx]?.batchCode}
              />
            </TableCell>
            <TableCell className="align-top">{formatLocalTimeHumanReadable(test.performedAt)}</TableCell>
            <TableCell className="align-top">
              <Cell value={test.quantity} error={errors?.[idx]?.quantity} warning={warnings?.[idx]?.quantity} />
            </TableCell>
            <TableCell className="align-top space-y-2">
              {test.measures.map((m, jdx, arr) => (
                <Fragment key={jdx}>
                  <Cell
                    value={
                      <div className="inline-flex w-full">
                        <span>{MEASURE_LABELS[m.id]}</span>
                        <span className="ml-auto">{m.value}</span>
                      </div>
                    }
                    error={errors?.[idx]?.measures?.[jdx]?.value}
                    warning={warnings?.[idx]?.measures?.[jdx]?.value}
                  />
                  {jdx !== arr.length - 1 ? <Separator /> : null}
                </Fragment>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
