import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { CouchdbQueryBuilder, CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { BaseDocument, DocumentType } from '@/shared/dto/BaseDocument'
import { CouchdbDoc } from '@iotinga/ts-backpack-couchdb-client'
import { useEffect, useMemo, useState } from 'react'
import { useCouchdb } from './useCouchdb'

export function useAllDocs<T extends DocumentType, Doc extends CouchdbDoc = BaseDocument<T>>(
  type: T,
  baseQuery?: CouchdbQueryBuilder<Doc>
) {
  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = useMemo(() => new SimpleCouchCrudService<Doc>(db), [db])

  const query = useMemo(() => baseQuery ?? new CouchdbQueryBuilderImpl().where('type', '=', type), [baseQuery, type])

  const [data, setData] = useState<Doc[]>()
  useEffect(() => {
    crudService.query(query.build()).then(resp => setData(resp.items))
  }, [crudService, query])

  return useMemo(
    () => ({
      data,
      isLoading: data === undefined,
    }),
    [data]
  )
}
