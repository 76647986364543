import { MachineContentLayout } from '@/components/layouts/MachineContentLayout'
import { MachineButton } from '@/components/machineOptimized/Buttons'
import { Numpad } from '@/components/machineOptimized/Numpad'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Params, useNavigate, useParams } from 'react-router-dom'

type FormNumberScreenProps = {
  label: string
  getValue: (params: Readonly<Params>) => number | undefined
  onValueChange: (value: number, params: Readonly<Params>) => void
}

export function FormNumberScreen({ label, getValue, onValueChange }: FormNumberScreenProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const value = getValue(params)

  const form = useForm<{ value: string }>({
    values: {
      value: !value || isNaN(value) ? '' : String(value),
    },
  })

  const onSave = ({ value }: { value: string }) => {
    const numberValue = Number(value)

    if (!isNaN(numberValue)) {
      onValueChange(numberValue, params)
      navigate(-1)
    }
  }

  const onCancel = () => {
    navigate(-1)
  }

  const onNumpadClick = (value: number | 'DEL') => {
    const formValue = form.getValues('value')
    if (value === 'DEL') {
      form.setValue('value', formValue.slice(0, -1))
    } else {
      form.setValue('value', formValue + value.toString())
    }
  }

  return (
    <MachineContentLayout
      actionBarConfig={[
        <MachineButton variant="outline" label={t('Cancel')} onClick={onCancel} />,
        <MachineButton variant="success" label={t('Confirm')} onClick={form.handleSubmit(onSave)} />,
      ]}
    >
      <div className="flex flex-1 flex-wrap">
        <div className="flex flex-[1_1_100%] lg:flex-[1_1_50%] justify-center items-center">
          <Form {...form}>
            <FormField
              control={form.control}
              name="value"
              render={({ field }) => (
                <FormItem className="mt-2 w-2/3">
                  <FormLabel className="text-3xl text-bold uppercase">{label}</FormLabel>
                  <FormControl>
                    <Input className="text-xl h-12 bg-[#20242A]" readOnly {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </Form>
        </div>
        <div className="flex flex-[1_1_100%] lg:flex-[1_1_50%] justify-center items-center">
          <div className="w-2/3 h-2/3">
            <Numpad onClick={onNumpadClick} />
          </div>
        </div>
      </div>
    </MachineContentLayout>
  )
}
