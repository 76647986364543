import { Card } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { forwardRef } from 'react'

export const NewReportScreenCard = forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => {
  return <Card ref={ref} className={cn('my-8 mx-4 2xl:mx-8', className)} {...props} />
})
