import { PropsWithChildren, ReactNode } from 'react'

type MachineContentLayoutProps = PropsWithChildren<{
  actionBarConfig?: ReactNode[]
}>

export function MachineContentLayout({ children, actionBarConfig }: MachineContentLayoutProps) {
  return (
    <div className="flex flex-col h-full w-full justify-between">
      <div className="flex flex-1 flex-col h-full overflow-y-auto">{children}</div>
      {actionBarConfig && (
        <div className="flex justify-center items-center p-2 h-16 bg-[#20242A]">
          {actionBarConfig.map((e, i) => (
            <div key={i} className="flex mx-4">
              {e}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
