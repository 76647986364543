import { CouchdbManagerProvider } from '@/components/CouchdbManagerProvider'
import { MachineLayout } from '@/components/layouts/MachineLayout'
import { MachineButton, MachineButtonDialog } from '@/components/machineOptimized/Buttons'
import { TileButtonProps } from '@/components/machineOptimized/TileButton'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'
import { useMachineReport } from '@/hooks/useMachineReport'
import { ROUTE } from '@/routes'
import {
  IconBuildingFactory2,
  IconClock,
  IconCreditCard,
  IconCube,
  IconNote,
  IconNoteOff,
  IconPlus,
  IconRecycle,
  IconTrashX,
} from '@tabler/icons-react'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteObject, matchPath, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'
import { FormListScreen } from './FormListScreen'
import { FormNumberScreen } from './FormNumberScreen'
import { FormTextScreen } from './FormTextScreen'
import { FormTimeRangeScreen } from './FormTimeRangeScreen'
import { TilesScreen } from './TilesScreen'

const TILES_COLOR = {
  common: 'gradientBlue',
  production: 'gradientGreen',
  stop: 'gradientRed',
  rework: 'gradientYellow',
  discard: 'gradientDarkViolet',
  oee: 'gradientGray',
} as const

export function MachineReportRoot() {
  return (
    <CouchdbManagerProvider>
      <MachineReportRoutes />
    </CouchdbManagerProvider>
  )
}

export function MachineReportRoutes() {
  const { t } = useTranslation()
  const { workCenterId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    report,
    divisionCode,
    availableStopReasons,
    productOptionsMap,
    reworkOptionsMap,
    discardOptionsMap,
    stopOptionsMap,
    parentWorkCenterOptionsMap,
    initData,
    updateReport,
    saveReport,
    getProduction,
    addProduction,
    updateProduction,
    removeProduction,
    couldAddProduction,
    getRework,
    addRework,
    updateRework,
    removeRework,
    couldAddRework,
    getDiscard,
    addDiscard,
    updateDiscard,
    removeDiscard,
    couldAddDiscard,
    getStop,
    addStop,
    updateStop,
    removeStop,
    couldAddStop,
    getStopRange,
    addStopRange,
    updateStopRange,
  } = useMachineReport()

  useEffect(() => {
    if (!workCenterId || workCenterId.length <= 0) {
      return navigate(-1)
    }

    initData(workCenterId).catch(e => {
      console.error(e)
      navigate(-1)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenterId])

  const onSave = async () => {
    try {
      await saveReport()
      navigate(ROUTE.reports)
    } catch (e) {
      /* empty */
    }
  }

  const translateRoutes = (path: string) => {
    const subPaths = path.split('/').filter(x => x.length > 0)
    const routeTranslations: Record<string, string> = {
      '/workerCode': t('WorkerCode'),
      '/notes': t('Notes'),
      '/productions': t('Quantity'),
      '/productions/:productionId':
        report.productions[Number(subPaths[1])]?.productCode || `${t('Insert')} ${t('Product')}`,
      '/productions/:productionId/product': t('Product'),
      '/productions/:productionId/quantity': t('Quantity'),
      '/productions/:productionId/parent': t('ParentWorkCentre'),
      '/productions/:productionId/reworks': t('ReworkedPieces'),
      '/productions/:productionId/reworks/:reworkId':
        report.productions[Number(subPaths[1])]?.reworks[Number(subPaths[3])]?.reasonCode ||
        `${t('Insert')} ${t('CauseId')}`,
      '/productions/:productionId/reworks/:reworkId/reason': t('CauseId'),
      '/productions/:productionId/reworks/:reworkId/quantity': t('Quantity'),
      '/productions/:productionId/discards': t('DiscardedPieces'),
      '/productions/:productionId/discards/:discardId':
        report.productions[Number(subPaths[1])]?.discards[Number(subPaths[3])]?.reasonCode ||
        `${t('Insert')} ${t('CauseId')}`,
      '/productions/:productionId/discards/:discardId/reason': t('CauseId'),
      '/productions/:productionId/discards/:discardId/quantity': t('Quantity'),
      '/stops': t('Stops'),
      '/stops/:stopId': report.stops[Number(subPaths[1])]?.reasonCode || `${t('Insert')} ${t('CauseId')}`,
      '/stops/:stopId/reason': t('CauseId'),
      '/stops/:stopId/quantity': t('NumberOfEvents'),
      '/stops/:stopId/ranges': t('StopTimes'),
      '/stops/:stopId/ranges/:rangeId': `${t('Stop')} ${subPaths[3]}`,
    }

    for (const route in routeTranslations) {
      if (matchPath({ path: route, caseSensitive: false }, '/' + path)) {
        return routeTranslations[route]
      }
    }

    return subPaths.pop() ?? ''
  }

  const statusBarConfig = [
    {
      title: t('Date'),
      value: DateTime.fromISO(report.date).toFormat('dd/MM/yyyy'),
    },
    {
      title: t('Shift'),
      value: report.shiftCode,
    },
    {
      title: t('WorkCentre'),
      value: report.workCenterCode,
    },
    {
      title: t('WorkerId'),
      value: report.workerCode,
    },
  ]

  const reworksRoutes: RouteObject[] = [
    {
      index: true,
      element: (
        <TilesScreen
          actionBarConfig={({ productionId, reworkId }) => [
            <MachineButtonDialog
              variant="danger"
              label={t('Delete')}
              onClick={() => {
                removeRework(Number(productionId), Number(reworkId))
                navigate(-1)
              }}
            />,
          ]}
          config={({ productionId, reworkId }) => {
            const prodIndex = Number(productionId)
            const reworkIndex = Number(reworkId)
            const reworkOption = reworkOptionsMap[report.productions[prodIndex]?.reworks[reworkIndex]?.reasonCode]

            return [
              {
                title: displayDefault(reworkOption?.label, `${t('Insert')} ${t('CauseId')}`),
                label: t('ReworkId'),
                Icon: IconRecycle,
                color: TILES_COLOR.rework,
                onClick: () => navigate(`${location.pathname}/reason`),
              },
              {
                title: t('Quantity'),
                value: report.productions[prodIndex]?.reworks[reworkIndex]?.quantity,
                color: TILES_COLOR.rework,
                onClick: () => navigate(`${location.pathname}/quantity`),
              },
            ]
          }}
        />
      ),
    },
    {
      path: 'reason',
      element: (
        <FormListScreen
          label={t('ReworkId')}
          options={Object.values(reworkOptionsMap)}
          getValue={({ productionId, reworkId }) => getRework(Number(productionId), Number(reworkId))?.reasonCode}
          onValueChange={(reasonCode, { productionId, reworkId }) =>
            updateRework(Number(productionId), Number(reworkId), { reasonCode })
          }
        />
      ),
    },
    {
      path: 'quantity',
      element: (
        <FormNumberScreen
          label={t('Quantity')}
          getValue={({ productionId, reworkId }) => getRework(Number(productionId), Number(reworkId))?.quantity}
          onValueChange={(quantity, { productionId, reworkId }) =>
            updateRework(Number(productionId), Number(reworkId), { quantity })
          }
        />
      ),
    },
  ]

  const discardsRoutes: RouteObject[] = [
    {
      index: true,
      element: (
        <TilesScreen
          actionBarConfig={({ productionId, discardId }) => [
            <MachineButtonDialog
              variant="danger"
              label={t('Delete')}
              onClick={() => {
                removeDiscard(Number(productionId), Number(discardId))
                navigate(-1)
              }}
            />,
          ]}
          config={({ productionId, discardId }) => {
            const prodIndex = Number(productionId)
            const discardIndex = Number(discardId)
            const discardOption = discardOptionsMap[report.productions[prodIndex]?.discards[discardIndex]?.reasonCode]

            return [
              {
                title: displayDefault(discardOption?.label, `${t('Insert')} ${t('CauseId')}`),
                label: t('DiscardId'),
                Icon: IconTrashX,
                color: TILES_COLOR.discard,
                onClick: () => navigate(`${location.pathname}/reason`),
              },
              {
                title: t('Quantity'),
                value: report.productions[prodIndex]?.discards[discardIndex]?.quantity,
                color: TILES_COLOR.discard,
                onClick: () => navigate(`${location.pathname}/quantity`),
              },
            ]
          }}
        />
      ),
    },
    {
      path: 'reason',
      element: (
        <FormListScreen
          label={t('DiscardId')}
          options={Object.values(discardOptionsMap)}
          getValue={({ productionId, discardId }) => getDiscard(Number(productionId), Number(discardId))?.reasonCode}
          onValueChange={(reasonCode, { productionId, discardId }) =>
            updateDiscard(Number(productionId), Number(discardId), { reasonCode })
          }
        />
      ),
    },
    {
      path: 'quantity',
      element: (
        <FormNumberScreen
          label={t('Quantity')}
          getValue={({ productionId, discardId }) => getDiscard(Number(productionId), Number(discardId))?.quantity}
          onValueChange={(quantity, { productionId, discardId }) =>
            updateDiscard(Number(productionId), Number(discardId), { quantity })
          }
        />
      ),
    },
  ]

  const productionsRoutes: RouteObject[] = [
    {
      index: true,
      element: (
        <TilesScreen
          actionBarConfig={({ productionId }) => [
            <MachineButtonDialog
              variant="danger"
              label={t('Delete')}
              onClick={() => {
                removeProduction(Number(productionId))
                navigate(-1)
              }}
            />,
          ]}
          config={({ productionId }) => {
            const prodIndex = Number(productionId)
            const prodOption = productOptionsMap[report.productions[prodIndex]?.productCode]
            const parentOption = parentWorkCenterOptionsMap[report.productions[prodIndex]?.parentWorkCenterCode ?? '']

            return [
              {
                title: displayDefault(prodOption?.label, `${t('Insert')} ${t('Product')}`),
                label: t('Product'),
                Icon: IconCube,
                color: TILES_COLOR.production,
                onClick: () => navigate(`${location.pathname}/product`),
              },
              ...(divisionCode === ASSIEMATRICI_DIVISION_CODE
                ? [
                    {
                      title: t('Batteries'),
                      value: report.productions[prodIndex]?.quantity,
                      color: TILES_COLOR.production,
                      onClick: () => navigate(`${location.pathname}/quantity`),
                    },
                    {
                      title: t('ElementsCount'),
                      value: report.productions[prodIndex]?.elements,
                      color: TILES_COLOR.production,
                      onClick: () => navigate(`${location.pathname}/elements`),
                    },
                  ]
                : [
                    {
                      title: t('Quantity'),
                      value: report.productions[prodIndex]?.quantity,
                      color: TILES_COLOR.production,
                      onClick: () => navigate(`${location.pathname}/quantity`),
                    },
                  ]),
              {
                title: displayDefault(parentOption?.label, `${t('Insert')} ${t('ParentWorkCentre')}`),
                label: t('ParentWorkCentre'),
                Icon: IconBuildingFactory2,
                color: TILES_COLOR.production,
                onClick: () => navigate(`${location.pathname}/parent`),
              },
              {
                title: t('ReworkedPieces'),
                label: t('InsertedItems'),
                value: report.productions[prodIndex]?.reworks.length,
                color: TILES_COLOR.rework,
                onClick: () => navigate(`${location.pathname}/reworks`),
              },
              {
                title: t('DiscardedPieces'),
                label: t('InsertedItems'),
                color: TILES_COLOR.discard,
                value: report.productions[prodIndex]?.discards.length,
                onClick: () => navigate(`${location.pathname}/discards`),
              },
            ]
          }}
        />
      ),
    },
    {
      path: 'product',
      element: (
        <FormListScreen
          label={t('Product')}
          options={Object.values(productOptionsMap)}
          getValue={({ productionId }) => getProduction(Number(productionId))?.productCode}
          onValueChange={(productCode, { productionId }) => updateProduction(Number(productionId), { productCode })}
        />
      ),
    },
    {
      path: 'quantity',
      element: (
        <FormNumberScreen
          label={t('Quantity')}
          getValue={({ productionId }) => getProduction(Number(productionId))?.quantity}
          onValueChange={(quantity, { productionId }) => updateProduction(Number(productionId), { quantity })}
        />
      ),
    },
    {
      path: 'elements',
      element: (
        <FormNumberScreen
          label={t('ElementsCount')}
          getValue={({ productionId }) => getProduction(Number(productionId))?.elements}
          onValueChange={(elements, { productionId }) => updateProduction(Number(productionId), { elements })}
        />
      ),
    },
    {
      path: 'parent',
      element: (
        <FormListScreen
          label={t('ParentWorkCentre')}
          options={Object.values(parentWorkCenterOptionsMap)}
          getValue={({ productionId }) => getProduction(Number(productionId))?.parentWorkCenterCode}
          onValueChange={(parentWorkCenterCode, { productionId }) =>
            updateProduction(Number(productionId), { parentWorkCenterCode })
          }
        />
      ),
    },
    {
      path: 'reworks',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={({ productionId }) => {
                const prodIndex = Number(productionId)
                return [
                  ...report.productions[prodIndex].reworks.map((r, i) => ({
                    title: displayDefault(reworkOptionsMap[r.reasonCode]?.label, `${t('Insert')} ${t('CauseId')}`),
                    label: t('ReworkedPiece'),
                    value: r.quantity,
                    color: TILES_COLOR.rework,
                    onClick: () => navigate(`${location.pathname}/${i}`),
                  })),
                  {
                    title: `${t('Add')} ${t('ReworkedPiece')}`,
                    Icon: IconPlus,
                    color: TILES_COLOR.rework,
                    dashed: true,
                    disabled: !couldAddRework(prodIndex),
                    onClick: () => {
                      const reworkIndex = addRework(Number(productionId))
                      navigate(`${location.pathname}/${reworkIndex}`)
                    },
                  },
                ]
              }}
            />
          ),
        },
        {
          path: ':reworkId',
          children: reworksRoutes,
        },
      ],
    },
    {
      path: 'discards',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={({ productionId }) => {
                const prodIndex = Number(productionId)
                return [
                  ...report.productions[prodIndex].discards.map((d, i) => ({
                    title: displayDefault(discardOptionsMap[d.reasonCode]?.label, `${t('Insert')} ${t('CauseId')}`),
                    label: t('DiscardedPiece'),
                    value: d.quantity,
                    color: TILES_COLOR.discard,
                    onClick: () => navigate(`${location.pathname}/${i}`),
                  })),
                  {
                    title: `${t('Add')} ${t('DiscardedPiece')}`,
                    Icon: IconPlus,
                    color: TILES_COLOR.discard,
                    dashed: true,
                    disabled: !couldAddDiscard(prodIndex),
                    onClick: () => {
                      const discardIndex = addDiscard(Number(productionId))
                      navigate(`${location.pathname}/${discardIndex}`)
                    },
                  },
                ]
              }}
            />
          ),
        },
        {
          path: ':discardId',
          children: discardsRoutes,
        },
      ],
    },
  ]

  const stopsRoutes: RouteObject[] = [
    {
      index: true,
      element: (
        <TilesScreen
          actionBarConfig={({ stopId }) => [
            <MachineButtonDialog
              variant="danger"
              label={t('Delete')}
              onClick={() => {
                removeStop(Number(stopId))
                navigate('stops')
              }}
            />,
          ]}
          config={({ stopId }) => {
            const stopIndex = Number(stopId)
            const stopOption = stopOptionsMap[report.stops[stopIndex]?.reasonCode]
            const stopReasonDuration = availableStopReasons.find(
              reason => reason.code === report.stops[stopIndex]?.reasonCode
            )?.stopDuration
            const hasTimeRanges = stopReasonDuration === undefined || stopReasonDuration === null

            const tiles: TileButtonProps[] = [
              {
                title: displayDefault(stopOption?.label, `${t('Insert')} ${t('CauseId')}`),
                label: t('StopId'),
                Icon: IconClock,
                color: TILES_COLOR.stop,
                onClick: () => navigate(`${location.pathname}/reason`),
              },
            ]

            if (stopOption && hasTimeRanges) {
              tiles.push({
                title: t('StopTimes'),
                value: report.stops[stopIndex].ranges.length,
                color: TILES_COLOR.stop,
                disabled: !stopOption || !hasTimeRanges,
                onClick: () => navigate(`${location.pathname}/ranges`),
              })
            }

            if (stopOption && !hasTimeRanges) {
              tiles.push({
                title: t('NumberOfEvents'),
                value: report.stops[stopIndex]?.quantity,
                color: TILES_COLOR.stop,
                disabled: !stopOption || hasTimeRanges,
                onClick: () => navigate(`${location.pathname}/quantity`),
              })
            }

            return tiles
          }}
        />
      ),
    },
    {
      path: 'reason',
      element: (
        <FormListScreen
          label={t('StopId')}
          options={Object.values(stopOptionsMap)}
          getValue={({ stopId }) => getStop(Number(stopId))?.reasonCode}
          onValueChange={(reasonCode, { stopId }) =>
            updateStop(Number(stopId), { reasonCode, quantity: 0, ranges: [] })
          }
        />
      ),
    },
    {
      path: 'quantity',
      element: (
        <FormNumberScreen
          label={t('NumberOfEvents')}
          getValue={({ stopId }) => getStop(Number(stopId))?.quantity}
          onValueChange={(quantity, { stopId }) => updateStop(Number(stopId), { quantity })}
        />
      ),
    },
    {
      path: 'ranges',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={({ stopId }) => {
                const stopIndex = Number(stopId)
                const ranges = report.stops[stopIndex].ranges ?? []
                return [
                  ...ranges.map((r, i) => ({
                    title: r.start ? `${r.start} - ${r.end}` : `${t('Add')} ${t('StopTime')}`,
                    label: t('StopTime'),
                    Icon: IconClock,
                    color: TILES_COLOR.stop,
                    onClick: () => navigate(`${location.pathname}/${i}`),
                  })),
                  {
                    title: `${t('Add')} ${t('Stop')}`,
                    Icon: IconPlus,
                    color: TILES_COLOR.stop,
                    dashed: true,
                    onClick: () => {
                      const rangeIndex = addStopRange(stopIndex)
                      navigate(`${location.pathname}/${rangeIndex}`)
                    },
                  },
                ]
              }}
            />
          ),
        },
        {
          path: ':rangeId',
          element: (
            <FormTimeRangeScreen
              getValue={({ stopId, rangeId }) => getStopRange(Number(stopId), Number(rangeId))}
              onValueChange={(range, { stopId, rangeId }) => updateStopRange(Number(stopId), Number(rangeId), range)}
            />
          ),
        },
      ],
    },
  ]

  const headerRoutes: RouteObject[] = [
    {
      index: true,
      element: (
        <TilesScreen
          actionBarConfig={() => [
            <MachineButton variant="success" disabled={true} label={t('Save')} onClick={onSave} />,
          ]}
          config={() => [
            {
              title: displayDefault(report.workerCode, `${t('Insert')} ${t('WorkerId')}`),
              label: t('WorkerId'),
              Icon: IconCreditCard,
              color: TILES_COLOR.common,
              onClick: () => navigate('workerCode'),
            },
            {
              title: report.notes.length > 0 ? t('NotesAvailable') : t('NotesEmpty'),
              label: t('Notes'),
              Icon: report.notes.length > 0 ? IconNote : IconNoteOff,
              color: TILES_COLOR.common,
              onClick: () => navigate('notes'),
            },
            {
              title: t('BreakTime'),
              value: report.hasBreak ? t('Yes') : t('No'),
              color: TILES_COLOR.common,
              onClick: () => updateReport({ hasBreak: !report.hasBreak }),
            },
            {
              title: t('Production'),
              label: t('InsertedItems'),
              value: report.productions.length,
              color: TILES_COLOR.production,
              onClick: () => navigate('productions'),
            },
            {
              title: t('Stops'),
              label: t('InsertedItems'),
              value: report.stops.length,
              color: TILES_COLOR.stop,
              onClick: () => navigate('stops'),
            },
            {
              title: t('Oee'),
              label: t('OeeFull'),
              value: report.oee.availability * report.oee.performance * report.oee.quality * 100,
              gauge: true,
              color: TILES_COLOR.oee,
              onClick: () => navigate('oee'),
            },
          ]}
        />
      ),
    },
    {
      path: 'workerCode',
      element: (
        <FormTextScreen
          label={t('WorkerCode')}
          getValue={() => report.workerCode}
          onValueChange={v => updateReport({ workerCode: v })}
        />
      ),
    },
    {
      path: 'notes',
      element: (
        <FormTextScreen
          label={t('Notes')}
          textArea={true}
          getValue={() => report.notes}
          onValueChange={v => updateReport({ notes: v })}
        />
      ),
    },
    {
      path: 'productions',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={() => [
                ...report.productions.map((p, i) => ({
                  title: displayDefault(productOptionsMap[p.productCode]?.label, `${t('Insert')} ${t('Product')}`),
                  label: t('Production'),
                  value: p.quantity,
                  color: TILES_COLOR.production,
                  onClick: () => navigate(`${location.pathname}/${i}`),
                })),
                {
                  title: `${t('Add')} ${t('Production')}`,
                  Icon: IconPlus,
                  color: TILES_COLOR.production,
                  dashed: true,
                  disabled: !couldAddProduction(),
                  onClick: () => {
                    const prodIndex = addProduction()
                    navigate(`${location.pathname}/${prodIndex}`)
                  },
                },
              ]}
            />
          ),
        },
        {
          path: ':productionId',
          children: productionsRoutes,
        },
      ],
    },
    {
      path: 'stops',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={() => [
                ...report.stops.map((s, i) => ({
                  title: displayDefault(stopOptionsMap[s.reasonCode]?.label, `${t('Insert')} ${t('CauseId')}`),
                  label: t('Stop'),
                  value: s.quantity,
                  color: TILES_COLOR.stop,
                  onClick: () => navigate(`${location.pathname}/${i}`),
                })),
                {
                  title: `${t('Add')} ${t('Stop')}`,
                  Icon: IconPlus,
                  color: TILES_COLOR.stop,
                  dashed: true,
                  disabled: !couldAddStop(),
                  onClick: () => {
                    const stopIndex = addStop()
                    navigate(`${location.pathname}/${stopIndex}`)
                  },
                },
              ]}
            />
          ),
        },
        {
          path: ':stopId',
          children: stopsRoutes,
        },
      ],
    },
    {
      path: 'oee',
      children: [
        {
          index: true,
          element: (
            <TilesScreen
              config={() => [
                {
                  title: t('Availabilty'),
                  value: report.oee.availability * 100,
                  gauge: true,
                  color: TILES_COLOR.oee,
                },
                {
                  title: t('Performance'),
                  value: report.oee.performance * 100,
                  gauge: true,
                  color: TILES_COLOR.oee,
                },
                {
                  title: t('Quality'),
                  value: report.oee.quality * 100,
                  gauge: true,
                  color: TILES_COLOR.oee,
                },
              ]}
            />
          ),
        },
      ],
    },
  ]

  const machineRoutes: RouteObject[] = [
    {
      path: '/',
      element: <MachineLayout statusBarConfig={statusBarConfig} translateRoute={translateRoutes} />,
      children: headerRoutes,
    },
  ]

  return useRoutes(machineRoutes)
}

const displayDefault = (value: string | undefined, defaultValue: string = '') => {
  if (!value || value.length <= 0) {
    return defaultValue
  }

  return value
}
