import { MachineContentLayout } from '@/components/layouts/MachineContentLayout'
import { TileButton, TileButtonProps } from '@/components/machineOptimized/TileButton'
import { ReactNode } from 'react'
import { Params, useParams } from 'react-router-dom'

type TilesScreenProps = {
  config: (params: Readonly<Params>) => TileButtonProps[]
  actionBarConfig?: (params: Readonly<Params>) => ReactNode[]
}

export function TilesScreen({ config, actionBarConfig }: TilesScreenProps) {
  const params = useParams()

  return (
    <MachineContentLayout actionBarConfig={actionBarConfig ? actionBarConfig(params) : undefined}>
      <div className="flex-1 flex flex-wrap p-2">
        {config(params).map((c, i) => (
          <TileButton key={i} {...c} />
        ))}
      </div>
    </MachineContentLayout>
  )
}
