import { BaseDocument, DocumentRef, DocumentType } from './BaseDocument'
import { Batch } from './Batch'
import { ReportItem } from './Report'

/**
 * Type of measures
 */
export enum Measure {
  HEAD_LEAKS_A = 'HEAD_LEAKS_A',
  HEAD_LEAKS_B = 'HEAD_LEAKS_B',
  HEAD_LEAKS_C = 'HEAD_LEAKS_C',
  HEAD_LEAKS_D = 'HEAD_LEAKS_D',
  PIPE_LEAKS = 'PIPE_LEAKS',
  POROUS_PIPE_LEAKS = 'POROUS_PIPE_LEAKS',
  JOINT_LEAKS = 'JOINT_LEAKS',
}

/**
 * A measure with associated result value
 */
export type PerformedMeasure = {
  /** ID of the measure performed */
  id: Measure

  /** Measured value of the test */
  value: number
}

/**
 * A test performed on the product
 */
export type Test = BaseDocument<DocumentType.TEST> &
  ReportItem & {
    /** The product batch being tested */
    batchCode: DocumentRef<Batch>

    /** Number of products tested */
    quantity: number

    /** Timestamp RFC 3339 UTC at which the test was performed */
    performedAt: string

    /** Measures performed on the product */
    measures: PerformedMeasure[]
  }
