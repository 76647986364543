import { ASSIEMATRICI_DIVISION_CODE, DOC_ID_SEPARATOR } from '@/constants'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Given an array of values, calls the provided hashing function on each one and groups them by the returned
 * string, counting the values.
 * @param values A list of values to be counted
 * @param grouper A function which extracts a common key from a value
 * @returns A object with the extracted keys as keys and the count as value
 */
export function countBy<V>(values: V[], grouper: (value: V) => string): Record<string, number> {
  return values.reduce(
    (acc, v) => {
      const key = grouper(v)
      return {
        ...acc,
        [key]: (acc[key] ?? 0) + 1,
      }
    },
    {} as Record<string, number>
  )
}

/**
 * Takes an Array<T>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>()

  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })

  return map
}

/**
 * Prepares a user-provided string for use inside a regex matching engine
 * @param str The string to process
 * @returns A string with regex-controlling characters escaped
 */
export function escapeForRegex(str: string) {
  return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

/** Regex prefix for a case insensitive match in CouchDB's regex engine */
export const CASE_INSENSITIVE_MATCH = '(?i)'

/**
 * Generates a valid document ID given a document type and other document-specific elements
 * @param type The document type
 * @param keys Arbitrary strings to embed in the document's ID
 * @returns A valid document ID
 */
export function getDocumentId(type: DocumentType, ...keys: string[]) {
  return `${type}${DOC_ID_SEPARATOR}${keys.join(DOC_ID_SEPARATOR)}`
}

/**
 *
 * @param divisionCode
 * @param quantity
 * @param elements
 * @param machineCycleDuration
 * @returns
 */
export function calculateProductionDuration(
  divisionCode: string,
  quantity: number,
  elements: number,
  machineCycleDuration: number
) {
  if (divisionCode === ASSIEMATRICI_DIVISION_CODE) {
    if (quantity > elements) return 0
    return (elements - quantity) * machineCycleDuration
  }

  return quantity * machineCycleDuration
}

export function* setMinus<T>(a: T[], b: T[]) {
  const setA = new Set(a)
  const setB = new Set(b)

  for (const v of setB.values()) {
    if (!setA.delete(v)) {
      yield v
    }
  }

  for (const v of setA.values()) {
    yield v
  }
}
