import { BreadcrumbNavigationBar } from '@/components/machineOptimized/BreadcrumbNavigationBar'
import { StatusBar, StatusBarConfig } from '@/components/machineOptimized/StatusBar'
import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import { FullScreenLayout } from './FullScreenLayout'

type MachineLayoutProps = PropsWithChildren<{
  statusBarConfig?: StatusBarConfig
  translateRoute: (path: string) => string
}>

export function MachineLayout({ statusBarConfig, translateRoute }: MachineLayoutProps) {
  return (
    <FullScreenLayout>
      <div className="flex w-screen h-screen bg-bearmetal-background text-bearmetal-text select-none">
        <div className="flex flex-1 fixed top-0 left-0 right-0 h-16 bg-[#20242A]">
          <StatusBar config={statusBarConfig} />
        </div>
        <div className="flex flex-1 my-16 w-full h-[calc(100vh - 8rem)] bg-[#333333]">
          <Outlet />
        </div>
        <div className="flex flex-1 fixed bottom-0 left-0 right-0 h-16 overflow-hidden bg-[#000]">
          <BreadcrumbNavigationBar translateRoute={translateRoute} />
        </div>
      </div>
    </FullScreenLayout>
  )
}
