export const BearMetal = {
  gradientGreen: 'linear-gradient(350deg, rgba(10,34,34,1) 0%, rgba(20,57,57,1) 35%, rgba(21,84,84,1) 100%)',
  gradientRed: 'linear-gradient(350deg, rgba(27,4,12,1) 0%, rgba(54,7,24,1) 35%, rgba(113,15,51,1) 100%)',
  gradientYellow: 'linear-gradient(350deg, rgba(24,18,3,1) 0%, rgba(61,43,0,1) 35%, rgba(112,83,14,1) 100%)',
  gradientLightGreen: 'linear-gradient(350deg, rgba(18,31,4,1) 0%, rgba(33,61,0,1) 35%, rgba(71,119,15,1) 100%)',
  gradientAzure: 'linear-gradient(350deg, rgba(5,40,41,1) 0%, rgba(0,60,61,1) 35%, rgba(16,121,122,1) 100%)',
  gradientBlue: 'linear-gradient(350deg, rgba(5,20,38,1) 0%, rgba(0,28,61,1) 35%, rgba(19,68,126,1) 100%)',
  gradientDarkViolet: 'linear-gradient(350deg, rgba(23,6,45,1) 0%, rgba(27,0,61,1) 35%, rgba(64,16,124,1) 100%)',
  gradientViolet: 'linear-gradient(350deg, rgba(42,6,45,1) 0%, rgba(52,0,61,1) 35%, rgba(108,16,124,1) 100%)',
  gradientGray: 'linear-gradient(350deg, rgba(46,51,51,1) 0%, rgba(67,73,73,1) 35%, rgba(118,124,124,1) 100%)',
} as const
