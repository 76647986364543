import { MachineContentLayout } from '@/components/layouts/MachineContentLayout'
import { MachineButton } from '@/components/machineOptimized/Buttons'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Params, useNavigate, useParams } from 'react-router-dom'

type FormTextScreenProps = {
  label: string
  textArea?: boolean
  getValue: (params: Readonly<Params>) => string | undefined
  onValueChange: (value: string, params: Readonly<Params>) => void
}

export function FormTextScreen({ label, textArea, getValue, onValueChange }: FormTextScreenProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const form = useForm<{ value: string }>({
    values: {
      value: getValue(params)!,
    },
    reValidateMode: 'onSubmit',
  })

  const onSave = ({ value }: { value: string }) => {
    if (value.length > 0) {
      onValueChange(value, params)
      navigate(-1)
    }
  }

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <MachineContentLayout
      actionBarConfig={[
        <MachineButton variant="outline" label={t('Cancel')} onClick={onCancel} />,
        <MachineButton variant="success" label={t('Confirm')} onClick={form.handleSubmit(onSave)} />,
      ]}
    >
      <div className="flex flex-1 justify-center items-center">
        <Form {...form}>
          <FormField
            control={form.control}
            rules={{ required: true }}
            name="value"
            render={({ field }) => (
              <FormItem className="mt-2 w-1/2">
                <FormLabel className="text-3xl text-bold uppercase">{label}</FormLabel>
                <FormControl>
                  {!textArea ? (
                    <Input className="text-xl h-12 bg-[#20242A]" {...field} />
                  ) : (
                    <Textarea className="text-xl h-28 bg-[#20242A]" {...field} />
                  )}
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
      </div>
    </MachineContentLayout>
  )
}
