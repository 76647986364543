import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { ComponentPropsWithoutRef, ElementRef, ReactNode, forwardRef, memo } from 'react'

export type ButtonWithTooltipProps = {
  tooltip: ReactNode
  tooltipContentProps?: ComponentPropsWithoutRef<typeof TooltipContent>
}

export const ButtonWithTooltip = memo(
  forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button> & ButtonWithTooltipProps>(
    ({ tooltip, tooltipContentProps = {}, className, children, ...props }, ref) => {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button ref={ref} className={className} {...props}>
              {children}
            </Button>
          </TooltipTrigger>
          <TooltipContent {...tooltipContentProps} hideWhenDetached>
            <span>{tooltip}</span>
          </TooltipContent>
        </Tooltip>
      )
    }
  )
)
