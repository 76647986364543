import { DeleteDialog } from '@/components/DeleteDialog'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { buttonVariants } from '@/components/ui/button'
import { ASSIEMATRICI_DIVISION_CODE } from '@/constants'
import { calculateTotalStopDuration } from '@/lib/calculation'
import { humanizeSeconds } from '@/lib/datetime'
import { joinIgnoreEmpty } from '@/lib/localize'
import { cn } from '@/lib/utils'
import { ROUTE } from '@/routes'
import { ReportEditorState } from '@/screens/reports/EditReportLayout'
import { QuantityFull } from '@/shared/crud/FullReportCrudService'
import { Stop } from '@/shared/dto/Stop'
import { Test } from '@/shared/dto/Test'
import { cloneDeep } from 'lodash'
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { generatePath, matchPath, useLocation, useMatches, useNavigate, useParams } from 'react-router-dom'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

type ReportAccordionProps = {
  editorState: ReportEditorState
  reportCode: string | undefined
  setEditorState: Dispatch<SetStateAction<ReportEditorState>>
}

type AccordionBadge = {
  tooltip: ReactNode
  value: string | number
}

export function ReportAccordion({ editorState, setEditorState, reportCode }: ReportAccordionProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const isEdit = reportCode !== undefined && reportCode.length > 0

  const ACCORDION_ROUTES = useMemo(
    () =>
      isEdit
        ? [ROUTE.reportEditQuantity, ROUTE.reportEditStops, ROUTE.reportEditTests]
        : [ROUTE.reportCreateQuantity, ROUTE.reportCreateStops, ROUTE.reportCreateTests],
    [isEdit]
  )

  const activeAccordion = useMemo(
    () => ACCORDION_ROUTES.map(route => matchPath(route, location.pathname)).find(match => !!match),
    [ACCORDION_ROUTES, location.pathname]
  )
  const [openAccordion, setOpenAccordion] = useState<string>(activeAccordion?.pattern.path || ROUTE.reportCreate)

  const { stops, quantities, tests, batches } = editorState

  // These (precompiled) routes can be navigated through with page up/down
  const paths = [
    isEdit ? ROUTE.reportEdit : ROUTE.reportCreate,
    ...(quantities || []).map(item =>
      generatePath(ACCORDION_ROUTES[0], { index: item.production.batchCode, id: reportCode || null })
    ),
    ...Object.keys(stops).map(reasonCode =>
      generatePath(ACCORDION_ROUTES[1], { index: reasonCode, id: reportCode || null })
    ),
    ...(tests || []).map(item => generatePath(ACCORDION_ROUTES[2], { index: item.batchCode, id: reportCode || null })),
  ]

  // The currently active route index
  const activePathIndex = paths.map(route => matchPath(route, location.pathname)).findIndex(match => !!match)

  const goToPrevious = () => {
    if (activePathIndex !== -1 && paths[activePathIndex - 1]) {
      navigate(paths[activePathIndex - 1])
    }
  }

  const goToNext = () => activePathIndex !== -1 && paths[activePathIndex + 1] && navigate(paths[activePathIndex + 1])

  useHotkeys('pageup', goToPrevious, { enableOnFormTags: true })
  useHotkeys('pagedown', goToNext, { enableOnFormTags: true })

  useEffect(() => {
    setOpenAccordion(activeAccordion?.pattern.path || ROUTE.reportCreate)
  }, [ACCORDION_ROUTES, activeAccordion])

  const onClickQuantity = (item: QuantityFull) => {
    navigate(generatePath(ACCORDION_ROUTES[0], { index: item.production.batchCode, id: reportCode || null }))
  }

  const onClickStop = (item: Stop) => {
    navigate(generatePath(ACCORDION_ROUTES[1], { index: item.reason.code, id: reportCode || null }))
  }

  const onClickTest = (item: Test) => {
    navigate(generatePath(ACCORDION_ROUTES[2], { index: item.batchCode, id: reportCode || null }))
  }

  const removeQuantity = (item: QuantityFull) => {
    goToPrevious()

    const batchCodeToRemove = item.production.batchCode

    // remove batch
    const newBatches = cloneDeep(editorState.batches)
    delete newBatches[batchCodeToRemove]

    // remove test associated with batch
    const newTests = editorState.tests.filter(test => test.batchCode !== batchCodeToRemove)

    // remove quantity
    const newQuantities = editorState.quantities.filter(quantity => quantity.production.batchCode !== batchCodeToRemove)

    setEditorState(old => ({
      ...old,
      quantities: newQuantities,
      tests: newTests,
      batches: newBatches,
    }))
  }

  const removeStop = (item: Stop) => {
    goToPrevious()

    const newStops = cloneDeep(editorState.stops)
    delete newStops[item.reason.code]

    const totalStopDuration = calculateTotalStopDuration(newStops)

    setEditorState(old => ({
      ...old,
      header: {
        ...old.header,
        totalStopDuration: totalStopDuration,
      },
      stops: newStops,
    }))
  }

  const removeTest = (item: Test) => {
    goToPrevious()

    const newTests = editorState.tests.filter(test => test.batchCode !== item.batchCode)
    setEditorState(old => ({
      ...old,
      tests: newTests,
    }))
  }

  return (
    <DeleteDialog>
      <Accordion type="single" value={openAccordion} onValueChange={setOpenAccordion} collapsible>
        <ReportAccordionItem
          reportField={quantities}
          route={ACCORDION_ROUTES[0]}
          onElementClick={onClickQuantity}
          extractId={quantity => quantity.production.batchCode}
          extractLabel={quantity =>
            joinIgnoreEmpty(
              ' - ',
              batches[quantity.production.batchCode].product.code,
              batches[quantity.production.batchCode].product.description
            )
          }
          accordionBadges={[
            ...(editorState.header.workCenter.divisionCode === ASSIEMATRICI_DIVISION_CODE
              ? [
                  {
                    tooltip: t('Batteries'),
                    value: editorState.quantities.reduce(
                      (acc, q) => acc + (Number.isNaN(q.production.quantity) ? 0 : q.production.quantity),
                      0
                    ),
                  },
                  {
                    tooltip: t('ElementsCount'),
                    value: editorState.quantities.reduce(
                      (acc, q) => acc + (Number.isNaN(q.production.elements) ? 0 : q.production.elements),
                      0
                    ),
                  },
                ]
              : [
                  {
                    tooltip: t('Products'),
                    value: editorState.quantities.reduce(
                      (acc, q) => acc + (Number.isNaN(q.production.quantity) ? 0 : q.production.quantity),
                      0
                    ),
                  },
                ]),

            {
              tooltip: t('ReworkedPieces'),
              value: editorState.quantities.reduce(
                (acc, q) => acc + q.reworks.reduce((acc, r) => acc + (Number.isNaN(r.quantity) ? 0 : r.quantity), 0),
                0
              ),
            },
            {
              tooltip: t('DiscardedPieces'),
              value: editorState.quantities.reduce(
                (acc, q) => acc + q.discards.reduce((acc, d) => acc + (Number.isNaN(d.quantity) ? 0 : d.quantity), 0),
                0
              ),
            },
            {
              tooltip: t('ProductionTime'),
              value: humanizeSeconds(editorState.header.totalProductionDuration, true),
            },
          ]}
          generateItemBadges={quantity =>
            editorState.header.workCenter.divisionCode === ASSIEMATRICI_DIVISION_CODE
              ? [
                  {
                    tooltip: t('Batteries'),
                    value: quantity.production?.quantity || 0,
                  },
                  {
                    tooltip: t('ElementsCount'),
                    value: quantity.production?.elements || 0,
                  },
                ]
              : [
                  {
                    tooltip: t('Products'),
                    value: quantity.production?.quantity || 0,
                  },
                ]
          }
          label={t('Quantity')}
          onDelete={removeQuantity}
        />
        <ReportAccordionItem
          reportField={Object.values(editorState.stops).map(stops => stops[0])}
          route={ACCORDION_ROUTES[1]}
          onElementClick={onClickStop}
          extractId={stop => stop.reason.code}
          extractLabel={stop => stop.reason.code}
          extractDescription={stop => stop.reason.description}
          accordionBadges={[
            {
              tooltip: t('Stops'),
              value: Object.values(editorState.stops).reduce((acc, s) => acc + s.length, 0),
            },
            {
              tooltip: t('StopTime'),
              value: humanizeSeconds(editorState.header.totalStopDuration, true),
            },
          ]}
          generateItemBadges={stop => [
            {
              tooltip: t('Stops'),
              value: editorState.stops[stop.reason.code].length,
            },
          ]}
          label={t('Stops')}
          onDelete={removeStop}
        />
        <ReportAccordionItem
          reportField={tests}
          route={ACCORDION_ROUTES[2]}
          onElementClick={onClickTest}
          extractId={test => test.batchCode}
          extractLabel={test =>
            test.batchCode.length > 0
              ? joinIgnoreEmpty(
                  ' - ',
                  batches[test.batchCode].product.code,
                  batches[test.batchCode].product.description
                )
              : undefined
          }
          accordionBadges={[
            {
              tooltip: t('Tests'),
              value: editorState.tests.reduce((acc, t) => acc + (Number.isNaN(t.quantity) ? 0 : t.quantity), 0),
            },
          ]}
          generateItemBadges={test => [
            {
              tooltip: t('Tests'),
              value: test.quantity || 0,
            },
          ]}
          label={t('TestsDetails')}
          onDelete={removeTest}
        />
      </Accordion>
    </DeleteDialog>
  )
}

function ReportAccordionItem<F>({
  reportField,
  route,
  label,
  onDelete,
  onElementClick,
  extractDescription,
  extractId,
  extractLabel,
  generateItemBadges,
  accordionBadges,
}: {
  reportField: F[] | undefined
  route: string
  label: string
  onElementClick: (entry: F) => void
  onDelete: (entry: F) => void
  extractId: (entry: F) => string | undefined
  extractLabel: (entry: F) => string | undefined
  extractDescription?: (entry: F) => string | undefined
  accordionBadges?: AccordionBadge[]
  generateItemBadges?: (entry: F) => AccordionBadge[]
}) {
  const { t } = useTranslation()
  const matches = useMatches()
  const { id: reportCode } = useParams()

  const [selectedItem, setSelectedItem] = useState<F | undefined>(undefined)

  /** Returns `true` if the current item is selected by checking the navigation path */
  const currentPathMatches = (index: string) => {
    const prepared = generatePath(route, { index, id: reportCode || null })
    return matches.find(match => match.pathname === prepared) !== undefined
  }

  function ReportAccordionItemContent({
    item,
    extractId,
    extractLabel,
    extractDescription,
    onDelete,
    onElementClick,
  }: {
    item: F
    onDelete: (entry: F) => void
    onElementClick: (entry: F) => void
    extractId: (entry: F) => string | undefined
    extractLabel: (entry: F) => string | undefined
    extractDescription?: (entry: F) => string | undefined
  }) {
    const id = extractId(item) ?? ''
    const label = extractLabel(item) ?? ''
    const description = extractDescription && extractDescription(item)
    const isSelected = currentPathMatches(id)
    const badges = generateItemBadges ? generateItemBadges(item) : []

    return (
      <div className="flex gap-x-2">
        <a
          onClick={() => onElementClick(item)}
          className={cn(
            buttonVariants({ variant: isSelected ? 'default' : 'ghost' }),
            isSelected && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white',
            'flex-1 justify-start rounded-none overflow-hidden cursor-pointer'
          )}
        >
          <div className="flex w-full items-center gap-4">
            {id && label ? (
              <div className="flex items-center flex-1 fade-clamp">
                <span className="min-w-7">{label}</span>
                {description && (
                  <>
                    <span className="mx-1 mr-2">-</span>
                    <span>{description}</span>
                  </>
                )}
              </div>
            ) : (
              <span className={cn('italic flex-1 text-start', !isSelected && 'text-muted-foreground')}>
                {t('Empty')}
              </span>
            )}
            <div className="space-x-2">
              {badges.map((b, idx) => (
                <QuantityBadge key={idx} isSelected={isSelected} content={b} />
              ))}
            </div>
          </div>
        </a>

        <DeleteDialog.Trigger onClick={() => onDelete(item)} />
      </div>
    )
  }

  return (
    <AccordionItem value={route} className="px-4" disabled={reportField === undefined || reportField.length === 0}>
      <AccordionTrigger className="[&[data-disabled]>svg]:invisible">
        <div className="flex w-full items-center gap-4">
          {label}
          <div className="ml-auto space-x-2 mr-4">
            {(accordionBadges || []).map((data, idx) => (
              <Tooltip key={idx}>
                <TooltipTrigger asChild type={undefined}>
                  <a key={idx} className="cursor-pointer" type={undefined}>
                    <Badge variant="outline" className="text-muted-foreground">
                      {data.value}
                    </Badge>
                  </a>
                </TooltipTrigger>
                <TooltipContent>{data.tooltip}</TooltipContent>
              </Tooltip>
            ))}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex flex-col">
          {reportField &&
            reportField.map((f, idx) => (
              <ReportAccordionItemContent
                key={idx}
                item={f}
                extractId={extractId}
                extractLabel={extractLabel}
                extractDescription={extractDescription}
                onElementClick={onElementClick}
                onDelete={item => setSelectedItem(item)}
              />
            ))}
        </div>
        <DeleteDialog.Content onConfirm={() => selectedItem && onDelete(selectedItem)} />
      </AccordionContent>
    </AccordionItem>
  )
}

function QuantityBadge({ isSelected, content }: { isSelected: boolean; content?: AccordionBadge }) {
  return content !== undefined ? (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          variant="outline"
          className={cn('ml-auto', isSelected ? 'text-primary-foreground' : 'text-muted-foreground')}
        >
          {content.value}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>{content.tooltip}</TooltipContent>
    </Tooltip>
  ) : null
}
