import { Measure } from '@/shared/dto/Test'

export const MEASURES = [
  Measure.HEAD_LEAKS_A,
  Measure.HEAD_LEAKS_B,
  Measure.HEAD_LEAKS_C,
  Measure.HEAD_LEAKS_D,
  Measure.JOINT_LEAKS,
  Measure.PIPE_LEAKS,
  Measure.POROUS_PIPE_LEAKS,
] as const

export function uniqBy<T>(values: T[], key: (value: T) => string): T[] {
  return [...new Map(values.map(v => [key(v), v])).values()]
}
