import { MachineContentLayout } from '@/components/layouts/MachineContentLayout'
import { MachineButton } from '@/components/machineOptimized/Buttons'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { cn } from '@/lib/utils'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Params, useNavigate, useParams } from 'react-router-dom'

export type ListScreenOption = { value: string; label: string }

type FormListScreenProps = {
  options: ListScreenOption[]
  label: string
  getValue: (params: Readonly<Params>) => string | undefined
  onValueChange: (value: string, params: Readonly<Params>) => void
}

export function FormListScreen({ options, label, getValue, onValueChange }: FormListScreenProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const form = useForm<{ value: string }>({
    values: {
      value: getValue(params)!,
    },
  })

  const onSave = () => {
    const formValue = form.getValues('value')
    if (formValue.length > 0) {
      onValueChange(formValue, params)
      navigate(-1)
    }
  }

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <MachineContentLayout
      actionBarConfig={[
        <MachineButton variant="outline" label={t('Cancel')} onClick={onCancel} />,
        <MachineButton variant="success" label={t('Confirm')} onClick={onSave} />,
      ]}
    >
      <Form {...form}>
        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem className="mt-2 px-2 flex-1 overflow-hidden">
              <FormLabel className="text-3xl text-bold uppercase">{label}</FormLabel>
              <FormControl>
                <Command className="bg-transparent text-[#FFFAFA]">
                  <CommandInput className="text-xl h-12" placeholder={t('Search')} />
                  <CommandList className="h-full max-h-full overflow-y-auto">
                    <CommandEmpty className="py-6 text-center text-sm text-[#FFFAFA]">{t('NoResult')}</CommandEmpty>
                    <CommandGroup>
                      {options.map(option => (
                        <CommandItem
                          key={option.value}
                          value={option.label}
                          onSelect={() => field.onChange(option.value)}
                          className={cn(
                            'text-xl h-12',
                            field.value === option.value
                              ? 'text-bearmetal-violet aria-selected:text-bearmetal-violet bg-bearmetal-green aria-selected:bg-bearmetal-green'
                              : 'text-[#FFFAFA] aria-selected:text-[#FFFAFA] aria-selected:bg-transparent'
                          )}
                        >
                          {option.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </FormControl>
            </FormItem>
          )}
        />
      </Form>
    </MachineContentLayout>
  )
}
