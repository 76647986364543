import { COUCHDB_ROOT_PATH } from '@/config'
import { CouchdbManagerContext } from '@/contexts/CouchdbManagerContext'
import { useAuthentication } from '@/hooks/useAuthentication'
import { ErrorScreen } from '@/screens/ErrorScreen'
import { PRODUCTION_DB_DESIGN_DOC, PRODUCTION_DB_NAME, PRODUCTION_INDEXES } from '@/shared/db/production'
import { User } from '@/shared/dto/User'
import { UserRole, hasRole } from '@/shared/utils/auth'
import { Couchdb, CouchdbAuthMethod, CouchdbManager } from '@iotinga/ts-backpack-couchdb-client'
import axios, { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

async function initDb(db: Couchdb, currentUser: User) {
  try {
    if (hasRole(currentUser, [UserRole.REPORTS_REGISTRY, UserRole.ADMIN])) {
      await db.createOrUpdateDesign(PRODUCTION_DB_DESIGN_DOC)
      console.debug(`Updated production design document`)

      for (const index of Object.values(PRODUCTION_INDEXES)) {
        console.debug(`Updating index ${index.name}`)
        await db.createIndex(index)
      }
      console.debug(`Updated production indexes`)
    }
  } catch (e) {
    console.warn(`Current user doesn't have the privileges to update design docs`)
  }
}

type ErrorWithCode = {
  error: AxiosError | unknown
  status?: number
}

export function CouchdbManagerProvider({ children }: { children: React.ReactNode }) {
  const { jwtToken, currentUser, signOut } = useAuthentication()
  const [errorWithCode, setErrorWithCode] = useState<ErrorWithCode>()
  const { t } = useTranslation()

  const handleCouchDbError = useCallback(
    (error: unknown, status?: number) => {
      console.error(`Couchdb client error with status ${status}`)
      if ((axios.isAxiosError(error) && error.response?.status === 401) || status === 401) {
        signOut()
      }

      setErrorWithCode({
        status,
        error,
      })

      // if ((axios.isAxiosError(error) && error.request.status === 500) || status === 500) {
      //   setIsCouchdbUnreachable(true)
      // }
    },
    [signOut]
  )

  const manager = useMemo(() => {
    if (jwtToken !== undefined) {
      return new CouchdbManager(
        {
          authMethod: CouchdbAuthMethod.JWT,
          jwtToken,
          rootPath: COUCHDB_ROOT_PATH,
        },
        handleCouchDbError
      )
    } else {
      return new CouchdbManager(
        {
          authMethod: CouchdbAuthMethod.NONE,
          rootPath: COUCHDB_ROOT_PATH,
        },
        handleCouchDbError
      )
    }
  }, [handleCouchDbError, jwtToken])

  // caches the instances such that with same dbName this hook returns the same instance
  // This is done to avoid a render loop if the db is used as a dependency of another hook
  const dbInstances = useMemo(() => new Map<string, Couchdb>(), [manager])

  const getDbInstance = useCallback(
    (dbName: string) => {
      if (!dbInstances.has(dbName)) {
        const db = manager.db(dbName)
        dbInstances.set(dbName, db)
      }

      return dbInstances.get(dbName) as Couchdb
    },
    [dbInstances, manager]
  )

  useEffect(() => {
    initDb(getDbInstance(PRODUCTION_DB_NAME), currentUser)
  }, [currentUser, getDbInstance, manager])

  if (
    (axios.isAxiosError(errorWithCode?.error) && errorWithCode?.error.request.status === 500) ||
    errorWithCode?.status === 500
  ) {
    return <ErrorScreen code={500} message={t('ErrorUnreachable')} />
  }

  if (
    (axios.isAxiosError(errorWithCode?.error) && errorWithCode?.error.request.status === 403) ||
    errorWithCode?.status === 403
  ) {
    // This will generally happen if the user does not have the `reports:read` role
    return <ErrorScreen code={403} message={t('Unauthorized')} />
  }

  if (errorWithCode !== undefined) {
    return <ErrorScreen code={errorWithCode.status} />
  }

  return (
    <CouchdbManagerContext.Provider value={{ manager, getDb: getDbInstance }}>
      {children}
    </CouchdbManagerContext.Provider>
  )
}
