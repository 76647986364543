import { CouchdbDoc, CouchdbFindQuery } from '@iotinga/ts-backpack-couchdb-client'
import { object } from 'zod'

const NOLIMIT = 100000

export type BasicOperatorType = '=' | '>' | '>=' | '<' | '<=' | 'regex' | 'in' | 'elemMatch'
type OperatorType = '$eq' | '$gt' | '$gte' | '$lt' | '$lte' | '$regex' | '$in' | '$elemMatch'

export interface CouchdbQueryBuilder<D extends Record<string, unknown>> {
  where(attribute: string, operator: BasicOperatorType, value: unknown): CouchdbQueryBuilder<D>
  orderBy(attribute: string): CouchdbQueryBuilder<D>
  offset(offset: number): CouchdbQueryBuilder<D>
  limit(limit: number): CouchdbQueryBuilder<D>
  paginationToken(token: string | undefined): CouchdbQueryBuilder<D>
  build(): CouchdbFindQuery
}

const OperatorsMap: Record<BasicOperatorType, OperatorType> = {
  '=': '$eq',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
  elemMatch: '$elemMatch',
  regex: '$regex',
  in: '$in',
}

export class CouchdbQueryBuilderImpl<D extends CouchdbDoc> implements CouchdbQueryBuilder<D> {
  constructor(
    private query: CouchdbFindQuery = {
      selector: {},
      sort: [],
      limit: NOLIMIT,
      skip: 0,
    }
  ) {}

  paginationToken(token: string | undefined) {
    return new CouchdbQueryBuilderImpl<D>({
      ...this.query,
      bookmark: token,
    })
  }

  where(attribute: string, operator: BasicOperatorType, value: unknown) {
    return new CouchdbQueryBuilderImpl<D>({
      ...this.query,
      selector: {
        ...this.query.selector,
        [attribute]: {
          [OperatorsMap[operator]]: value,
        },
      },
    })
  }

  orderBy(attribute: string, direction: 'asc' | 'desc' = 'asc') {
    return new CouchdbQueryBuilderImpl<D>({
      ...this.query,
      sort: [
        ...(this.query.sort?.filter(x => x instanceof object && attribute in x) ?? []),
        { [attribute]: direction },
      ],
    })
  }

  offset(offset: number) {
    return new CouchdbQueryBuilderImpl<D>({
      ...this.query,
      skip: offset,
    })
  }

  limit(limit: number) {
    return new CouchdbQueryBuilderImpl<D>({
      ...this.query,
      limit,
    })
  }

  build(): CouchdbFindQuery {
    return this.query
  }
}
