import { ENCODED_SHIFT_SCHEMA } from '@/lib/schema'
import { EncodedShift, Shift } from '../dto/Shift'

const SHIFT_PART_SEPARATOR = '_'

/**
 * Type guard to verify a string is a valid encoded shift
 * @param value A string to test
 * @returns `true` if the string is a valid encoded shift, `false` otherwise
 */
export function isEncodedShift(value: string): value is EncodedShift {
  return ENCODED_SHIFT_SCHEMA.safeParse(value).success
}

/**
 * Decodes an encoded shift into a shift object. Returns `null` if the data is not an encoded shift
 * @param data A string to parse
 * @returns A `Shift` object with the parsed data, `null` if the data is not a valid encoded
 */
export function decodeShift(data: string): Shift | null {
  if (!isEncodedShift(data)) {
    return null
  }

  const [, workCenter, code, start, end] = data.split(SHIFT_PART_SEPARATOR)
  return { workCenter, code, start, end }
}

export function encodeShift(data: Shift): EncodedShift {
  const { workCenter, code, start, end } = data
  return `SHIFT_${workCenter}_${code}_${start}_${end}`
}
