import { DocumentType } from '@/shared/dto/BaseDocument'
import { Division } from '@/shared/dto/Division'
import { ProcessingDetails } from '@/shared/dto/ProcessingDetails'
import { Discards, Production, Reworks } from '@/shared/dto/Quantity'
import { Report, ReportDataSource, ReportState } from '@/shared/dto/Report'
import { ScheduleItem } from '@/shared/dto/ScheduleItem'
import { EncodedShift, Shift } from '@/shared/dto/Shift'
import { Stop } from '@/shared/dto/Stop'
import { Measure, Test } from '@/shared/dto/Test'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { decodeShift } from '@/shared/utils/shift'
import { t } from 'i18next'
import { DateTime, Interval } from 'luxon'
import z from 'zod'
import { getIntervalInShift } from './datetime'

export const SHIFT_PART_SEAPARATOR = '_'

export const schemaForType =
  <T>() =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <S extends z.ZodType<T, any, any>>(arg: S) => {
    return arg
  }

export const REQUIRED_NUMBER = z.number({
  required_error: t('MissingRequiredField'),
  invalid_type_error: t('MissingRequiredField'),
})

export const MIN_1_NUMBER = REQUIRED_NUMBER.min(1, t('MinimumValue', { min: 1 }))

export const NON_EMPTY_STRING = z
  .string({
    required_error: t('MissingRequiredField'),
    invalid_type_error: t('MissingRequiredField'),
  })
  .min(1, t('MissingRequiredField'))

const BASE_DOCUMENT_SCHEMA = z.object({
  _id: NON_EMPTY_STRING,
  type: z.nativeEnum(DocumentType),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  createdBy: NON_EMPTY_STRING,
})

export const ENCODED_SHIFT_SCHEMA = z.custom<EncodedShift>(value => {
  if (typeof value !== 'string') {
    return false
  }

  const [, , , start, end] = value.split(SHIFT_PART_SEAPARATOR)

  if (!DateTime.fromISO(start).isValid) {
    return false
  }

  if (!DateTime.fromISO(end).isValid) {
    return false
  }

  return true
})

export const DECODED_SHIFT_SCHEMA = schemaForType<Shift>()(
  z.object({
    workCenter: NON_EMPTY_STRING,
    code: NON_EMPTY_STRING,
    start: z.string().datetime(),
    end: z.string().datetime(),
  })
)

const REPORT_ITEM_PART_SCHEMA = BASE_DOCUMENT_SCHEMA.extend({
  source: z.nativeEnum(ReportDataSource),
  code: NON_EMPTY_STRING,
  reportCode: NON_EMPTY_STRING,
  shift: ENCODED_SHIFT_SCHEMA,
  state: z.nativeEnum(ReportState),
})

export const WORK_CENTER_SCHEMA = schemaForType<WorkCenter>()(
  BASE_DOCUMENT_SCHEMA.extend({
    code: NON_EMPTY_STRING,
    divisionCode: NON_EMPTY_STRING,
    description: z.string().optional().default(''),
    type: z.literal(DocumentType.WORK_CENTER),
    parentsCodes: z.array(z.string()),
  })
)

export const WORK_CENTER_TYPE_SCHEMA = schemaForType<Division>()(
  BASE_DOCUMENT_SCHEMA.extend({
    type: z.literal(DocumentType.DIVISION),
    code: NON_EMPTY_STRING,
    description: z.string(),
  })
)

export const PROCESSING_DETAILS_SCHEMA = schemaForType<ProcessingDetails>()(
  BASE_DOCUMENT_SCHEMA.extend({
    type: z.literal(DocumentType.PROCESSING_DETAILS),
    productCode: NON_EMPTY_STRING,
    workCenterCode: NON_EMPTY_STRING,
    machineCycleDuration: z.number().int().gte(0),
  })
)

export const SCHEDULE_ITEM_SCHEMA = schemaForType<ScheduleItem>()(
  BASE_DOCUMENT_SCHEMA.extend({
    type: z.literal(DocumentType.SCHEDULE_ITEM),
    code: NON_EMPTY_STRING,
    shiftCode: NON_EMPTY_STRING,
    workCenterCode: NON_EMPTY_STRING,
    startTime: z.string(),
    endTime: z.string(),
    validFrom: z.string().datetime(),
    validTo: z.string().datetime(),
    breakDuration: z.number().gte(0),
    shiftChangeDuration: z.number().gte(0),
  })
)

export const QUANTITY_SCHEMA = REPORT_ITEM_PART_SCHEMA.extend({
  batchCode: NON_EMPTY_STRING,
  quantity: MIN_1_NUMBER,
  elements: z.number().int(),
  machineCycleDuration: z.number(),
  productionDuration: z.number(),
})

const REASON_SCHEMA = BASE_DOCUMENT_SCHEMA.extend({
  code: NON_EMPTY_STRING,
  description: z.string().optional().default(''),
})

export const REWORK_REASON_SCHEMA = REASON_SCHEMA.extend({
  type: z.literal(DocumentType.REWORK_REASON),
})

export const DISCARD_REASON_SCHEMA = REASON_SCHEMA.extend({
  type: z.literal(DocumentType.DISCARD_REASON),
})

export const STOP_REASON_SCHEMA = REASON_SCHEMA.extend({
  type: z.literal(DocumentType.STOP_REASON),
})

export const PRODUCTION_SCHEMA = schemaForType<Production>()(
  QUANTITY_SCHEMA.extend({
    type: z.literal(DocumentType.PRODUCTION),
  })
)

export const REWORK_SCHEMA = schemaForType<Reworks>()(
  QUANTITY_SCHEMA.extend({
    type: z.literal(DocumentType.REWORK),
    reason: REWORK_REASON_SCHEMA,
  })
)

export const DISCARD_SCHEMA = schemaForType<Discards>()(
  QUANTITY_SCHEMA.extend({
    type: z.literal(DocumentType.DISCARD),
    reason: DISCARD_REASON_SCHEMA,
  })
)

export const TEST_SCHEMA = schemaForType<Test>()(
  REPORT_ITEM_PART_SCHEMA.extend({
    type: z.literal(DocumentType.TEST),
    batchCode: NON_EMPTY_STRING,
    shift: ENCODED_SHIFT_SCHEMA,
    quantity: REQUIRED_NUMBER,
    performedAt: NON_EMPTY_STRING,
    measures: z.array(
      z.object({
        id: z.nativeEnum(Measure),
        value: REQUIRED_NUMBER,
      })
    ),
  })
)

export const REPORT_HEADER_SCHEMA = schemaForType<Report>()(
  BASE_DOCUMENT_SCHEMA.extend({
    type: z.literal(DocumentType.REPORT),
    shift: ENCODED_SHIFT_SCHEMA,
    code: NON_EMPTY_STRING,
    decodedShift: DECODED_SHIFT_SCHEMA,
    workerCode: NON_EMPTY_STRING,
    state: z.nativeEnum(ReportState),
    breakDuration: z.number().gte(0),
    shiftChangeDuration: z.number().gte(0),
    notes: z.string().default(''),
    workCenter: WORK_CENTER_SCHEMA,
    totalProductionDuration: z.number().gte(0),
    totalStopDuration: z.number().gte(0),
    totalShiftDuration: z.number().gte(0),
  })
)

export const REPORT_HEADER_SCHEMA_WARNINGS = schemaForType<Partial<Report>>()(
  BASE_DOCUMENT_SCHEMA.extend({
    type: z.literal(DocumentType.REPORT),
    decodedShift: DECODED_SHIFT_SCHEMA,
    totalProductionDuration: z.number().gte(0),
    totalStopDuration: z.number().gte(0),
    totalShiftDuration: z.number().gte(0),
  }).superRefine((schema, ctx) => {
    const schemaShiftDuration = Interval.fromISO(schema.decodedShift.start + '/' + schema.decodedShift.end).toDuration(
      'seconds'
    ).seconds

    if (schema.totalShiftDuration > schemaShiftDuration) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        type: 'number',
        inclusive: true,
        maximum: schemaShiftDuration,
        message: t('ShiftTimeExceeds'),
        path: ['totalShiftDuration'],
      })
    }
  })
)

export const STOP_SCHEMA = schemaForType<Stop>()(
  REPORT_ITEM_PART_SCHEMA.extend({
    type: z.literal(DocumentType.STOP),
    reason: STOP_REASON_SCHEMA,
    stopDuration: z.number().gte(0),
    start: z
      .string()
      .regex(/\d{2}:\d{2}/gm, t('MissingRequiredField'))
      .nullable(),
    end: z
      .string()
      .regex(/\d{2}:\d{2}/gm, t('MissingRequiredField'))
      .nullable(),
  })
).superRefine((schema, ctx) => {
  if (schema.start && schema.end) {
    const shift = decodeShift(schema.shift)
    if (shift === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid shift',
        fatal: true,
      })

      return z.NEVER
    }
    const shiftInterval = Interval.fromISO(shift.start + '/' + shift.end)

    if (!DateTime.fromFormat(schema.start, 'HH:mm').isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('InvalidFormat'),
        path: ['start'],
      })
    }

    if (!DateTime.fromFormat(schema.end, 'HH:mm').isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('InvalidFormat'),
        fatal: true,
        path: ['end'],
      })
      return z.NEVER
    }

    const stopInterval = getIntervalInShift(shift, schema.start, schema.end)

    // This returns `true` only if the stop is fully contained in the shift
    if (!stopInterval.isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('EndMustBeAfterStart'),
        path: ['end'],
      })
    }

    if (!shiftInterval.engulfs(stopInterval)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('StopIsOutsideShift'),
      })
    }
  }
  return true
})
