import { BearMetal } from '@/assets/colors'
import { Gauge } from '@/components/Gauge'
import { cn } from '@/lib/utils'
import { TablerIconsProps } from '@tabler/icons-react'

export type TileButtonProps = {
  title: string
  label?: string
  value?: string | number
  Icon?: (props: TablerIconsProps) => JSX.Element
  color?: keyof typeof BearMetal
  gauge?: boolean
  disabled?: boolean
  dashed?: boolean
  onClick?: () => void
}

export const TileButton = ({ title, label, value, Icon, color, gauge, disabled, onClick }: TileButtonProps) => {
  return (
    <div className="flex w-1/3 h-[280px] p-2">
      <div
        className={cn(
          'flex-1 flex flex-col items-center rounded-xl shadow-[0.1rem_0.1rem_#111] active:scale-95',
          disabled ? 'opacity-60' : ''
        )}
        onClick={() => {
          if (!disabled && onClick) {
            onClick()
          }
        }}
        style={{
          background: color ? BearMetal[color] : BearMetal.gradientGray,
        }}
      >
        <div className="relative h-full w-full">
          {value !== undefined && !gauge && (
            <div className="absolute right-0 bottom-0 overflow-hidden">
              <span className="text-[10rem] font-anton tracking-wide leading-none opacity-25 uppercase">
                {String(value).slice(0, 4)}
              </span>
            </div>
          )}
          {value !== undefined && gauge && (
            <div className="absolute right-0 bottom-0 p-2">
              <Gauge value={Number(value)} size="large" showValue={true} />
            </div>
          )}
          {value === undefined && Icon && (
            <span className="absolute right-0 bottom-0 opacity-25 h-2/3">
              <Icon className="w-full h-full" />
            </span>
          )}
          <div className="w-full h-1/2 p-4">
            <div className="flex items-center h-12 mb-2">
              <h3 className="text-2xl leading-none line-clamp-2 uppercase">{title}</h3>
            </div>
            {label && <span className="font-light tracking-wide uppercase">{label}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}
