/** Identifiers for document types used in the application */
export enum DocumentType {
  BATCH = 'BATCH',
  DISCARD = 'DISCARD',
  DISCARD_REASON = 'DISCARD_REASON',
  DIVISION = 'DIVISION',
  PROCESSING_DETAILS = 'PROCESSING_DETAILS',
  PRODUCT = 'PRODUCT',
  PRODUCTION = 'PRODUCTION',
  REPORT = 'REPORT',
  REWORK = 'REWORK',
  REWORK_REASON = 'REWORK_REASON',
  SCHEDULE_ITEM = 'SCHEDULE_ITEM',
  STOP = 'STOP',
  STOP_REASON = 'STOP_REASON',
  TEST = 'TEST',
  WORK_CENTER = 'WORK_CENTER',
  WORK_CENTER_DISCARD = 'WORK_CENTER_DISCARD',
  WORK_CENTER_REWORK = 'WORK_CENTER_REWORK',
  WORK_CENTER_STOP = 'WORK_CENTER_STOP',
}

/**
 * Base document data for all the other documents
 */
export type BaseDocument<T extends DocumentType> = {
  /** ID of the document */
  _id: string

  /** Type of the document */
  type: T

  /** Timestamp RFC 3339 UTC of the creation of the document  */
  createdAt: string

  /** Timestamp RFC 3339 UTC of the last update of the document  */
  updatedAt: string

  /** ID of the user that created the document */
  createdBy: string
}

/** Represents a reference to another document, a string (document ID) for CouchDB */
export type DocumentRef<D extends BaseDocument<DocumentType>> = D['_id']
