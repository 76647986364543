import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { ComboSelect } from '@/components/ComboSelect'
import { NewReportScreenCard } from '@/components/NewReportScreenCard'
import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useAuthentication } from '@/hooks/useAuthentication'
import { calculateTotalStopDuration } from '@/lib/calculation'
import { getIntervalInShift } from '@/lib/datetime'
import { ROUTE } from '@/routes'
import { NewReportOutletContext, getBaseReportItemDocument } from '@/screens/reports/EditReportLayout'
import { StopReasonWithDuration } from '@/shared/crud/WorkCenterViewServices'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Shift } from '@/shared/dto/Shift'
import { Stop } from '@/shared/dto/Stop'
import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import { Interval } from 'luxon'
import { useEffect } from 'react'
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { z } from 'zod'

type StopFormFields = {
  reasonCode: string
  numberOfStops: number
  stopRanges: {
    start: string
    end: string
  }[]
}

const STOPS_FORM_SCHEMA = z.object({
  reasonCode: z.string(),
  numberOfStops: z.coerce.number().gt(0),
  stopRanges: z.array(
    z.object({
      start: z.string().regex(/\d{2}:\d{2}/gm, t('MissingRequiredField')),
      end: z.string().regex(/\d{2}:\d{2}/gm, t('MissingRequiredField')),
    })
  ),
})

export function EditReportStopScreen() {
  const navigate = useNavigate()
  const { index: indexParam } = useParams()
  const { currentUser } = useAuthentication()
  const { editorState, setEditorState, availableStopReasons, isEdit } = useOutletContext<NewReportOutletContext>()

  const stopReasonCode = indexParam || ''
  const stopReasonDuration = availableStopReasons.find(reason => reason.code === stopReasonCode)?.stopDuration

  // stop reasons without fixed duration enable start-end fields
  const hasTimeRanges = stopReasonDuration === undefined || stopReasonDuration === null

  const onUpdateStops = (data: StopFormFields) => {
    const newStops = cloneDeep(editorState.stops)
    const stopReason = availableStopReasons.find(r => r.code === data.reasonCode)!

    if (data.reasonCode !== stopReasonCode) {
      delete newStops[stopReasonCode]
    }

    const numberOfStops = Number.isNaN(data.numberOfStops) ? 1 : data.numberOfStops

    if (stopReason.stopDuration === undefined || stopReason.stopDuration === null) {
      newStops[data.reasonCode] = data.stopRanges.map(range => ({
        ...getBaseReportItemDocument(DocumentType.STOP, editorState.header, currentUser.username),
        reason: stopReason,
        stopDuration:
          range.start && range.end
            ? getIntervalInShift(editorState.header.decodedShift, range.start, range.end)
                .toDuration()
                .shiftTo('seconds').seconds
            : 0,
        start: range.start,
        end: range.end,
      }))
    } else {
      newStops[data.reasonCode] = []
      for (let index = 0; index < numberOfStops; index++) {
        newStops[data.reasonCode].push({
          ...getBaseReportItemDocument(DocumentType.STOP, editorState.header, currentUser.username),
          reason: stopReason,
          stopDuration: stopReason.stopDuration,
          start: null,
          end: null,
        })
      }
    }

    const totalStopDuration = calculateTotalStopDuration(newStops)

    setEditorState(old => ({
      ...old,
      header: {
        ...old.header,
        totalStopDuration,
      },
      stops: newStops,
    }))

    if (data.reasonCode !== (indexParam || '')) {
      navigate(
        isEdit
          ? generatePath(ROUTE.reportEditStops, { index: data.reasonCode, id: editorState.header.code })
          : generatePath(ROUTE.reportCreateStops, { index: data.reasonCode }),
        { replace: true }
      )
    }
  }

  return (
    <NewReportScreenCard>
      <CardContent className="pt-6">
        <StopForm
          stops={editorState.stops[stopReasonCode]}
          shift={editorState.header.decodedShift}
          hasTimeRanges={hasTimeRanges}
          setData={onUpdateStops}
          stopReasons={availableStopReasons}
        />
      </CardContent>
    </NewReportScreenCard>
  )
}

type StopFormProps = {
  stops: Stop[]
  shift: Shift
  hasTimeRanges: boolean
  stopReasons: StopReasonWithDuration[]
  setData: (data: StopFormFields) => void
}

function StopForm({ stops, shift, hasTimeRanges, stopReasons, setData }: StopFormProps) {
  const { t } = useTranslation()

  const refinedSchema = STOPS_FORM_SCHEMA.superRefine((schema, ctx) => {
    if (hasTimeRanges) {
      schema.stopRanges.forEach(({ start, end }, idx) => {
        if (!/\d{2}:\d{2}/gm.test(start)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('InvalidFormat'),
            fatal: true,
            path: ['stopRanges', idx, 'start'],
          })
          return z.NEVER
        }

        if (!/\d{2}:\d{2}/gm.test(end)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('InvalidFormat'),
            fatal: true,
            path: ['stopRanges', idx, 'end'],
          })
          return z.NEVER
        }

        const stopInterval = getIntervalInShift(shift, start, end)
        if (!stopInterval.isValid) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('InvalidInterval'),
            fatal: true,
            path: ['stopRanges', idx, 'start'],
          })
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('InvalidInterval'),
            fatal: true,
            path: ['stopRanges', idx, 'end'],
          })
        }

        const shiftInterval = Interval.fromISO(shift.start + '/' + shift.end)
        if (!shiftInterval.engulfs(stopInterval)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('StopIsOutsideShift'),
            path: ['stopRanges', idx, 'start'],
          })
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('StopIsOutsideShift'),
            path: ['stopRanges', idx, 'end'],
          })
        }
      })
    }
  })
  const form = useForm<z.infer<typeof refinedSchema>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(refinedSchema),
    defaultValues: {
      reasonCode: stops[0]?.reason.code ?? '',
      numberOfStops: hasTimeRanges || stops.length > 1 ? stops.length : NaN,
      stopRanges: stops.map(s => ({
        start: s.start ?? '',
        end: s.end ?? '',
      })),
    },
  })

  const reasonCode = form.watch('reasonCode')
  useEffect(
    () => form.resetField('numberOfStops', { defaultValue: hasTimeRanges || stops.length > 1 ? stops.length : NaN }),
    [form, hasTimeRanges, reasonCode, stops.length]
  )

  useEffect(() => {
    const subscription = form.watch(data => setData(data as StopFormFields))
    return () => subscription.unsubscribe()
  }, [form, setData])

  return (
    <Form {...form}>
      <div className="grid gap-6">
        <div className="grid col-span-full grid-cols-3 gap-4">
          <FormField
            control={form.control}
            name={`reasonCode`}
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>{t('CauseId')}</FormLabel>
                <FormControl>
                  <ComboSelect
                    autoFocus={!field.value}
                    items={(stopReasons || []).map(item => ({
                      label: `${item.code} - ${item.description}`,
                      value: item.code,
                    }))}
                    label={t('CauseId')}
                    value={field.value}
                    onSelect={item => field.onChange(item.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`numberOfStops`}
            defaultValue={1}
            render={({ field }) => (
              <FormItem className="ml-2">
                <FormLabel>{t('NumberOfEvents')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    autoFocus={!hasTimeRanges}
                    type="number"
                    disabled={hasTimeRanges}
                    placeholder={t('NumberOfEvents')}
                    value={hasTimeRanges ? stops.length : Number.isNaN(field.value) ? '' : field.value}
                    onChange={e => field.onChange(!isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : NaN)}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        {hasTimeRanges && <StopsTable form={form} />}
      </div>
    </Form>
  )
}

function StopsTable({ form }: { form: UseFormReturn<StopFormFields, unknown, undefined> }) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'stopRanges',
  })

  const { t } = useTranslation()

  const addRow = () => {
    append({
      start: '',
      end: '',
    })
  }

  return (
    <Table className="table-fixed">
      <TableHeader>
        <TableRow>
          <TableHead>{t('StartTime')}</TableHead>
          <TableHead>{t('EndTime')}</TableHead>
          <TableHead className="w-20"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fields.map((field, idx, arr) => (
          <TableRow key={field.id}>
            <TableCell>
              <FormField
                control={form.control}
                defaultValue=""
                name={`stopRanges.${idx}.start`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={e => {
                          field.onChange(e)
                          form.trigger(`stopRanges.${idx}.end`, { shouldFocus: false })
                        }}
                        type="time"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </TableCell>
            <TableCell>
              <FormField
                control={form.control}
                defaultValue=""
                name={`stopRanges.${idx}.end`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={e => {
                          field.onChange(e)
                          form.trigger(`stopRanges.${idx}.start`, { shouldFocus: false })
                        }}
                        type="time"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </TableCell>
            <TableCell>
              {arr.length > 1 && (
                <div className="mr-4 flex">
                  <Button tabIndex={-1} type="button" onClick={() => remove(idx)} variant="link">
                    <IconTrash />
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}

        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>
            <div className="mr-4 flex">
              <ButtonWithHotkey
                hotkey="shift+alt+1"
                tooltip={t('AddObject', {
                  object: t('Stop'),
                })}
                enableOnFormTags
                type="button"
                onClick={addRow}
                className="mb-2"
                variant="link"
              >
                <IconPlus />
              </ButtonWithHotkey>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
