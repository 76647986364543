import { AuthenticationToken } from '../dto/AuthenticationToken'
import { User } from '../dto/User'

/**
 * Tests a string against a pattern which can contain wildcards (`*`)
 * @param wildcard The wildcarded pattern to test against
 * @param test The role to be tested
 * @returns True if the role matches the pattern, false otherwise
 */
export function wildcardMatch(wildcard: string, test: string): boolean {
  // Edge case for an entirely wildcarded pattern
  if (wildcard === ROLE_WILDCARD) {
    return true
  }

  let tIndex = 0 // index for test string

  for (let wIndex = 0; wIndex < wildcard.length; wIndex++) {
    const wChar = wildcard[wIndex]
    const tChar = test[tIndex]

    if (wChar === ROLE_WILDCARD) {
      // Handle cases where wildcard is the last character
      if (wIndex === wildcard.length - 1) {
        return true
      }

      // Advance test index to match the next character in the wildcard pattern
      wIndex++ // move to the next character in the pattern
      const nextWChar = wildcard[wIndex]

      // Find next character in test string that matches next character in pattern after wildcard
      while (tIndex < test.length && test[tIndex] !== nextWChar) {
        tIndex++
      }

      // If end of test string is reached without finding a match, return false
      if (tIndex === test.length && nextWChar !== ROLE_WILDCARD) {
        return false
      }
    } else if (wChar !== tChar) {
      return false
    }

    tIndex++ // Move to the next character in the test string
  }

  // Ensure that all characters in the test string were checked
  return tIndex === test.length
}

/**
 * Returns true if the specified user has the specified role,
 * or at least one of the specified role in case of an array.
 */
export function hasRole(
  userOrToken: AuthenticationToken | User | null | undefined,
  role: UserRole | UserRole[]
): boolean {
  if (Array.isArray(role)) {
    for (const r of role) {
      if (hasRole(userOrToken, r)) {
        return true
      }
    }
    return false
  }

  return userOrToken != null && userOrToken.roles.some(r => wildcardMatch(r, role))
}

/**
 * Returns true if the provided user or authentication token has ADMIN privileges.
 */
export function isAdmin(userOrToken: AuthenticationToken | null | undefined | User) {
  return hasRole(userOrToken, UserRole.ADMIN)
}

/**
 * Returns the ID of the user provided an user object or authentication token.
 * For user objects returns the id property, for the token the sub property.
 */
export function getId(userOrToken: AuthenticationToken | User) {
  if ('id' in userOrToken) {
    return userOrToken.id
  } else {
    return userOrToken.sub
  }
}

/**
 * Checks if the provided user or authentication token has the permission to modify the specified
 * user object.
 * It has the permission either if the user role is ADMIN or the user ID is identical.
 */
export function hasPermissionFor(userOrToken: AuthenticationToken | null | undefined | User, userId: string) {
  return userOrToken != null && (isAdmin(userOrToken) || getId(userOrToken) === userId)
}

/** Roles of the user */
export enum UserRole {
  /** Generic admin role for the reports app */
  ADMIN = 'reports:*',

  /** Allows a read-only access to the reports */
  REPORTS_READ = 'reports:read',

  /** Role that permits inserting production reports */
  REPORTS_WRITE = 'reports:write',

  /** Allows modifying the registry */
  REPORTS_REGISTRY = 'reports:registry',
}

/** Common separator used for role layering/leveling */
export const ROLE_CHILD_SEPARATOR = ':'

/**
 * A wildcard role enables admin rights for that role group,
 * as well as granting all roles in the group.
 */
export const ROLE_WILDCARD = '*'
