export const Gauge = ({
  value,
  size = 'small',
  showValue = true,
}: {
  value: number
  size: 'small' | 'medium' | 'large'
  showValue: boolean
}) => {
  // 100 is the max value
  const valueStr = Math.round(value).toString()
  value = value > 100 ? 100 : value

  const circumference = 332 //2 * Math.PI * 53; // 2 * pi * radius
  const valueInCircumference = (value / 100) * circumference
  const strokeDasharray = `${circumference} ${circumference}`
  const initialOffset = circumference
  const strokeDashoffset = initialOffset - valueInCircumference

  const sizes = {
    small: {
      width: '36',
      height: '36',
      textSize: 'text-xs',
    },
    medium: {
      width: '72',
      height: '72',
      textSize: 'text-lg',
    },
    large: {
      width: '165',
      height: '165',
      textSize: 'text-4xl',
    },
  }

  const colors = {
    success: 'text-[#40c057]',
    warning: 'text-[#F9C61C]',
    danger: 'text-[#e40613]',
  }

  const color = value < 40 ? colors.danger : value < 75 ? colors.warning : colors.success

  return (
    <div className="flex flex-col items-center justify-center relative">
      <svg
        fill="none"
        shapeRendering="crispEdges"
        height={sizes[size].height}
        width={sizes[size].width}
        viewBox="-5 -5 130 130"
        strokeWidth="2"
        className="transform -rotate-90"
      >
        <circle
          className="text-[#fff] opacity-25"
          strokeWidth="18"
          stroke="currentColor"
          fill="transparent"
          shapeRendering="geometricPrecision"
          r="53"
          cx="60"
          cy="60"
        />
        <circle
          className={`${color} animate-gauge_fill`}
          strokeWidth="16"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={initialOffset}
          shapeRendering="geometricPrecision"
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="53"
          cx="60"
          cy="60"
          style={{
            strokeDashoffset: strokeDashoffset,
            transition: 'stroke-dasharray 1s ease 0s,stroke 1s ease 0s',
          }}
        />
      </svg>
      {showValue ? (
        <div className="absolute flex opacity-0 animate-gauge_fadeIn">
          <p className={`font-anton tracking-wide opacity-25 ${sizes[size].textSize}`}>{`${valueStr}%`}</p>
        </div>
      ) : null}
    </div>
  )
}
