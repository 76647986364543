import { AUTHENTICATION_SERVICE_URI, COUCHDB_ROOT_PATH } from '@/config'
import { AuthenticationContext } from '@/contexts/AuthenticationContext'
import { composeAuthenticationHeader, decodeJwtToken, getUserFromAuthenticationToken } from '@/shared/utils/jwt'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TOKEN_STORAGE_KEY = 'token'

/** verifies if a session token is valid */
async function verifySessionToken(token: string): Promise<boolean> {
  try {
    console.debug('Verifying user token...')
    const response = await axios.get(COUCHDB_ROOT_PATH + '_session', {
      headers: {
        Authorization: composeAuthenticationHeader(token),
      },
    })

    if (response.data?.ok) {
      console.info('Session token is valid', response.data)
      return true
    } else {
      console.info('User session token is expired, data: ', response.data)
      return false
    }
  } catch (e) {
    console.warn('Get session request failed', e)
    return false
  }
}

/**
 * Provides the auth context to the whole application.
 * If the user is not signed in it redirects the user to the SignIn page,
 * hosted in the IRSAP authentication service.
 * Checks then if `token` is inside the URL, if so verifies the token and saves
 * it into the application local storage.
 */
export function AuthenticationProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState<string>()

  useEffect(() => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    const init = async (token: string | null) => {
      if (token === null) {
        // try to restore the token from the session
        token = localStorage.getItem(TOKEN_STORAGE_KEY)
      }

      if (token !== null) {
        const validSession = await verifySessionToken(token)
        if (validSession) {
          localStorage.setItem(TOKEN_STORAGE_KEY, token)
          setToken(token)
        }
      }
    }

    init(token)
      .catch(e => {
        console.warn(`Error initializing authentication context`, e)
      })
      .finally(() => {
        setLoading(false)

        if (token !== null) {
          // removes token from the history, without triggering a page reload
          url.searchParams.delete('token')
          window.history.replaceState({ path: url.href }, '', url)
        }
      })
  }, [])

  const signOut = () => {
    console.info('Signing out user')

    localStorage.removeItem(TOKEN_STORAGE_KEY)
    setToken(undefined)
  }

  if (loading) {
    return null
  }

  if (token === undefined) {
    console.info('User has not a valid session. Redirecting to the authentication server')

    const authUrl = new URL(AUTHENTICATION_SERVICE_URI)
    authUrl.searchParams.set('return_to', window.location.origin)

    window.location.replace(authUrl)

    // This code should not be executed. Put a fallback message here anyway.
    return (
      <p>
        {t('AuthRedirect')} <a href={authUrl.href}>{authUrl.href}</a>
      </p>
    )
  }

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser: getUserFromAuthenticationToken(decodeJwtToken(token)),
        jwtToken: token,
        signOut,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
