import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { ButtonWithTooltip } from '@/components/ButtonWithTooltip'
import { buttonVariants } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { ComponentPropsWithoutRef, ElementRef, Fragment, ReactNode, forwardRef } from 'react'

type ButtonGroupProps = {
  children: ReactNode | ReactNode[]
}

export function ButtonGroup({ children }: ButtonGroupProps) {
  return (
    <div
      className={cn(
        buttonVariants({ variant: 'outline' }),
        'p-0 box-border hover:bg-transparent hover:text-accent-inherit'
      )}
    >
      {(children instanceof Array ? children : [children]).map((child, idx, arr) => (
        <Fragment key={idx}>
          {child}
          {idx !== arr.length - 1 && <Separator orientation="vertical" className="m-0 p-0 h-9" />}
        </Fragment>
      ))}
    </div>
  )
}

ButtonGroup.ButtonWithTooltip = forwardRef<
  ElementRef<typeof ButtonWithTooltip>,
  ComponentPropsWithoutRef<typeof ButtonWithTooltip>
>(({ className, children, ...props }, ref) => (
  <ButtonWithTooltip ref={ref} className={cn(className, 'm-0 rounded-none box-border')} {...props} variant="ghost">
    {children}
  </ButtonWithTooltip>
))

ButtonGroup.ButtonWithHotkey = forwardRef<
  ElementRef<typeof ButtonWithHotkey>,
  ComponentPropsWithoutRef<typeof ButtonWithHotkey>
>(({ className, children, ...props }, ref) => (
  <ButtonWithHotkey ref={ref} className={cn(className, 'm-0 rounded-none box-border')} {...props} variant="ghost">
    {children}
  </ButtonWithHotkey>
))
