import { ComboSelect } from '@/components/ComboSelect'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { EXTERNAL_DIVISION_CODE } from '@/constants'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { joinIgnoreEmpty } from '@/lib/localize'
import { cn, getDocumentId } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { StopReason } from '@/shared/dto/Reason'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { WorkCenterStopReason } from '@/shared/dto/WorkCenterReason'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'

type WorkCenterStopsFormProps = {
  value?: WorkCenterStopReason
  onFinish?: () => void
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  workCenters: WorkCenter[]
  stopReasons: StopReason[]
}

type FormFields = {
  workCenterCode: string
  reasonCode: string
  stopDuration: string
}

export function WorkCenterStopsForm({
  value,
  onFinish,
  open,
  setOpen,
  workCenters,
  stopReasons,
}: WorkCenterStopsFormProps) {
  const isEdit = value?._id !== undefined

  const { currentUser } = useAuthentication()

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<WorkCenterStopReason>(db)

  const form = useForm<FormFields>({
    defaultValues: {
      workCenterCode: value?.workCenterCode ?? '',
      reasonCode: value?.reasonCode ?? '',
      stopDuration:
        value?.stopDuration === undefined
          ? ''
          : value?.stopDuration === null
            ? ''
            : (value.stopDuration / 60).toFixed(3),
    },
    reValidateMode: 'onSubmit',
  })

  const title = t(isEdit ? 'EditObject' : 'AddObject', { object: t('StopTime').toLocaleLowerCase() })

  const onSubmit = async (data: FormFields) => {
    try {
      const timestamp = DateTime.now().toUTC().toISO()
      const formData = {
        workCenterCode: data.workCenterCode,
        reasonCode: data.reasonCode,
        stopDuration: data.stopDuration ? Number.parseFloat(data.stopDuration) * 60 : null,
        updatedAt: timestamp,
        createdBy: currentUser.username,
      }

      if (isEdit) {
        const inputDoc: WorkCenterStopReason = {
          ...value,
          ...formData,
          type: DocumentType.WORK_CENTER_STOP,
        }
        console.debug('On update', inputDoc)
        await crudService.update(inputDoc)
      } else {
        const inputDoc: WorkCenterStopReason = {
          ...value,
          ...formData,
          _id: getDocumentId(DocumentType.WORK_CENTER_STOP, data.workCenterCode, data.reasonCode),
          createdAt: timestamp,
          type: DocumentType.WORK_CENTER_STOP,
        }
        console.debug('On create', inputDoc)
        await crudService.create(inputDoc)
      }

      if (onFinish) {
        onFinish()
      }

      setOpen(false)
    } catch (error) {
      console.error('form error', error)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle> {title} </DialogTitle>
          </DialogHeader>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <FormField
                control={form.control}
                defaultValue=""
                name="workCenterCode"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('WorkCentre')}</FormLabel>
                    <FormControl>
                      <ComboSelect
                        items={workCenters
                          .filter(wc => wc.code !== EXTERNAL_DIVISION_CODE)
                          .map(item => ({
                            value: item.code,
                            label: joinIgnoreEmpty(' - ', item.code, item.description),
                          }))}
                        label={t('WorkCenter')}
                        value={field.value}
                        onSelect={item => field.onChange(item.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                defaultValue=""
                name="reasonCode"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Code')}</FormLabel>
                    <FormControl>
                      <ComboSelect
                        items={stopReasons.map(item => ({
                          value: item.code,
                          label: joinIgnoreEmpty(' - ', item.code, item.description),
                        }))}
                        label={t('Products')}
                        value={field.value}
                        onSelect={item => field.onChange(item.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="stopDuration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('StopTime')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={t('StopTime')}
                        className={cn(
                          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="flex flex-row pt-6 justify-end">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t('Cancel')}
                </Button>
              </DialogClose>
              <Button type="submit" className="ml-2">
                {isEdit ? t('Edit') : t('Add')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
