import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { ComboSelect } from '@/components/ComboSelect'
import { DeleteButton } from '@/components/DeleteButton'
import { DeleteDialog } from '@/components/DeleteDialog'
import { PaginationFooter } from '@/components/PaginationFooter'
import { ContentLayout } from '@/components/layouts/ContentLayout'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { EXTERNAL_DIVISION_CODE } from '@/constants'
import { useAllDocs } from '@/hooks/useAllDocs'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { usePaginatedQuery } from '@/hooks/usePaginatedQuery'
import { humanizeSeconds } from '@/lib/datetime'
import { joinIgnoreEmpty } from '@/lib/localize'
import { CASE_INSENSITIVE_MATCH, cn, escapeForRegex } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { StopReason } from '@/shared/dto/Reason'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { WorkCenterStopReason } from '@/shared/dto/WorkCenterReason'
import { UserRole, hasRole } from '@/shared/utils/auth'
import { IconDatabasePlus } from '@tabler/icons-react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { WorkCenterStopsForm } from './WorkCenterStopsForm'

type WorkCenterStopFilters = {
  workCenterCode?: string
  reasonCode?: string
}

const BASE_QUERY = new CouchdbQueryBuilderImpl().where('type', '=', DocumentType.WORK_CENTER_STOP)

export function WorkCenterStopsListScreen() {
  const { currentUser } = useAuthentication()
  const isUserAdmin = hasRole(currentUser, [UserRole.REPORTS_REGISTRY, UserRole.ADMIN])

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<WorkCenterStopReason>(db)

  const [selectedEntry, setSelectedEntry] = useState<WorkCenterStopReason | undefined>()
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [filters, setFilters] = useState<WorkCenterStopFilters>({})
  const [query, setQuery] = useState(BASE_QUERY)

  const { data: stopReasons } = useAllDocs<DocumentType.STOP_REASON, StopReason>(DocumentType.STOP_REASON)
  const { data: workCenters } = useAllDocs<DocumentType.WORK_CENTER, WorkCenter>(DocumentType.WORK_CENTER)

  const updateFilter = (
    name: keyof WorkCenterStopFilters,
    value: WorkCenterStopFilters[keyof WorkCenterStopFilters]
  ) => {
    setFilters(old => ({
      ...old,
      [name]: value,
    }))
  }

  const updateQuery = useDebouncedCallback(() => {
    let query = BASE_QUERY

    if (filters.workCenterCode) {
      query = query.where('workCenterCode', '=', filters.workCenterCode)
    }
    if (filters.reasonCode) {
      query = query.where('reasonCode', 'regex', CASE_INSENSITIVE_MATCH + escapeForRegex(filters.reasonCode))
    }

    setQuery(query)
  }, 300)

  useEffect(() => {
    /* trigger query update when filters change */
    updateQuery()
  }, [filters, updateQuery])

  const { data, goToNext, goToPrev, pageIndex, hasNext, hasPrev, refresh } = usePaginatedQuery(
    query,
    crudService,
    pageSize
  )

  const onDeleteItem = async (item: WorkCenterStopReason) => {
    await crudService.delete(item._id)
    refresh()
  }

  return (
    <>
      <ContentLayout
        leftActions={
          <ButtonWithHotkey
            hotkey="ctrl+n"
            disabled={!isUserAdmin}
            tooltip={t('AddNewRegistryItem')}
            onClick={() => {
              setSelectedEntry(undefined)
              setEditModalVisible(true)
            }}
            variant="outline"
          >
            <IconDatabasePlus />
          </ButtonWithHotkey>
        }
        footer={
          <PaginationFooter
            setPageSize={setPageSize}
            pageSize={pageSize}
            nextEnabled={hasNext}
            prevEnabled={hasPrev}
            onNext={goToNext}
            onPrev={goToPrev}
            pageIndex={pageIndex}
            currentPageSize={data.length}
          />
        }
      >
        <Table className="table-fixed">
          <TableHeader className="sticky inset-0 bg-secondary">
            <TableRow className="shadow-sticky-header-b [&_th]:pt-4 [&_th]:align-top [&_th]:text-primary [&_input]:text-muted-foreground">
              <TableHead className="w-1/6">
                <div className="flex flex-col">
                  <span>{t('WorkCentre')}</span>
                  <ComboSelect
                    className="my-2 print:hidden bg-secondary"
                    items={(workCenters || [])
                      .filter(wc => wc.divisionCode !== EXTERNAL_DIVISION_CODE)
                      .map(item => ({
                        value: item.code,
                        label: joinIgnoreEmpty(' - ', item.code, item.description),
                      }))}
                    showReset
                    resetLabel={t('Any')}
                    label={t('WorkCenter')}
                    onSelect={value => updateFilter('workCenterCode', value.value)}
                  />
                </div>
              </TableHead>
              <TableHead>
                <div className="flex flex-col">
                  <span>{t('Code')}</span>
                  <Input
                    type="text"
                    value={filters.reasonCode || ''}
                    onChange={e => updateFilter('reasonCode', e.target.value)}
                    className="my-2 print:hidden"
                    placeholder={t('Code')}
                  />
                </div>
              </TableHead>
              <TableHead>{t('StopTime')}</TableHead>
              <TableHead className="w-20">{t('Actions')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map(item => (
              <TableRow
                key={item._id}
                className={cn(isUserAdmin && 'cursor-pointer', '[&_td]:break-words')}
                onClick={() => {
                  if (isUserAdmin) {
                    setSelectedEntry(item)
                    setEditModalVisible(true)
                  }
                }}
              >
                <TableCell>{item.workCenterCode}</TableCell>
                <TableCell>{item.reasonCode}</TableCell>
                <TableCell>{item.stopDuration !== null && humanizeSeconds(item.stopDuration, false)}</TableCell>
                <TableCell>
                  {isUserAdmin ? (
                    <DeleteButton
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSelectedEntry(item)
                        setDeleteModalVisible(true)
                      }}
                    />
                  ) : (
                    <div className="h-9"></div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow />
          </TableBody>
        </Table>
      </ContentLayout>
      <DeleteDialog open={deleteModalVisible} setOpen={setDeleteModalVisible}>
        <DeleteDialog.Content onConfirm={() => selectedEntry && onDeleteItem(selectedEntry)} />
      </DeleteDialog>
      <WorkCenterStopsForm
        key={selectedEntry?._id}
        value={selectedEntry}
        open={editModalVisible}
        setOpen={setEditModalVisible}
        stopReasons={stopReasons || []}
        workCenters={workCenters || []}
        onFinish={() => refresh()}
      />
    </>
  )
}
