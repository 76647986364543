import { ReportView } from '@/components/ReportView'
import { NewReportOutletContext } from '@/screens/reports/EditReportLayout'
import { ReportFull } from '@/shared/crud/FullReportCrudService'
import { useOutletContext } from 'react-router-dom'

export function EditReportSummaryScreen() {
  const { editorState, validationErrors, validationWarnings, isEdit } = useOutletContext<NewReportOutletContext>()

  const report: ReportFull = {
    header: editorState.header,
    quantities: editorState.quantities,
    tests: editorState.tests,
    stops: editorState.stops,
    batches: editorState.batches,
  }

  return (
    <div className={'my-8 mx-4 2xl:mx-8'}>
      <ReportView
        isEdit={isEdit}
        report={report}
        errors={validationErrors}
        warnings={validationWarnings}
        linkingEnabled
      />
    </div>
  )
}
