import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { getDocumentId } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Division } from '@/shared/dto/Division'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'

type WorkCenterTypeFormProps = {
  value?: Division
  onFinish?: () => void
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function DivisionForm({ value, onFinish, open, setOpen }: WorkCenterTypeFormProps) {
  const isEdit = value?._id !== undefined

  const { currentUser } = useAuthentication()

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<Division>(db)

  const form = useForm<Division>({
    values: value,
    reValidateMode: 'onSubmit',
  })

  const title = t(isEdit ? 'EditObject' : 'AddObject', { object: t('Product').toLocaleLowerCase() })

  const onSubmit = async (data: Division) => {
    try {
      const timestamp = DateTime.now().toUTC().toISO()
      const inputDoc: Division = {
        ...value,
        ...data,
        type: DocumentType.DIVISION,
        updatedAt: timestamp,
      }

      if (isEdit) {
        console.debug('On update', inputDoc)
        await crudService.update(inputDoc)
      } else {
        inputDoc._id = getDocumentId(inputDoc.type, inputDoc.code)
        inputDoc.createdAt = timestamp
        inputDoc.createdBy = currentUser.username
        console.debug('On create', inputDoc)
        await crudService.create(inputDoc)
      }

      if (onFinish) {
        onFinish()
      }

      setOpen(false)
    } catch (error) {
      console.error('form error', error)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle> {title} </DialogTitle>
          </DialogHeader>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <FormField
                control={form.control}
                defaultValue=""
                name="code"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Code')}</FormLabel>
                    <FormControl>
                      <Input {...field} readOnly={isEdit} disabled={isEdit} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                defaultValue=""
                name="description"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Description')}</FormLabel>
                    <FormControl>
                      <Textarea className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="flex flex-row pt-6 justify-end">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t('Cancel')}
                </Button>
              </DialogClose>
              <Button type="submit" className="ml-2">
                {isEdit ? t('Edit') : t('Add')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
