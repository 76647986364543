import { CouchdbManagerProvider } from '@/components/CouchdbManagerProvider'
import { DrawerLayout } from '@/components/layouts/DrawerLayout'
import { Outlet } from 'react-router-dom'

export function Root() {
  return (
    <CouchdbManagerProvider>
      <DrawerLayout>
        <Outlet />
      </DrawerLayout>
    </CouchdbManagerProvider>
  )
}
