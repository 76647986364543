import { ButtonWithTooltip } from '@/components/ButtonWithTooltip'
import { ComponentPropsWithoutRef, ElementRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

type ButtonHotkeyProps = {
  hotkey: string
  enableOnFormTags?: boolean
}

export const ButtonWithHotkey = forwardRef<
  ElementRef<typeof ButtonWithTooltip>,
  ComponentPropsWithoutRef<typeof ButtonWithTooltip> & ButtonHotkeyProps
>(({ hotkey, enableOnFormTags = false, children, tooltip, ...props }, ref) => {
  const innerRef = useRef<HTMLButtonElement>(null)
  useImperativeHandle(ref, () => innerRef.current!, [])

  const callback = useCallback(() => {
    if (innerRef.current) {
      innerRef.current.click()
    }
  }, [])
  useHotkeys(hotkey, callback, {
    enabled: !props.disabled,
    enableOnFormTags,
    preventDefault: true,
  })

  return (
    <ButtonWithTooltip
      tooltip={
        <div className="flex gap-2 items-center">
          <span className="h-full">{tooltip}</span>
          <div className="flex gap-1">
            {hotkey.split('+').map(key => (
              <kbd
                key={key}
                className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted-foreground px-1.5 font-mono text-[10px] font-medium text-muted opacity-100"
              >
                {key.replace(/\b\S/g, t => t.toUpperCase())}
              </kbd>
            ))}
          </div>
        </div>
      }
      ref={innerRef}
      {...props}
    >
      {children}
    </ButtonWithTooltip>
  )
})
