import { Couchdb, CouchdbDoc, CouchdbFindQuery } from '@iotinga/ts-backpack-couchdb-client'
import { CrudService, QueryResult, ViewResult } from './types'

export class SimpleCouchCrudService<D extends CouchdbDoc> implements CrudService<D> {
  constructor(protected readonly db: Couchdb) {}

  async read(id: string, rev?: string): Promise<D | null> {
    try {
      const doc: D = await this.db.get(id, { rev })
      return doc
    } catch (e) {
      return null
    }
  }

  async create(item: D): Promise<D | null> {
    try {
      const response = await this.db.put(item)

      if (response.ok === true) {
        return {
          ...item,
          _id: response.id,
          _rev: response.rev,
        }
      }

      return null
    } catch (e) {
      return null
    }
  }

  async update(item: D): Promise<D | null> {
    try {
      const response = await this.db.createOrUpdateDoc(item)

      if (response.ok === true) {
        return {
          ...item,
          _id: response.id,
          _rev: response.rev,
        }
      }

      return null
    } catch (e) {
      return null
    }
  }

  async delete(id: string, rev?: string): Promise<void> {
    try {
      if (rev === undefined) {
        const currentDoc = await this.read(id)
        rev = currentDoc!._rev!
      }

      await this.db.delete(id, rev)
    } catch (e) {
      return
    }
  }

  async query(query: CouchdbFindQuery): Promise<QueryResult<D>> {
    const records = await this.db.find(query)

    return {
      items: records.docs as D[],
      paginationToken: records.bookmark,
    }
  }

  createMany(): Promise<D[] | null> {
    throw new Error('Method not implemented.')
  }

  view(): Promise<ViewResult<D>> {
    throw new Error('Method not implemented.')
  }

  count(): Promise<number> {
    throw new Error('Method not implemented.')
  }
}
