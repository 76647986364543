import { Tag, TagsSelector } from '@/components/TagsSelector'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { EXTERNAL_DIVISION_CODE } from '@/constants'
import { useAllDocs } from '@/hooks/useAllDocs'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { joinIgnoreEmpty } from '@/lib/localize'
import { getDocumentId } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import {
  PRODUCTION_DB_DESIGN_DOC_NAME,
  PRODUCTION_DB_NAME,
  WORK_CENTERS_BY_DISCARD_REASON_VIEW_NAME,
} from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { DiscardReason } from '@/shared/dto/Reason'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { WorkCenterDiscardReason } from '@/shared/dto/WorkCenterReason'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type DiscardReasonFormProps = {
  value?: DiscardReason
  onFinish?: () => void
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function DiscardReasonForm({ value, onFinish, open, setOpen }: DiscardReasonFormProps) {
  const isEdit = value?._id !== undefined

  const { currentUser } = useAuthentication()

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<DiscardReason>(db)
  const workCenterReasonCrudService = new SimpleCouchCrudService<WorkCenterDiscardReason>(db)

  const form = useForm<DiscardReason>({
    values: value,
    reValidateMode: 'onSubmit',
  })
  const reasonCode = form.watch('code')

  const { data: allWorkCenters } = useAllDocs<DocumentType.WORK_CENTER, WorkCenter>(DocumentType.WORK_CENTER)
  const [currentSelectedWorkCenters, setCurrentSelectedWorkCenters] = useState<WorkCenter[]>([])
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  useEffect(() => {
    if (isEdit) {
      db.design(PRODUCTION_DB_DESIGN_DOC_NAME)
        .view(WORK_CENTERS_BY_DISCARD_REASON_VIEW_NAME, {
          include_docs: true,
          reduce: false,
          key: reasonCode,
        })
        .then(resp => {
          const docs = resp.rows.map(row => row.doc) as unknown as WorkCenter[]
          setCurrentSelectedWorkCenters(docs.filter(wc => wc.divisionCode !== EXTERNAL_DIVISION_CODE))
          setSelectedTags(
            docs
              .filter(wc => wc.divisionCode !== EXTERNAL_DIVISION_CODE)
              .map(wc => ({
                label: wc.description,
                value: wc.code,
              }))
          )
        })
    }
  }, [db, isEdit, reasonCode])

  const title = t(isEdit ? 'EditObject' : 'AddObject', { object: t('DiscardId').toLocaleLowerCase() })

  const onSubmit = async (data: DiscardReason) => {
    try {
      const timestamp = DateTime.now().toUTC().toISO()
      const inputDoc: DiscardReason = {
        ...value,
        ...data,
        type: DocumentType.DISCARD_REASON,
        updatedAt: timestamp,
      }

      const workCentersMap = new Map<string, WorkCenter>()
      allWorkCenters?.forEach(wc => {
        workCentersMap.set(wc.code, wc)
      })

      const selectedWorkCentersMap = new Map<string, WorkCenter>()
      currentSelectedWorkCenters.forEach(wc => {
        selectedWorkCentersMap.set(wc.code, wc)
      })

      const selectedTagsMap = new Map<string, Tag>()
      selectedTags.forEach(tag => {
        selectedTagsMap.set(tag.value, tag)
      })

      const added = selectedTags.filter(tag => !selectedWorkCentersMap.has(tag.value)).map(tag => tag.value)
      const removed = currentSelectedWorkCenters.filter(wc => !selectedTagsMap.has(wc.code)).map(wc => wc.code)

      for (const wcCode of removed) {
        await workCenterReasonCrudService.delete(getDocumentId(DocumentType.WORK_CENTER_DISCARD, wcCode, reasonCode))
      }
      for (const wcCode of added) {
        await workCenterReasonCrudService.create({
          _id: getDocumentId(DocumentType.WORK_CENTER_DISCARD, wcCode, reasonCode),
          type: DocumentType.WORK_CENTER_DISCARD,
          createdAt: DateTime.utc().toISO(),
          updatedAt: DateTime.utc().toISO(),
          createdBy: currentUser.username,
          workCenterCode: wcCode,
          reasonCode: reasonCode,
        })
      }

      if (isEdit) {
        console.debug('On update', inputDoc)
        await crudService.update(inputDoc)
      } else {
        inputDoc._id = getDocumentId(inputDoc.type, inputDoc.code)
        inputDoc.createdAt = timestamp
        inputDoc.createdBy = currentUser.username
        console.debug('On create', inputDoc)
        await crudService.create(inputDoc)
      }

      if (onFinish) {
        onFinish()
      }

      setOpen(false)
    } catch (error) {
      console.error('form error', error)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-3xl">
        <Form {...form}>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <FormField
                control={form.control}
                defaultValue=""
                name="code"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Code')}</FormLabel>
                    <FormControl>
                      <Input {...field} readOnly={isEdit} disabled={isEdit} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                defaultValue=""
                name="description"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Description')}</FormLabel>
                    <FormControl>
                      <Textarea className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormItem>
                <FormLabel>{t('WorkCentres')}</FormLabel>
                <FormControl>
                  <TagsSelector
                    placeholder={t('AddObject', { object: t('WorkCentre').toLocaleLowerCase() })}
                    setSelectedTags={setSelectedTags}
                    selectedTags={selectedTags}
                    availableTags={(allWorkCenters ?? [])
                      .filter(wc => wc.divisionCode !== EXTERNAL_DIVISION_CODE)
                      .map(item => ({
                        label: joinIgnoreEmpty(' - ', item.code, item.description),
                        value: item.code,
                      }))}
                    transformAfterSelect={(tag, others) =>
                      [
                        ...others,
                        {
                          label: tag.label.split(' - ')[1],
                          value: tag.value,
                        },
                      ].sort((a, b) => a.value.localeCompare(b.value))
                    }
                  />
                </FormControl>
              </FormItem>
            </div>
            <DialogFooter className="flex flex-row pt-6 justify-end">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t('Cancel')}
                </Button>
              </DialogClose>
              <Button type="submit" className="ml-2">
                {isEdit ? t('Edit') : t('Add')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
