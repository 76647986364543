import { t } from 'i18next'

export const CURRENT_FORM_ID = 'currentForm'

export const validateMinLength = (length: number, required = true) => {
  return {
    required: required ? t('MissingRequiredField') : false,
    minLength: {
      value: length,
      message: t('MinimumLengthRequired', { min: length }),
    },
  }
}

export const validateMinValue = (min: number) => {
  return {
    min: {
      value: min,
      message: t('MinimumValue', { min }),
    },
  }
}

export const validateMaxValue = (max: number) => {
  return {
    max: {
      value: max,
      message: t('MaximumValue', { max }),
    },
  }
}

export const validateRequired = () => {
  return {
    required: t('MissingRequiredField'),
  }
}
