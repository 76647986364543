import BearMetalLogo from '@/assets/img/logo_bear_metal.svg'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import { cn } from '@/lib/utils'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const MACHINE_ROOT_ITEMS = 3
const BREADCRUMB_ITEM_WIDTH = 190

type BreadcrumbNavigationBarProps = {
  translateRoute: (path: string) => string
}

export function BreadcrumbNavigationBar({ translateRoute }: BreadcrumbNavigationBarProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const subpaths = processMachineSubpath(location.pathname)
  const homeUrl = getMachineHome(location.pathname)
  const windowDimensions = useWindowDimensions()
  const breadCrumbItemsNumber = Math.trunc(windowDimensions.width / BREADCRUMB_ITEM_WIDTH) - 1
  const breadcrumbPaths = subpaths.slice(-breadCrumbItemsNumber)

  return (
    <div className="flex flex-1 border-[#767D92] border-t">
      <ul className="flex p-0 m-0">
        <BreadcrumbItem>
          <a onClick={() => navigate('/' + homeUrl)}>
            <img src={BearMetalLogo} className="h-12" alt="HOME" />
          </a>
        </BreadcrumbItem>
        <div className="flex flex-1 overflow-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          {breadcrumbPaths.map(path => (
            <BreadcrumbItem key={path} onClick={() => navigate(path)}>
              {translateRoute(path)}
            </BreadcrumbItem>
          ))}
        </div>
      </ul>
    </div>
  )
}

const BreadcrumbItem = ({ children, onClick }: { children?: ReactNode; onClick?: () => void }) => {
  return (
    <li
      className={cn(
        `w-[190px]`,
        'flex relative text-center text-[#FFFAFA] justify-center items-center pr-12 pl-4 overflow-hidden uppercase',
        'after:content-[\'""\'] after:w-16 after:h-full after:inline-block after:absolute after:bg-transparent after:top-0 after:right-0 after:rotate-45 after:translate-x-[-1rem] after:border-solid after:border-[#767D92] after:border-t after:border-r'
      )}
      onClick={onClick}
    >
      {children}
    </li>
  )
}

function getMachineHome(path: string) {
  const splittedPath = path.split('/').filter(x => x.length > 0)
  return splittedPath.slice(0, MACHINE_ROOT_ITEMS).join('/')
}

function processMachineSubpath(path: string): string[] {
  const retval: string[] = []
  let url = ''

  const navigationPath = path
    .split('/')
    .filter(x => x.length > 0)
    .slice(MACHINE_ROOT_ITEMS)

  for (const subpath of navigationPath) {
    url += subpath
    retval.push(url)
    url += '/'
  }

  return retval
}
