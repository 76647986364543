import { AuthenticationProvider } from '@/components/AuthenticationProvider.tsx'
import { ThemeProvider } from '@/components/ThemeProviders.tsx'
import { TooltipProvider } from '@/components/ui/tooltip'
import i18n from '@/i18n.ts'
import '@/index.css'
import { Settings } from 'luxon'
import ReactDOM from 'react-dom/client'
import { HotkeysProvider } from 'react-hotkeys-hook'
import { I18nextProvider } from 'react-i18next'
import { AppRouter } from './routes'

Settings.defaultLocale = 'it-IT'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <I18nextProvider i18n={i18n} defaultNS={'translation'}>
    <HotkeysProvider>
      <ThemeProvider defaultTheme="light">
        <TooltipProvider delayDuration={100} disableHoverableContent>
          <AuthenticationProvider>
            <AppRouter />
          </AuthenticationProvider>
        </TooltipProvider>
      </ThemeProvider>
    </HotkeysProvider>
  </I18nextProvider>
)
