import { DateTime } from 'luxon'

export function formatLocalDateHumanReadable(date: string | DateTime | undefined | null) {
  if (typeof date === 'string') {
    date = DateTime.fromISO(date)
  }

  if (!date || !date.isValid) {
    return '--'
  }

  return date.toLocal().toFormat('dd/MM/yyyy')
}

export function formatLocalDateTimeHumanReadable(time: string | DateTime | undefined | null) {
  if (typeof time === 'string') {
    time = DateTime.fromISO(time)
  }

  if (!time || !time.isValid) {
    return '--'
  }

  return time.toLocal().toFormat('dd/MM/yyyy HH:mm')
}

export function formatLocalTimeHumanReadable(time: string | DateTime | undefined | null) {
  if (typeof time === 'string') {
    time = DateTime.fromISO(time)
  }

  if (!time || !time.isValid) {
    return '--'
  }

  return time.toLocal().toFormat('HH:mm')
}
