import { EncodedShift } from '@/shared/dto/Shift'

const STORAGE_PREFIX = 'loom_production_reports'
const REPORT_INPUT_STORAGE_KEY = `${STORAGE_PREFIX}_report_input`
const LAST_REPORT_CODE_STORAGE_KEY = `${STORAGE_PREFIX}_report_code`

export type ReportInputData = {
  date?: string
  shiftCode?: string
  scheduleItemCode?: string
  encodedShift?: EncodedShift
  hasBreak?: boolean
}

export const storage = {
  getLastReportInput: (): ReportInputData => {
    return JSON.parse(window.localStorage.getItem(REPORT_INPUT_STORAGE_KEY) as string) ?? {}
  },
  setLastReportInput: (input: ReportInputData) => {
    window.localStorage.setItem(REPORT_INPUT_STORAGE_KEY, JSON.stringify(input))
  },
  clearLastReportInput: () => {
    window.localStorage.removeItem(REPORT_INPUT_STORAGE_KEY)
  },
  getLastEditedReportCode: (): string => {
    return JSON.parse(window.localStorage.getItem(LAST_REPORT_CODE_STORAGE_KEY) as string) ?? ''
  },
  setLastEditedReportCode: (input: string) => {
    window.localStorage.setItem(LAST_REPORT_CODE_STORAGE_KEY, JSON.stringify(input))
  },
  clearLastEditedReportCode: () => {
    window.localStorage.removeItem(LAST_REPORT_CODE_STORAGE_KEY)
  },
}
