import { groupBy } from '@/lib/utils'
import { Couchdb, CouchdbDoc } from '@iotinga/ts-backpack-couchdb-client'
import {
  PRODUCTION_DB_DESIGN_DOC_NAME,
  REPORT_PARTS_BY_SHIFT_VIEW_NAME,
  REPORT_PARTS_BY_SOURCE_VIEW_NAME,
} from '../db/production'
import { DocumentType } from '../dto/BaseDocument'
import { Batch } from '../dto/Batch'
import { Discards, Production, Reworks } from '../dto/Quantity'
import { Report, ReportDataSource, ReportState } from '../dto/Report'
import { EncodedShift } from '../dto/Shift'
import { Stop } from '../dto/Stop'
import { Test } from '../dto/Test'

type ReportDocument = Report | Stop | Test | Production | Reworks | Discards | Batch

export type QuantityFull = {
  production: Production
  discards: Discards[]
  reworks: Reworks[]
}

export type ReportFull = {
  header: Report
  quantities: QuantityFull[]
  tests: Test[]
  stops: Record<string, Stop[]>
  batches: Record<string, Batch>
}

export class FullReportCrudService {
  constructor(protected readonly db: Couchdb) {}

  private async getReportDocsByCode(code: string): Promise<Array<CouchdbDoc & ReportDocument>> {
    const reportDocs = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view<unknown[], CouchdbDoc & ReportDocument>(REPORT_PARTS_BY_SOURCE_VIEW_NAME, {
        start_key: [code],
        end_key: [code, {}],
        include_docs: true,
        reduce: false,
      })

    return reportDocs.rows.filter(r => r.doc).map(r => r.doc as ReportDocument)
  }

  private async getReportDocsByShift(
    shift: EncodedShift,
    state: ReportState = ReportState.COMPLETE,
    docType?: DocumentType
  ) {
    const reportDocs = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view<unknown[], CouchdbDoc & ReportDocument>(REPORT_PARTS_BY_SHIFT_VIEW_NAME, {
        start_key: [shift, state],
        end_key: [shift, state, docType ?? {}],
        include_docs: true,
        reduce: false,
      })

    return reportDocs.rows.filter(r => r.doc).map(r => r.doc as ReportDocument)
  }

  private normalizeReportDocuments(docs: ReportDocument[], reportCode: string): ReportDocument[] {
    for (const doc of docs) {
      if (doc.type === 'REPORT') {
        doc.state = ReportState.COMPLETE
      }

      if (doc.type !== 'REPORT' && doc.type !== 'BATCH') {
        // add reference report code to each report document
        doc.reportCode = reportCode
        // add complete state to each docs
        doc.state = ReportState.COMPLETE
      }
    }

    return docs
  }

  private async getFullReportFromReportDocs(docs: ReportDocument[]): Promise<ReportFull> {
    const report: ReportFull = {
      header: {} as Report,
      quantities: [],
      tests: [],
      stops: {},
      batches: {},
    }

    const flatQuantities: Array<Production | Reworks | Discards> = []
    const flatStops: Stop[] = []

    for (const doc of docs) {
      // select doc type
      switch (doc.type) {
        case DocumentType.REPORT:
          report.header = doc
          break
        case DocumentType.STOP:
          flatStops.push(doc)
          // report.stops.push(doc)
          break
        case DocumentType.TEST:
          report.tests.push(doc)
          break
        case DocumentType.BATCH:
          report.batches[doc.code] = doc
          break
        case DocumentType.PRODUCTION:
        case DocumentType.REWORK:
        case DocumentType.DISCARD:
          flatQuantities.push(doc)
          break
      }
    }

    // group quantities by batch
    const quantitiesGroupByBatch = groupBy(flatQuantities, q => q.batchCode)
    for (const [, quantities] of quantitiesGroupByBatch) {
      const production: Production[] = []
      const discards: Discards[] = []
      const reworks: Reworks[] = []

      for (const doc of quantities) {
        switch (doc.type) {
          case DocumentType.PRODUCTION:
            production.push(doc)
            break
          case DocumentType.DISCARD:
            discards.push(doc as Discards)
            break
          case DocumentType.REWORK:
            reworks.push(doc as Reworks)
            break
        }
      }

      report.quantities.push({
        production: production[0],
        discards,
        reworks,
      })
    }

    // group stops by stop reason code
    const stopsGroupByReasonCode = groupBy(flatStops, s => s.reason.code)
    for (const [reasonCode, stops] of stopsGroupByReasonCode) {
      report.stops[reasonCode] = stops
    }

    return report
  }

  private getReportDocsFromFullReport(report: ReportFull): ReportDocument[] {
    const docs: ReportDocument[] = [report.header]

    for (const quantity of report.quantities) {
      docs.push(quantity.production)
      docs.push(...quantity.reworks)
      docs.push(...quantity.discards)
    }

    docs.push(...report.tests)

    for (const stopReason in report.stops) {
      const stopGroup = report.stops[stopReason]
      docs.push(...stopGroup)
    }

    docs.push(...Object.values(report.batches))
    return docs
  }

  async readByShift(
    shift: EncodedShift,
    state: ReportState = ReportState.COMPLETE,
    docType?: DocumentType
  ): Promise<Partial<ReportFull>> {
    const reportDocs = await this.getReportDocsByShift(shift, state, docType)
    return await this.getFullReportFromReportDocs(reportDocs)
  }

  async read(code: string): Promise<ReportFull> {
    const reportDocs = await this.getReportDocsByCode(code)
    return await this.getFullReportFromReportDocs(reportDocs)
  }

  async write(report: ReportFull): Promise<boolean> {
    const reportDocs = this.getReportDocsFromFullReport(report)
    const docsToStore = this.normalizeReportDocuments(reportDocs, report.header.code)

    const result = await this.db.bulkDocs({ docs: docsToStore })
    return result.every(response => 'ok' in response)
  }

  async update(report: ReportFull): Promise<boolean> {
    const oldReportDocs = await this.getReportDocsByCode(report.header.code)
    const newReportDocs = this.normalizeReportDocuments(this.getReportDocsFromFullReport(report), report.header.code)

    const docsToDelete: ReportDocument[] = []
    for (const oldDoc of oldReportDocs) {
      if (!newReportDocs.some(doc => doc.code === oldDoc.code)) {
        oldDoc._deleted = true
        docsToDelete.push(oldDoc)
      }
    }

    const docsToStore: CouchdbDoc[] = [...newReportDocs, ...docsToDelete]

    const result = await this.db.bulkDocs({ docs: docsToStore })

    return result.every(response => 'ok' in response)
  }

  async delete(code: string): Promise<boolean> {
    const docsToDelete = await this.getReportDocsByCode(code)

    for (const doc of docsToDelete) {
      // Skip deleting documents imported from SIPRO. This allows deleting a report in the editor
      // and creating a new one will import the data again instead of deleting it
      if ('source' in doc && doc.source === ReportDataSource.SIPRO) {
        doc.state = ReportState.DRAFT
        continue
      }

      // set doc state to deleted
      if ('state' in doc) {
        doc.state = ReportState.DELETED
      }
    }

    // update docs in bulk
    const result = await this.db.bulkDocs({ docs: docsToDelete })

    return result.every(response => 'ok' in response)
  }
}
