import { cn } from '@/lib/utils'

export type StatusBarConfig = Array<{ title: string; value: string }>

type StatusBarProps = {
  config?: StatusBarConfig
}

export const StatusBar = ({ config }: StatusBarProps) => {
  return (
    <div className="flex flex-1 items-center justify-between px-4 border-[#767D92] border-b">
      {config?.map((e, i) => (
        <div
          key={i}
          className={cn('flex flex-col flex-1 text-center h-full pt-1', i != 0 ? 'border-[#767D92] border-l' : '')}
        >
          <div className="flex flex-1 flex-col justify-center text-center text-lg text-[#FFFAFA]">
            <span className="flex-1 font-semibold uppercase">{e.value}</span>
            <span className="flex-1 font-light tracking-wide uppercase">{e.title}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
