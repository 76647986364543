import { ButtonGroup } from '@/components/ButtonGroup'
import { ButtonWithHotkey } from '@/components/ButtonWithHotkey'
import { DeleteDialog } from '@/components/DeleteDialog'
import { Remounter } from '@/components/Remounter'
import { ReportAccordion } from '@/components/ReportAccordion'
import { ContentLayout } from '@/components/layouts/ContentLayout'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { calculateShiftTime, calculateTotalProductionDuration, calculateTotalStopDuration } from '@/lib/calculation'
import {
  DISCARD_SCHEMA,
  PRODUCTION_SCHEMA,
  REPORT_HEADER_SCHEMA,
  REPORT_HEADER_SCHEMA_WARNINGS,
  REWORK_SCHEMA,
  STOP_SCHEMA,
  TEST_SCHEMA,
  schemaForType,
} from '@/lib/schema'
import { ReportInputData, storage } from '@/lib/storage'
import { getDocumentId } from '@/lib/utils'
import { ROUTE } from '@/routes'
import { FullReportCrudService, QuantityFull } from '@/shared/crud/FullReportCrudService'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import {
  ProductWithProcessingDetails,
  StopReasonWithDuration,
  WorkCenterViewServices,
} from '@/shared/crud/WorkCenterViewServices'
import { CouchdbQueryBuilderImpl } from '@/shared/db/CouchdbQueryBuilder'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { BaseDocument, DocumentType } from '@/shared/dto/BaseDocument'
import { Batch } from '@/shared/dto/Batch'
import { DiscardReason, ReworkReason } from '@/shared/dto/Reason'
import { Report, ReportDataSource, ReportItem, ReportState } from '@/shared/dto/Report'
import { ScheduleItem } from '@/shared/dto/ScheduleItem'
import { Stop } from '@/shared/dto/Stop'
import { Test } from '@/shared/dto/Test'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { UserRole, hasRole } from '@/shared/utils/auth'
import { decodeShift } from '@/shared/utils/shift'
import {
  IconClockPlus,
  IconCopyPlus,
  IconCubePlus,
  IconDeviceFloppy,
  IconEye,
  IconTemperaturePlus,
  IconX,
} from '@tabler/icons-react'
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  Outlet,
  generatePath,
  matchPath,
  useBeforeUnload,
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { ZodFormattedError, z } from 'zod'
import { ReportNewModal } from './ReportNewModal'

export type NewReportOutletContext = {
  isEdit: boolean
  editorState: ReportEditorState
  validationErrors: ZodFormattedError<Report> | undefined
  validationWarnings: ZodFormattedError<Report> | undefined
  setEditorState: Dispatch<SetStateAction<ReportEditorState>>
  availableProducts: ProductWithProcessingDetails[]
  availableReworkReasons: ReworkReason[]
  availableDiscardReasons: DiscardReason[]
  availableStopReasons: StopReasonWithDuration[]
  parentWorkCenters: WorkCenter[]
}

export type ReportEditorState = {
  header: Report
  quantities: QuantityFull[]
  stops: Record<string, Stop[]>
  tests: Test[]
  batches: Record<string, Batch>
  scheduleItem: ScheduleItem | undefined
}

const QUANTITY_FULL_SCHEMA = schemaForType<QuantityFull>()(
  z.object({
    production: PRODUCTION_SCHEMA,
    discards: z.array(DISCARD_SCHEMA),
    reworks: z.array(REWORK_SCHEMA),
  })
)

const EDITOR_STATE_SCHEMA = z.object({
  header: REPORT_HEADER_SCHEMA,
  stops: z.record(z.string(), z.array(STOP_SCHEMA)),
  quantities: z.array(QUANTITY_FULL_SCHEMA),
  tests: z.array(TEST_SCHEMA),
  batches: z.record(z.string(), z.any()),
  scheduleItem: z.object({}).optional(),
})

const EDITOR_STATE_SCHEMA_WARNINGS = z.object({
  header: REPORT_HEADER_SCHEMA_WARNINGS,
})

export function EditReportLayout() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id: reportCode } = useParams()
  const { currentUser } = useAuthentication()

  const isEdit = reportCode !== undefined && reportCode.length > 0

  const db = useCouchdb(PRODUCTION_DB_NAME)
  const reportCrudService = useMemo(() => new FullReportCrudService(db), [db])
  const simpleCrudService = useMemo(() => new SimpleCouchCrudService(db), [db])

  if (!hasRole(currentUser, [UserRole.ADMIN, UserRole.REPORTS_REGISTRY, UserRole.REPORTS_WRITE])) {
    navigate(ROUTE.reports)
  }

  // global editor state
  const [editorState, setEditorState] = useState<ReportEditorState>({
    header: getBaseReportHeader(currentUser.username),
    quantities: [],
    stops: {},
    tests: [],
    batches: {},
    scheduleItem: undefined,
  })

  const [createModalVisible, setCreateModalVisible] = useState(false)

  const [validationErrors, setValidationErrors] = useState<ZodFormattedError<Report>>()
  const [validationWarnings, setValidationWarnings] = useState<ZodFormattedError<Report>>()
  const [availableProducts, setAvailableProducts] = useState<ProductWithProcessingDetails[]>([])
  const [availableReworkReasons, setAvailableReworkReasons] = useState<ReworkReason[]>([])
  const [availableDiscardReasons, setAvailableDiscardReasons] = useState<DiscardReason[]>([])
  const [availableStopReasons, setAvailableStopReasons] = useState<StopReasonWithDuration[]>([])
  const [parentWorkCenters, setParentWorkCenters] = useState<WorkCenter[]>([])

  useEffect(() => {
    const fetchSchedItems = async (workCenterCode: string) => {
      const query = new CouchdbQueryBuilderImpl()
        .where('type', '=', DocumentType.SCHEDULE_ITEM)
        .where('workCenterCode', '=', workCenterCode)

      const response = await simpleCrudService.query(query.build())
      return response.items
    }

    const fetchStoredReport = async (reportCode: string) => {
      const report = await reportCrudService.read(reportCode)
      const scheduleItems = (await fetchSchedItems(report.header.decodedShift.workCenter)) as ScheduleItem[]
      const scheduleItem = scheduleItems.find(item => item.shiftCode === report.header.decodedShift.code)
      setEditorState({
        header: report.header,
        quantities: report.quantities,
        stops: report.stops,
        tests: report.tests,
        batches: report.batches,
        scheduleItem: scheduleItem,
      })
    }
    if (isEdit && reportCode && reportCode.length > 0) {
      fetchStoredReport(reportCode)
    }
  }, [isEdit, reportCode, reportCrudService, simpleCrudService])

  useEffect(() => {
    // when work center change set available registry codes
    const workCenterCode = editorState.header.workCenter.code
    if (workCenterCode && workCenterCode.length > 0) {
      const workCentreViewService = new WorkCenterViewServices(db, workCenterCode)
      workCentreViewService.getAvailableProducts().then(setAvailableProducts)
      workCentreViewService.getAvailableReworkReason().then(setAvailableReworkReasons)
      workCentreViewService.getAvailableDiscardReason().then(setAvailableDiscardReasons)
      workCentreViewService.getAvailableStopReason().then(setAvailableStopReasons)
      workCentreViewService.getParentWorkCenters().then(setParentWorkCenters)
    }
  }, [db, editorState.header.workCenter.code])

  useEffect(() => {
    const errors = EDITOR_STATE_SCHEMA.safeParse(editorState)
    if (!errors.success) {
      setValidationErrors(errors.error.format())
    } else {
      setValidationErrors(undefined)
    }

    const warnings = EDITOR_STATE_SCHEMA_WARNINGS.safeParse(editorState)
    if (!warnings.success) {
      setValidationWarnings(warnings.error.format())
    } else {
      setValidationWarnings(undefined)
    }
  }, [editorState])

  useEffect(() => {
    setEditorState(old => ({
      ...old,
      header: {
        ...old.header,
        totalShiftDuration: old.scheduleItem ? calculateShiftTime(old.header, old.scheduleItem) : 0,
      },
    }))
  }, [
    editorState.header.totalProductionDuration,
    editorState.header.totalStopDuration,
    editorState.header.breakDuration,
    editorState.scheduleItem,
  ])

  const beforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = t('ConfirmDeleteBody')
      return t('ConfirmDeleteBody')
    },
    [t]
  )
  useBeforeUnload(beforeUnload)

  const EDITOR_ROOT_PATHS = [ROUTE.reportCreate, ROUTE.reportEdit]
  const blocker = useBlocker(({ nextLocation }) => {
    // Allows bypassing the blocker when using navigate(PATH, { state: { bypassBlocker: true } })
    if (nextLocation.state !== null && nextLocation.state.bypassBlocker === true) {
      return false
    }

    return !EDITOR_ROOT_PATHS.some(root => matchPath({ path: root, end: false }, nextLocation.pathname))
  })

  useEffect(() => {
    if (!isEdit) {
      onStartNewReport(storage.getLastReportInput())
    }
  }, [])

  const onStartNewReport = async (inputData: ReportInputData) => {
    try {
      const { scheduleItemCode, encodedShift, hasBreak } = inputData
      const decodedShift = decodeShift(encodedShift!)

      if (!decodedShift || !scheduleItemCode || !encodedShift) {
        throw new Error('Invalid input data')
      }

      const workCenter = (await simpleCrudService.read(
        getDocumentId(DocumentType.WORK_CENTER, decodedShift.workCenter)
      )) as WorkCenter | null

      const scheduleItem = (await simpleCrudService.read(
        getDocumentId(DocumentType.SCHEDULE_ITEM, scheduleItemCode)
      )) as ScheduleItem | null

      if (!workCenter || !scheduleItem) {
        throw new Error('Schedule item or work center not found')
      }

      // check for report documents in draft for selected shift
      const reportCrudService = new FullReportCrudService(db)
      const partialReport = await reportCrudService.readByShift(encodedShift, ReportState.DRAFT)

      // update reportCode
      const currentReportCode = editorState.header.code
      for (const quantity of partialReport.quantities ?? []) {
        quantity.production.reportCode = currentReportCode
        quantity.discards = quantity.discards.map(discard => ({ ...discard, reportCode: currentReportCode }))
        quantity.reworks = quantity.reworks.map(rework => ({ ...rework, reportCode: currentReportCode }))
      }

      for (const test of partialReport.tests ?? []) {
        test.reportCode = currentReportCode
      }

      for (const stopReasonCode in partialReport.stops) {
        partialReport.stops[stopReasonCode] = partialReport.stops[stopReasonCode].map(stop => ({
          ...stop,
          reportCode: currentReportCode,
        }))
      }

      const totalProductionDuration = calculateTotalProductionDuration(partialReport.quantities ?? []) ?? 0
      const totalStopDuration = calculateTotalStopDuration(partialReport.stops ?? {}) ?? 0

      const header: Report = {
        ...getBaseReportHeader(currentUser.username),
        shift: encodedShift,
        decodedShift: decodedShift,
        workCenter: workCenter,
        breakDuration: hasBreak === false ? 0 : scheduleItem.breakDuration,
        shiftChangeDuration: scheduleItem.shiftChangeDuration,
        totalProductionDuration: totalProductionDuration,
        totalStopDuration: totalStopDuration,
      }

      setEditorState(old => ({
        ...old,
        ...partialReport,
        header,
        scheduleItem,
      }))
    } catch (error) {
      console.error('Error in init new report', error)
      navigate(ROUTE.reports)
    }
  }

  const [saveConfirmState, setSaveConfirmState] = useState<{
    open: boolean
    onConfirm: () => void
  }>({
    open: false,
    onConfirm: () => {},
  })

  const onSave = async () => {
    const reportCrudService = new FullReportCrudService(db)

    let response = undefined
    if (isEdit) {
      response = await reportCrudService.update(editorState)
    } else {
      response = await reportCrudService.write(editorState)
    }

    // save last edited report in local storage
    storage.setLastEditedReportCode(editorState.header.code)

    if (!response) {
      throw new Error(`Error inserting document`)
    }
  }

  const addQuantity = () => {
    // check if there is already an incomplete quanity
    const incompleteBatch = Object.values(editorState.batches).find(
      batch => !batch.product.code || batch.product.code.length === 0
    )

    if (incompleteBatch === undefined) {
      // add new batch
      const newBatch = getBaseBatchDocument(currentUser.username)
      const newBatches = cloneDeep(editorState.batches)
      newBatches[newBatch.code] = newBatch

      // add new quantity
      const newQuantities = cloneDeep(editorState.quantities)
      newQuantities.push({
        production: {
          ...getBaseReportItemDocument(DocumentType.PRODUCTION, editorState.header, currentUser.username),
          batchCode: newBatch.code,
          quantity: 0,
          elements: 0,
          machineCycleDuration: 0,
          productionDuration: 0,
        },
        discards: [],
        reworks: [],
      })

      setEditorState(old => ({
        ...old,
        quantities: newQuantities,
        batches: newBatches,
      }))
      navigate(
        isEdit
          ? generatePath(ROUTE.reportEditQuantity, { index: newBatch.code, id: reportCode })
          : generatePath(ROUTE.reportCreateQuantity, { index: newBatch.code })
      )
    } else {
      navigate(
        isEdit
          ? generatePath(ROUTE.reportEditQuantity, { index: incompleteBatch.code, id: reportCode })
          : generatePath(ROUTE.reportCreateQuantity, { index: incompleteBatch.code })
      )
    }
  }

  const addStop = () => {
    const newStop: Stop = {
      ...getBaseReportItemDocument(DocumentType.STOP, editorState.header, currentUser.username),
      reason: {
        _id: '',
        code: '',
        createdAt: '',
        createdBy: '',
        updatedAt: '',
        description: '',
        type: DocumentType.STOP_REASON,
      },
      stopDuration: 0,
      start: null,
      end: null,
    }

    setEditorState(old => ({
      ...old,
      stops: {
        ...old.stops,
        '': [newStop],
      },
    }))

    navigate(
      isEdit
        ? generatePath(ROUTE.reportEditStops, { index: newStop.reason.code, id: reportCode })
        : generatePath(ROUTE.reportCreateStops, { index: newStop.reason.code })
    )
  }

  const addTest = () => {
    // check if there is already an incomplete test
    const hasIncompleteTest = editorState.tests.some(test => !test.batchCode || test.batchCode.length === 0)

    if (!hasIncompleteTest) {
      // use the report start date as performedAt
      const testPerformedAt = editorState.header.decodedShift.start

      const newTests = cloneDeep(editorState.tests)
      newTests.push({
        ...getBaseReportItemDocument(DocumentType.TEST, editorState.header, currentUser.username),
        batchCode: '',
        quantity: 0,
        performedAt: testPerformedAt,
        measures: [],
      })

      setEditorState(old => ({
        ...old,
        tests: newTests,
      }))
    }

    navigate(
      generatePath(isEdit ? ROUTE.reportEditTests : ROUTE.reportCreateTests, { id: reportCode || null, index: null })
    )
  }

  const anotherReport = () => setCreateModalVisible(true)
  const exit = (bypassBlocker: boolean = false) => {
    navigate(ROUTE.reports, {
      state: {
        bypassBlocker,
      },
    })
  }

  return (
    <ContentLayout scrollable={false} hideHeader>
      <DeleteDialog open={blocker.state === 'blocked'}>
        <DeleteDialog.Content
          title={t('ConfirmExit')}
          description={t('ConfirmExitBody')}
          onConfirm={() => blocker.proceed?.()}
          onCancel={() => blocker.reset?.()}
        ></DeleteDialog.Content>
      </DeleteDialog>
      <Dialog
        open={saveConfirmState.open}
        onOpenChange={open =>
          setSaveConfirmState(old => ({
            ...old,
            open,
          }))
        }
      >
        <DialogContent>
          <DialogHeader className="space-y-2">
            <DialogTitle>{t('ConfirmSave')}</DialogTitle>
            <DialogDescription>{t('ConfirmSaveBody')}</DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-row pt-6 justify-end">
            <DialogClose asChild>
              <Button type="button" variant="outline">
                {t('Cancel')}
              </Button>
            </DialogClose>
            <Button type="submit" className="ml-2" onClick={saveConfirmState.onConfirm}>
              {t('Confirm')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ReportNewModal
        open={createModalVisible}
        setOpen={setCreateModalVisible}
        onConfirm={() => onStartNewReport(storage.getLastReportInput())}
      />
      <ResizablePanelGroup
        direction="horizontal"
        autoSaveId="loom-production-reports-editor"
        className="h-full items-stretch"
      >
        <ResizablePanel defaultSize={35} minSize={35} maxSize={50}>
          <ContentLayout.Header
            leftActions={
              <ButtonGroup>
                <ButtonGroup.ButtonWithHotkey
                  hotkey="alt+1"
                  enableOnFormTags
                  onClick={addQuantity}
                  tooltip={t('AddObject', { object: t('Quantity').toLocaleLowerCase() })}
                >
                  <IconCubePlus />
                </ButtonGroup.ButtonWithHotkey>
                <ButtonGroup.ButtonWithHotkey
                  hotkey="alt+2"
                  enableOnFormTags
                  onClick={addStop}
                  tooltip={t('AddObject', { object: t('Stop').toLocaleLowerCase() })}
                >
                  <IconClockPlus />
                </ButtonGroup.ButtonWithHotkey>
                <ButtonGroup.ButtonWithHotkey
                  hotkey="alt+3"
                  enableOnFormTags
                  onClick={addTest}
                  tooltip={t('AddObject', { object: t('Test').toLocaleLowerCase() })}
                >
                  <IconTemperaturePlus />
                </ButtonGroup.ButtonWithHotkey>
              </ButtonGroup>
            }
            rightActions={
              <div className="flex flex-row gap-2">
                <ButtonWithHotkey
                  hotkey="ctrl+p"
                  enableOnFormTags
                  variant="outline"
                  onClick={() =>
                    navigate(
                      isEdit ? generatePath(ROUTE.reportEditSummary, { id: reportCode }) : ROUTE.reportCreateSummary
                    )
                  }
                  tooltip={t('Preview')}
                >
                  <IconEye />
                </ButtonWithHotkey>
                <ButtonGroup>
                  <ButtonGroup.ButtonWithHotkey hotkey="esc" onClick={() => exit()} tooltip={t('Cancel')}>
                    <IconX />
                  </ButtonGroup.ButtonWithHotkey>
                  <ButtonGroup.ButtonWithHotkey
                    hotkey="ctrl+shift+s"
                    onClick={() => {
                      if (validationWarnings !== undefined) {
                        setSaveConfirmState({
                          open: true,
                          onConfirm: anotherReport,
                        })
                      } else {
                        onSave().then(anotherReport)
                      }
                    }}
                    tooltip={t('SaveAndAddAnother')}
                    disabled={validationErrors !== undefined}
                  >
                    <IconCopyPlus />
                  </ButtonGroup.ButtonWithHotkey>
                  <ButtonGroup.ButtonWithHotkey
                    hotkey="ctrl+s"
                    onClick={() => {
                      const exitBypass = () => exit(true)

                      if (validationWarnings !== undefined) {
                        setSaveConfirmState({
                          open: true,
                          onConfirm: exitBypass,
                        })
                      } else {
                        onSave().then(exitBypass)
                      }
                    }}
                    tooltip={t('Save')}
                    disabled={validationErrors !== undefined}
                  >
                    <IconDeviceFloppy />
                  </ButtonGroup.ButtonWithHotkey>
                </ButtonGroup>
              </div>
            }
          />

          <ScrollArea className="h-screen overflow-auto border-0">
            <div className="bg-secondary">
              <Link
                to={isEdit ? generatePath(ROUTE.reportEdit, { id: reportCode }) : ROUTE.reportCreate}
                className="m-4"
              >
                <ReportHeaderTable header={editorState.header} />
              </Link>
            </div>
            <Separator />

            <ReportAccordion reportCode={reportCode} editorState={editorState} setEditorState={setEditorState} />
          </ScrollArea>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel>
          <ScrollArea className="h-screen overflow-auto border-0">
            <Remounter>
              <Outlet
                context={
                  {
                    isEdit,
                    editorState,
                    validationErrors,
                    validationWarnings,
                    setEditorState,
                    availableProducts,
                    availableReworkReasons,
                    availableDiscardReasons,
                    availableStopReasons,
                    parentWorkCenters,
                  } as NewReportOutletContext
                }
              />
            </Remounter>
          </ScrollArea>
        </ResizablePanel>
      </ResizablePanelGroup>
    </ContentLayout>
  )
}

export const ReportHeaderTable = ({ header }: { header: Report }) => {
  const { t } = useTranslation()

  return (
    <Table>
      <TableHeader>
        <TableRow className="[&_th]:text-primary [&_th]:fade-clamp">
          <TableHead>{t('Date')}</TableHead>
          <TableHead>{t('Shift')}</TableHead>
          <TableHead>{t('WorkCentre')}</TableHead>
          <TableHead>{t('WorkerId')}</TableHead>
          <TableHead>{t('BreakTime')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{DateTime.fromISO(header.decodedShift.start).toFormat('dd/MM/yyyy')}</TableCell>
          <TableCell>{header.decodedShift.code}</TableCell>
          <TableCell>{header.decodedShift.workCenter}</TableCell>
          <TableCell>{header.workerCode}</TableCell>
          <TableCell>{header.breakDuration > 0 ? t('Yes') : t('No')}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function getBaseReportHeader(createdBy: string): Report {
  const timestamp = DateTime.now().toUTC().toISO()
  const code = uuid()

  return {
    _id: getDocumentId(DocumentType.REPORT, code),
    type: DocumentType.REPORT,
    code,
    shift: 'SHIFT____',
    decodedShift: {
      workCenter: '',
      code: '',
      start: '',
      end: '',
    },
    workerCode: '',
    workCenter: {} as WorkCenter,
    breakDuration: 0,
    shiftChangeDuration: 0,
    totalProductionDuration: 0,
    totalStopDuration: 0,
    totalShiftDuration: 0,
    notes: '',
    state: ReportState.COMPLETE,
    createdAt: timestamp,
    updatedAt: timestamp,
    createdBy,
  }
}

export function getBaseReportItemDocument<T extends DocumentType>(
  type: T,
  header: Report,
  createdBy: string
): BaseDocument<T> & ReportItem {
  const code = uuid()
  const timestamp = DateTime.now().toUTC().toISO()
  return {
    _id: getDocumentId(type, code),
    type: type,
    code: code,
    state: ReportState.COMPLETE,
    source: ReportDataSource.EDITOR,
    reportCode: header.code,
    shift: header.shift,
    createdAt: timestamp,
    updatedAt: timestamp,
    createdBy,
  }
}

export function getBaseBatchDocument(createdBy: string): Batch {
  const code = uuid()
  const timestamp = DateTime.now().toUTC().toISO()
  return {
    _id: getDocumentId(DocumentType.BATCH, code),
    type: DocumentType.BATCH,
    code: code,
    parentWorkCenterCode: undefined,
    product: {
      _id: getDocumentId(DocumentType.PRODUCT, ''),
      type: DocumentType.PRODUCT,
      code: '',
      description: '',
      createdAt: timestamp,
      updatedAt: timestamp,
      createdBy,
    },
    createdAt: timestamp,
    updatedAt: timestamp,
    createdBy,
  }
}
