import { useUnzoomablePage } from '@/hooks/useUnzoomablePage'
import { PropsWithChildren, useEffect, useState } from 'react'

type FullScreenLayoutProps = PropsWithChildren<{}>

export function FullScreenLayout({ children }: FullScreenLayoutProps) {
  useUnzoomablePage()

  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    document.onfullscreenchange = () => {
      setFullScreen(curr => !curr)
    }

    return () => {
      document.onfullscreenchange = null
    }
  }, [])

  const enableFullScreen = () => {
    const doc: any = document.documentElement
    if (doc.requestFullscreen) {
      doc.requestFullscreen()
    } else if (doc.webkitRequestFullscreen) {
      /* Safari */
      doc.webkitRequestFullscreen()
    } else if (doc.msRequestFullscreen) {
      /* IE11 */
      doc.msRequestFullscreen()
    }
  }

  return (
    <div
      className="flex w-screen h-screen"
      onClick={e => {
        if (!fullScreen) {
          enableFullScreen()
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      {children}
    </div>
  )
}
