import { MachineContentLayout } from '@/components/layouts/MachineContentLayout'
import { MachineButton } from '@/components/machineOptimized/Buttons'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Params, useNavigate, useParams } from 'react-router-dom'

type FormTimeRangeScreenProps = {
  getValue: (params: Readonly<Params>) => { start: string; end: string } | undefined
  onValueChange: ({ start, end }: { start: string; end: string }, params: Readonly<Params>) => void
}

export function FormTimeRangeScreen({ getValue, onValueChange }: FormTimeRangeScreenProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const form = useForm<{ start: string; end: string }>({
    values: {
      start: getValue(params)?.start ?? '',
      end: getValue(params)?.end ?? '',
    },
  })

  const onSave = () => {
    const start = form.getValues('start')
    const end = form.getValues('end')
    onValueChange({ start, end }, params)
    navigate(-1)
  }

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <MachineContentLayout
      actionBarConfig={[
        <MachineButton variant="outline" label={t('Cancel')} onClick={onCancel} />,
        <MachineButton variant="success" label={t('Confirm')} onClick={onSave} />,
      ]}
    >
      <div className="flex flex-col gap-8 flex-1 justify-center items-center">
        <Form {...form}>
          <FormField
            control={form.control}
            name="start"
            render={({ field }) => (
              <FormItem className="mt-2 w-2/3">
                <FormLabel className="text-3xl text-bold uppercase">{t('StartTime')}</FormLabel>
                <FormControl>
                  <Input className="text-xl h-12 bg-[#20242A]" {...field} type="time" />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="end"
            render={({ field }) => (
              <FormItem className="mt-2 w-2/3">
                <FormLabel className="text-3xl text-bold uppercase">{t('EndTime')}</FormLabel>
                <FormControl>
                  <Input className="text-xl h-12 bg-[#20242A]" {...field} type="time" />
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
      </div>
    </MachineContentLayout>
  )
}
