import { QuantityFull } from '@/shared/crud/FullReportCrudService'
import { Report } from '@/shared/dto/Report'
import { ScheduleItem } from '@/shared/dto/ScheduleItem'
import { Stop } from '@/shared/dto/Stop'

export function calculateTotalStopDuration(stops: Record<string, Stop[]>) {
  return Object.values(stops).reduce(
    (stopTot, stopGroup) => stopTot + stopGroup.reduce((total, stop) => total + stop.stopDuration, 0),
    0
  )
}

export function calculateTotalProductionDuration(quantities: QuantityFull[]) {
  return quantities.reduce(
    (prodTotal, qty) =>
      prodTotal +
      qty.production.productionDuration +
      qty.reworks.reduce((total, rew) => total + rew.productionDuration, 0) +
      qty.discards.reduce((total, dis) => total + dis.productionDuration, 0),
    0
  )
}

/**
 * Calculates the shift time in seconds for the given report
 * @param report The report to use for calculations
 * @param scheduleItem The correct schedule item associated to the report
 * @returns The number of seconds of duration of all shift operations
 */
export function calculateShiftTime(report: Report, scheduleItem: ScheduleItem) {
  return (
    report.totalProductionDuration + report.totalStopDuration + report.breakDuration + scheduleItem.shiftChangeDuration
  )
}
