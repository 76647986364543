import { ComboSelect } from '@/components/ComboSelect'
import { Tag, TagsSelector } from '@/components/TagsSelector'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { useAuthentication } from '@/hooks/useAuthentication'
import { useCouchdb } from '@/hooks/useCouchdb'
import { joinIgnoreEmpty } from '@/lib/localize'
import { getDocumentId } from '@/lib/utils'
import { SimpleCouchCrudService } from '@/shared/crud/SimpleCouchCrudService'
import { PRODUCTION_DB_NAME } from '@/shared/db/production'
import { DocumentType } from '@/shared/dto/BaseDocument'
import { Division } from '@/shared/dto/Division'
import { WorkCenter } from '@/shared/dto/WorkCenter'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'

type WorkCenterFormProps = {
  value?: WorkCenter
  onFinish?: () => void
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  divisions: Division[]
  workCenters: WorkCenter[]
}

export function WorkCenterForm({ value, onFinish, open, setOpen, divisions, workCenters }: WorkCenterFormProps) {
  const isEdit = value !== undefined

  const { currentUser } = useAuthentication()
  const db = useCouchdb(PRODUCTION_DB_NAME)
  const crudService = new SimpleCouchCrudService<WorkCenter>(db)

  const form = useForm<WorkCenter>({
    values: value,
    reValidateMode: 'onSubmit',
  })

  const workCentersByCode = new Map<string, WorkCenter>()
  workCenters.forEach(wc => workCentersByCode.set(wc.code, wc))

  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    (value?.parentsCodes || []).map(code => ({
      value: code,
      label: workCentersByCode.get(code)?.description ?? code,
    }))
  )

  const title = t(isEdit ? 'EditObject' : 'AddObject', { object: t('WorkCentre').toLocaleLowerCase() })

  const onSubmit = async (data: WorkCenter) => {
    try {
      const timestamp = DateTime.now().toUTC().toISO()
      const inputDoc: WorkCenter = {
        ...value,
        ...data,
        type: DocumentType.WORK_CENTER,
        updatedAt: timestamp,
        parentsCodes: selectedTags.map(tag => tag.value),
      }

      if (isEdit) {
        await crudService.update(inputDoc)
      } else {
        inputDoc._id = getDocumentId(inputDoc.type, inputDoc.code)
        inputDoc.createdAt = timestamp
        inputDoc.createdBy = currentUser.username
        await crudService.create(inputDoc)
      }

      if (onFinish) {
        onFinish()
      }

      setOpen(false)
    } catch (error) {
      console.error('form error', error)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle> {title} </DialogTitle>
          </DialogHeader>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <FormField
                control={form.control}
                defaultValue=""
                name="code"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Code')}</FormLabel>
                    <FormControl>
                      <Input {...field} readOnly={isEdit} disabled={isEdit} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                defaultValue=""
                name="description"
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Description')}</FormLabel>
                    <FormControl>
                      <Textarea className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={'divisionCode'}
                defaultValue={undefined}
                rules={{ required: t('MissingRequiredField') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('Division')}</FormLabel>
                    <FormControl>
                      <ComboSelect
                        items={divisions.map(item => ({
                          value: item.code,
                          label: joinIgnoreEmpty(' - ', item.code, item.description),
                        }))}
                        label={t('Division')}
                        value={field.value}
                        onSelect={item => field.onChange(item.value)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormItem>
                <FormLabel>{t('ParentWorkCentre')}</FormLabel>
                <FormControl>
                  <TagsSelector
                    placeholder={t('AddObject', { object: t('ParentWorkCentre').toLocaleLowerCase() })}
                    setSelectedTags={setSelectedTags}
                    selectedTags={selectedTags}
                    availableTags={workCenters.map(item => ({
                      label: joinIgnoreEmpty(' - ', item.code, item.description),
                      value: item.code,
                    }))}
                    transformAfterSelect={(tag, others) =>
                      [
                        ...others,
                        {
                          label: tag.label.split(' - ')[1],
                          value: tag.value,
                        },
                      ].sort((a, b) => a.value.localeCompare(b.value))
                    }
                  />
                </FormControl>
              </FormItem>
            </div>
            <DialogFooter className="flex flex-row pt-6 justify-end">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  {t('Cancel')}
                </Button>
              </DialogClose>
              <Button type="submit" className="ml-2">
                {isEdit ? t('Edit') : t('Add')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
