import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { IconCheck } from '@tabler/icons-react'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

export type ComboSelectProps = {
  items: ComboItem[]
  label: string
  autoFocus?: boolean
  className?: string
  defaultOpen?: boolean
  disabled?: boolean
  exclude?: string[]
  hideExcluded?: boolean
  onSelect?: (item: ComboItem) => void
  resetLabel?: string
  showReset?: boolean
  value?: string
  wrapperClassName?: string
}

export type ComboItem = {
  label: string
  value: string
}

export function ComboSelect({
  items,
  label,
  onSelect,
  value,
  className,
  autoFocus = false,
  defaultOpen = false,
  disabled = false,
  exclude = [],
  hideExcluded = false,
  resetLabel = 'Any',
  showReset = false,
  wrapperClassName = '',
}: ComboSelectProps) {
  const [open, setOpen] = useState(false)
  const [containerInFocus, setContainerInFocus] = useState(false)
  const [focusFromItemList, setFocusFromItemList] = useState(false)
  const [focusByMouse, setFocusByMouse] = useState(false)

  const drawerItems = showReset
    ? [
        {
          label: resetLabel,
          value: '',
        },
      ].concat(items)
    : items
  const selectedItem = drawerItems.find(item => item.value === value)

  const [text, setText] = useState<string | undefined>(value ?? label)
  useEffect(() => setText(value ? selectedItem?.label : label), [label, selectedItem?.label, value])

  const triggerRef = useRef<HTMLButtonElement>(null)
  // If the dropdown is open, we want to handle Tab/Shift+Tab like the user will expect by closing the dropdown
  useHotkeys('tab', () => setOpen(false), { enabled: open, enableOnFormTags: true, enableOnContentEditable: true })
  useHotkeys('shift+tab', () => setOpen(false), {
    enabled: open,
    enableOnFormTags: true,
    enableOnContentEditable: true,
  })

  useEffect(() => {
    if (containerInFocus && !open) {
      setFocusFromItemList(true)
    }
  }, [containerInFocus, open])

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  return (
    <div
      onFocus={() => setContainerInFocus(true)}
      onBlur={() => setContainerInFocus(false)}
      className={wrapperClassName}
    >
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            ref={triggerRef}
            disabled={disabled}
            variant="outline"
            role="combobox"
            autoFocus={autoFocus}
            aria-expanded={open}
            onBlur={() => {
              setFocusByMouse(false)
              setFocusFromItemList(false)
            }}
            onMouseDown={() => setFocusByMouse(true)}
            onFocus={() => {
              if (!focusByMouse && !focusFromItemList) {
                setOpen(true)
              }
              setFocusByMouse(false)
              setFocusFromItemList(false)
            }}
            className={cn('justify-start overflow-hidden flex w-full', !value && 'text-muted-foreground', className)}
          >
            <span className="fade-clamp w-full text-start">{text}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          tabIndex={-1}
          className="p-0 min-w-[var(--radix-popover-trigger-width)] max-h-[var(--radix-popover-content-available-height)]"
        >
          <ItemsList
            setOpen={setOpen}
            exclude={exclude}
            selectedItem={selectedItem}
            setSelectedItem={item => {
              onSelect && onSelect(item)
              setText(item?.label)
            }}
            hideExcluded={hideExcluded}
            items={drawerItems}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

function ItemsList({
  setOpen,
  selectedItem,
  setSelectedItem,
  items,
  placeholder = t('Search'),
  emptyPlaceholder = t('NoResult'),
  exclude = [],
  hideExcluded = false,
}: {
  setOpen: (open: boolean) => void
  setSelectedItem: (item: ComboItem) => void
  items: ComboItem[]
  selectedItem?: ComboItem
  placeholder?: string
  emptyPlaceholder?: string
  exclude?: string[]
  hideExcluded?: boolean
}) {
  return (
    <Command
      filter={(value, search) => {
        if (value.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return 1
        return 0
      }}
    >
      <CommandInput placeholder={placeholder} className="h-9" />
      <CommandList className="max-h-[60vh]">
        <CommandEmpty className="py-6 text-center text-sm text-muted-foreground">{emptyPlaceholder}</CommandEmpty>
        <CommandGroup>
          {items.map(item => {
            if (hideExcluded && exclude.includes(item.value)) {
              return null
            }

            return (
              <CommandItem
                key={item.value}
                value={item.label}
                disabled={exclude.includes(item.value)}
                data-disabled={exclude.includes(item.value) || undefined}
                className="data-[disabled]:text-muted-foreground"
                onSelect={() => {
                  setSelectedItem(item)
                  setOpen(false)
                }}
              >
                {item.label}
                <IconCheck
                  className={cn('ml-auto h-4 w-4', item.value === selectedItem?.value ? 'opacity-100' : 'opacity-0')}
                />
              </CommandItem>
            )
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}
