import { DeleteDialog } from '@/components/DeleteDialog'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const BUTTON_VARIANTS = {
  default: '',
  success: 'bg-bearmetal-violet hover:bg-bearmetal-violet text-bearmetal-green',
  danger: 'bg-[#B51827] hover:bg-[#B51827]',
  outline: 'bg-[#333] hover:bg-[#333]',
}

export function MachineButton({
  variant = 'default',
  label,
  disabled,
  onClick,
}: {
  variant?: keyof typeof BUTTON_VARIANTS
  label: string
  disabled?: boolean
  onClick: () => void
}) {
  return (
    <Button
      className={cn('uppercase text-xl h-12 active:scale-95', BUTTON_VARIANTS[variant])}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export function MachineButtonDialog({
  variant = 'default',
  label,
  onClick,
}: {
  variant?: keyof typeof BUTTON_VARIANTS
  label: string
  onClick: () => void
}) {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <DeleteDialog open={dialogOpen} setOpen={setDialogOpen}>
        <DeleteDialog.Content
          title={t('ConfirmDeleteTitle')}
          description={t('ConfirmDeleteBody')}
          onConfirm={() => {
            onClick()
          }}
          onCancel={() => {}}
        />
      </DeleteDialog>
      <Button className={cn('uppercase text-xl h-12', BUTTON_VARIANTS[variant])} onClick={() => setDialogOpen(true)}>
        {label}
      </Button>
    </>
  )
}
