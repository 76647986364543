import { DrawerLayout } from '@/components/layouts/DrawerLayout'
import { Separator } from '@/components/ui/separator'
import { useTranslation } from 'react-i18next'

export function ErrorScreen({ code = 500, message }: { code?: number; message?: string }) {
  const { t } = useTranslation()

  return (
    <DrawerLayout>
      <div className="h-screen text-center flex flex-col items-center justify-center space-y-4">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-medium align-top py-2">{code}</h1>
          <Separator orientation="vertical" />
          <h2 className="text-sm font-normal m-0">{message || t('ErrorPageTitle')}</h2>
        </div>
      </div>
    </DrawerLayout>
  )
}
