import { useTranslation } from 'react-i18next'
import { Spinner } from './Spinner'

export function Loading() {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-center items-center space-y-4">
      <div className="max-w-[10vw]">
        <Spinner />
      </div>
      <span>{t('Loading')}</span>
    </div>
  )
}
