import { cn } from '@/lib/utils'
import { IconPlus, IconX } from '@tabler/icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonWithTooltip } from './ButtonWithTooltip'
import { ComboSelect } from './ComboSelect'
import { Badge } from './ui/badge'
import { buttonVariants } from './ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

export type Tag = {
  label: string
  value: string
}

export type TagsSelectorProps = {
  placeholder: string
  selectedTags: Tag[]
  availableTags: Tag[]
  setSelectedTags: (tags: Tag[]) => void
  onAdd?: (tag: Tag) => void
  onDelete?: (tag: Tag) => void
  transformAfterSelect?: (tag: Tag, oldSelection: Tag[]) => Tag[]
}

export function TagsSelector({
  selectedTags,
  availableTags,
  setSelectedTags,
  placeholder,
  onAdd = () => {},
  onDelete = () => {},
  transformAfterSelect = (_, old) => old,
}: TagsSelectorProps) {
  const [comboSelection, setComboSelection] = useState<Tag>()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col space-y-4">
      <div className="grid grid-rows-1 grid-cols-6 items-center gap-2">
        <ComboSelect
          items={availableTags}
          label={placeholder}
          exclude={selectedTags.map(t => t.value)}
          value={comboSelection?.value}
          onSelect={setComboSelection}
          hideExcluded
          wrapperClassName="col-span-5"
        />
        <ButtonWithTooltip
          className="cursor-pointer"
          variant="outline"
          tooltip={t('AddObject', { object: undefined })}
          onClick={e => {
            e.preventDefault()
            if (comboSelection) {
              setSelectedTags(
                transformAfterSelect(
                  comboSelection,
                  selectedTags.filter(t => t.value !== comboSelection.value)
                )
              )
              setComboSelection(undefined)
              onAdd(comboSelection)
            }
          }}
        >
          <IconPlus />
        </ButtonWithTooltip>
      </div>
      <div className="flex flex-wrap gap-2">
        {selectedTags.map((tag, idx) => (
          <Badge
            key={idx}
            className="text-muted-foreground inline-flex items-center space-x-1 p-0 pl-2"
            variant="outline"
          >
            <Tooltip>
              <TooltipTrigger onClick={e => e.preventDefault()}>
                <span>{tag.value}</span>
              </TooltipTrigger>
              {tag.label && <TooltipContent>{tag.label}</TooltipContent>}
            </Tooltip>
            <a
              className={cn(buttonVariants({ variant: 'ghost' }), 'px-1 py-2.5 m-0 h-4 cursor-pointer')}
              onClick={e => {
                e.preventDefault()
                setSelectedTags(selectedTags.filter(t => t.value !== tag.value))
                onDelete(tag)
              }}
            >
              <IconX className="h-4 w-4" />
            </a>
          </Badge>
        ))}
      </div>
    </div>
  )
}
