import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import BrowserLanguageDetector from 'i18next-browser-languagedetector'
import it from './assets/translations/it.json'

const FALLBACK_LANGUAGE = 'it'

export const defaultNS = 'translation'
export const resources = {
  /*
   * Since this is an internal tool used only in Italy, for now we
   * only specify the italian localization. Other languages may added,
   * if needed, in the future.
   */
  it: {
    translation: it,
  },
} as const

i18next.use(initReactI18next).use(BrowserLanguageDetector).init({
  defaultNS,
  resources,
  fallbackLng: FALLBACK_LANGUAGE,
})

export default i18next
