import { Button } from '@/components/ui/button'
import { IconBackspace } from '@tabler/icons-react'

type NumpadProps = {
  onClick?: (value: number | 'DEL') => void
}

const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

export function Numpad({ onClick = () => {} }: NumpadProps) {
  return (
    <div className="grid grid-row-4 grid-cols-3 gap-4 w-full h-full bg-secondary p-4 text-black rounded-md">
      {keys.map(key => (
        <Button key={key} variant="outline" className="shadow h-full min-h-16 text-xl" onClick={() => onClick(key)}>
          {key}
        </Button>
      ))}

      <Button variant="outline" className="shadow h-full col-span-2" onClick={() => onClick('DEL')}>
        <IconBackspace />
      </Button>
    </div>
  )
}
