import { Measure } from '@/shared/dto/Test'
import { t } from 'i18next'
import { DateTime, Duration, DurationObjectUnits, DurationOptions } from 'luxon'

export const MEASURE_LABELS: Record<Measure, string> = {
  [Measure.HEAD_LEAKS_A]: t('HeadLeaks') + ' A',
  [Measure.HEAD_LEAKS_B]: t('HeadLeaks') + ' B',
  [Measure.HEAD_LEAKS_C]: t('HeadLeaks') + ' C',
  [Measure.HEAD_LEAKS_D]: t('HeadLeaks') + ' D',
  [Measure.PIPE_LEAKS]: t('PipeLeaks'),
  [Measure.POROUS_PIPE_LEAKS]: t('PorousPipesLeaks'),
  [Measure.JOINT_LEAKS]: t('JointLeaks'),
}

/**
 * Returns a humanized string telling the difference between the two given timestamps
 * @param start A timestamp to compare
 * @param end Another timestamp to compare
 * @param opts Optional {@link DurationOptions} to pass to {@link Duration.toHuman()} when converting the difference to a string
 * @returns The humanized difference as a string
 */
export function toAbsHumanDuration(start: DateTime, end: DateTime, opts?: DurationOptions): string {
  // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
  const duration = end.diff(start).shiftTo('days', 'hours', 'minutes', 'seconds').toObject()

  if ('seconds' in duration) {
    duration.seconds = Math.round(duration.seconds!)
  }

  const cleanedDuration = Object.fromEntries(
    Object.entries(duration)
      .filter(([, value]) => value !== 0)
      .map(([key, value]) => [key, Math.abs(value)])
  ) as DurationObjectUnits

  if (Object.keys(cleanedDuration).length === 0) {
    cleanedDuration.seconds = 0
  }

  return Duration.fromObject(cleanedDuration, {
    ...opts,
    locale: 'it-IT',
  }).toHuman({
    listStyle: 'short',
    unitDisplay: 'short',
  })
}

/**
 * Humanizes the interval between now and the given event (e.g. `"2 hours ago"` or `"in 5 minutes"`)
 * @param event The timestamp of the event
 * @returns A humanized string with an optional prefix or suffix
 */
export function humanizeFromNow(event: DateTime) {
  const now = DateTime.local({ zone: event.zone })
  const prefix = event > now ? 'in ' : ''
  const suffix = now > event ? ' ago' : ''

  return `${prefix}${toAbsHumanDuration(event, now)}${suffix}`
}

/**
 * Like `.join(separator)` but ignores empty strings like `""`
 * @param separator The separator to put between strings
 * @param strings The various strings to join
 * @returns The resulting concatenated string
 */
export function joinIgnoreEmpty(separator: string, ...strings: (string | null | undefined)[]) {
  return strings.filter(s => s != null && s.length > 0).join(separator)
}
