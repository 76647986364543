import { IconTrash } from '@tabler/icons-react'
import { t } from 'i18next'
import { MouseEventHandler } from 'react'
import { ButtonWithTooltip } from './ButtonWithTooltip'

export const DeleteButton = ({ onClick = () => {} }: { onClick: MouseEventHandler<HTMLButtonElement> }) => (
  <ButtonWithTooltip
    className="h-9 w-9"
    tooltip={t('Delete')}
    tooltipContentProps={{
      side: 'right',
    }}
    onClick={onClick}
    variant="ghost"
    size="icon"
  >
    <IconTrash className="h-5" />
  </ButtonWithTooltip>
)
