import { NewReportScreenCard } from '@/components/NewReportScreenCard'
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { NON_EMPTY_STRING } from '@/lib/schema'
import { storage } from '@/lib/storage'
import { Report } from '@/shared/dto/Report'
import { zodResolver } from '@hookform/resolvers/zod'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { useThrottledCallback } from 'use-debounce'
import { z } from 'zod'
import { NewReportOutletContext } from './EditReportLayout'

type HeaderFormFields = {
  workerCode: string
  break: boolean
  notes: string

  // readonly
  date: string
  workCenter: string
  shiftCode: string
}

const HEADER_FORM_SCHEMA = z.object({
  date: z.string(),
  workCenter: z.string(),
  shiftCode: z.string(),
  workerCode: NON_EMPTY_STRING,
  break: z.boolean(),
  notes: z.string(),
})

export function EditReportHeaderScreen() {
  const { t } = useTranslation()
  const { editorState, setEditorState } = useOutletContext<NewReportOutletContext>()

  const lastReportInput = storage.getLastReportInput()

  const scheduleItemBreak = editorState.scheduleItem?.breakDuration ?? 0

  const onUpdateHeader = useThrottledCallback((data: HeaderFormFields) => {
    if (data.break !== editorState.header.breakDuration > 0) {
      // break overwritten, save in local storage
      storage.setLastReportInput({
        ...lastReportInput,
        hasBreak: data.break,
      })
    }

    setEditorState(old => ({
      ...old,
      header: {
        ...old.header,
        workerCode: data.workerCode,
        breakDuration: data.break ? scheduleItemBreak : 0,
        notes: data.notes,
      },
    }))
  }, 300)

  return (
    <NewReportScreenCard>
      <CardHeader>
        <CardTitle>{t('ReportHeader')}</CardTitle>
      </CardHeader>
      <CardContent>
        <HeaderForm header={editorState.header} breakEnabled={scheduleItemBreak > 0} setData={onUpdateHeader} />
      </CardContent>
    </NewReportScreenCard>
  )
}

type HeaderFormProps = {
  header: Report
  breakEnabled: boolean
  setData: (data: HeaderFormFields) => void
}

export function HeaderForm({ header, breakEnabled, setData }: HeaderFormProps) {
  const { t } = useTranslation()

  const form = useForm<z.infer<typeof HEADER_FORM_SCHEMA>>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: zodResolver(HEADER_FORM_SCHEMA),
    values: {
      date: DateTime.fromISO(header.decodedShift.start ?? '').toFormat('yyyy-MM-dd'),
      workCenter: `${header.workCenter.code} - ${header.workCenter.description}`,
      shiftCode: header.decodedShift.code,
      workerCode: header.workerCode ?? '',
      break: !!header.breakDuration && header.breakDuration > 0,
      notes: header.notes ?? '',
    },
  })

  useEffect(() => {
    const subscription = form.watch(data => setData(data as HeaderFormFields))
    return () => subscription.unsubscribe()
  }, [form, setData])

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="date"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('Date')}</FormLabel>
            <FormControl>
              <Tooltip>
                <TooltipTrigger className="flex w-full h-9">
                  <Input type="date" {...field} readOnly tabIndex={-1} disabled={true} />
                </TooltipTrigger>
                <TooltipContent>{t('EditNotAllowed')}</TooltipContent>
              </Tooltip>
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="workCenter"
        defaultValue=""
        render={({ field }) => (
          <FormItem className="mt-2">
            <FormLabel>{t('WorkCentre')}</FormLabel>
            <FormControl>
              <Tooltip>
                <TooltipTrigger className="flex w-full h-9">
                  <Input {...field} readOnly tabIndex={-1} disabled={true} />
                </TooltipTrigger>
                <TooltipContent>{t('EditNotAllowed')}</TooltipContent>
              </Tooltip>
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="shiftCode"
        defaultValue=""
        render={({ field }) => (
          <FormItem className="mt-2">
            <FormLabel>{t('Shift')}</FormLabel>
            <FormControl>
              <Tooltip>
                <TooltipTrigger className="flex w-full h-9">
                  <Input {...field} readOnly tabIndex={-1} disabled={true} />
                </TooltipTrigger>
                <TooltipContent>{t('EditNotAllowed')}</TooltipContent>
              </Tooltip>
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="workerCode"
        defaultValue=""
        render={({ field }) => (
          <FormItem className="mt-2">
            <FormLabel>{t('WorkerId')}</FormLabel>
            <FormControl>
              <Input {...field} autoFocus placeholder={t('WorkerId')} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="break"
        render={({ field }) => (
          <FormItem className="mt-2">
            <FormLabel>{t('BreakTime')}</FormLabel>
            <FormControl className="flex">
              <Checkbox
                checked={field.value}
                onCheckedChange={state => field.onChange(state)}
                disabled={!breakEnabled}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="notes"
        defaultValue=""
        render={({ field }) => (
          <FormItem className="mt-2">
            <FormLabel>{t('Notes')}</FormLabel>
            <FormControl className="flex">
              <Textarea {...field} />
            </FormControl>
          </FormItem>
        )}
      />
    </Form>
  )
}
