import { getDocumentId } from '@/lib/utils'
import { Couchdb } from '@iotinga/ts-backpack-couchdb-client'
import {
  PRODUCTION_DB_DESIGN_DOC_NAME,
  WORK_CENTER_DISCARD_REASONS_VIEW_NAME,
  WORK_CENTER_PRODUCTS_VIEW_NAME,
  WORK_CENTER_REWORK_REASONS_VIEW_NAME,
  WORK_CENTER_STOP_REASONS_VIEW_NAME,
} from '../db/production'
import { DocumentType } from '../dto/BaseDocument'
import { Product } from '../dto/Product'
import { DiscardReason, ReworkReason, StopReason } from '../dto/Reason'
import { WorkCenter } from '../dto/WorkCenter'
import { ScheduleItem } from '../dto/ScheduleItem'
import { CouchdbQueryBuilderImpl } from '../db/CouchdbQueryBuilder'

export type ProductWithProcessingDetails = Product & {
  machineCycleDuration: number
}

export type StopReasonWithDuration = StopReason & {
  stopDuration: number | null
}

type WorkCenterProductsViewValue = {
  _id: string
  machineCycleDuration: number
}

type WorkCenterStopReasonsViewValue = {
  _id: string
  stopDuration: number | null
}

export class WorkCenterViewServices {
  constructor(
    protected readonly db: Couchdb,
    readonly workCenterCode: string
  ) {}

  async getWorkCenter(): Promise<WorkCenter | undefined> {
    const workCenter = (await this.db.get(getDocumentId(DocumentType.WORK_CENTER, this.workCenterCode))) as
      | WorkCenter
      | undefined
    return workCenter
  }

  async getAvailableProducts(): Promise<ProductWithProcessingDetails[]> {
    const productForWorkCenterView = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view(WORK_CENTER_PRODUCTS_VIEW_NAME, {
        reduce: false,
        include_docs: true,
        start_key: [this.workCenterCode],
        end_key: [this.workCenterCode, {}],
      })

    const items: ProductWithProcessingDetails[] = []
    for (const row of productForWorkCenterView.rows) {
      items.push({
        ...(row.doc as Product),
        machineCycleDuration: (row.value as WorkCenterProductsViewValue).machineCycleDuration,
      })
    }

    return items
  }

  async getAvailableReworkReason(): Promise<ReworkReason[]> {
    const reworkReasonForWorkCenterView = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view(WORK_CENTER_REWORK_REASONS_VIEW_NAME, {
        reduce: false,
        include_docs: true,
        start_key: [this.workCenterCode],
        end_key: [this.workCenterCode, {}],
      })

    return reworkReasonForWorkCenterView.rows.map(row => row.doc as ReworkReason)
  }

  async getAvailableDiscardReason(): Promise<DiscardReason[]> {
    const discardReasonForWorkCenterView = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view(WORK_CENTER_DISCARD_REASONS_VIEW_NAME, {
        reduce: false,
        include_docs: true,
        start_key: [this.workCenterCode],
        end_key: [this.workCenterCode, {}],
      })

    return discardReasonForWorkCenterView.rows.map(row => row.doc as DiscardReason)
  }

  async getAvailableStopReason(): Promise<StopReasonWithDuration[]> {
    const stopsForWorkCenterView = await this.db
      .design(PRODUCTION_DB_DESIGN_DOC_NAME)
      .view(WORK_CENTER_STOP_REASONS_VIEW_NAME, {
        reduce: false,
        include_docs: true,
        start_key: [this.workCenterCode],
        end_key: [this.workCenterCode, {}],
      })

    const items: StopReasonWithDuration[] = []
    for (const row of stopsForWorkCenterView.rows) {
      items.push({
        ...(row.doc as StopReason),
        stopDuration: (row.value as WorkCenterStopReasonsViewValue).stopDuration,
      })
    }

    return items
  }

  async getParentWorkCenters(): Promise<WorkCenter[]> {
    const workCenter = (await this.db.get(getDocumentId(DocumentType.WORK_CENTER, this.workCenterCode))) as WorkCenter
    const parentsDocIds = workCenter.parentsCodes.map(code => getDocumentId(DocumentType.WORK_CENTER, code))
    const parentsViewResult = await this.db.allDocs({ keys: parentsDocIds, include_docs: true })
    return parentsViewResult.rows.map(r => r.doc as WorkCenter)
  }

  async getScheduleItems(): Promise<ScheduleItem[]> {
    const query = new CouchdbQueryBuilderImpl()
      .where('type', '=', DocumentType.SCHEDULE_ITEM)
      .where('workCenterCode', '=', this.workCenterCode)

    const records = await this.db.find(query.build())
    return records.docs as ScheduleItem[]
  }
}
